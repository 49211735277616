import { render, staticRenderFns } from "./SingleArticlePage.vue?vue&type=template&id=5dcbf8ac&scoped=true&"
import script from "./SingleArticlePage.vue?vue&type=script&lang=js&"
export * from "./SingleArticlePage.vue?vue&type=script&lang=js&"
import style0 from "./SingleArticlePage.vue?vue&type=style&index=0&id=5dcbf8ac&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dcbf8ac",
  null
  
)

export default component.exports