<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="headTitle">
      <span> 为保障您的权益，请上传被保险人身份证信息</span>
    </div>


    <div class="sectionList">
      <div class="section section_front_bg" @click="frontSelect">
        <input type="file" style="display: none" ref="fileFrontInput" @change="handleFileFrontUpload">
        <img class="fileFrontImg" :src="fileFrontUrl" :style="{'display': fileFrontShow}"/>
      </div>
      <div class="section section_back_bg" @click="backSelect">
        <input type="file" style="display: none" ref="fileBackInput" @change="handleFileBackUpload">
        <img class="fileBackImg" :src="fileBackUrl" :style="{'display': fileBackShow}"/>
      </div>
    </div>

    <div class="bottomButton" @click="dealChack">
      <div class="textSon">下一步</div>
    </div>

  </div>
</template>


<script>


import {Button, Dialog, NavBar, Toast, Uploader,Loading} from 'vant';
import axios from "axios";

export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader,
    [Loading.name]:Loading

  }, data() {
    return {
      pageTitle: "身份证明",
      fileFront: null,
      fileBack: null,
      fileFrontUrl: "",
      fileBackUrl: "",
      fileFrontShow: "none",
      fileBackShow: "none"
    }
  },
  methods: {
    frontSelect() {
      this.$refs.fileFrontInput.click();
    },
    backSelect() {
      this.$refs.fileBackInput.click();
    },
    handleFileFrontUpload(event) {

      Toast("上传中，请稍后");
      console.log("handleFileBackUpload")
      // this.fileBack = event.target.files[0];
      this.fileBack = event.target.files[0];
      //直接就上传
      let formData = new FormData();
      formData.append('file', this.fileBack);
      axios.post(this.$Constants.devHost + this.$Constants.RzApiMethod.hmbRzCardUpload, formData)
          .then(response => {
            // 处理上传成功事件
            this.fileFrontUrl =  response.data.data;
            this.fileFrontShow  = "block";
          })
          .catch(error => {
            // 处理上传失败事件
            console.info(error)
          });
    },
    handleFileBackUpload(event) {

      Toast("上传中，请稍后");
      console.log("handleFileBackUpload")
      // this.fileBack = event.target.files[0];

      this.fileBack = event.target.files[0];
      //直接就上传
      let formData = new FormData();
      formData.append('file', this.fileBack);
      axios.post(this.$Constants.devHost + this.$Constants.RzApiMethod.hmbRzCardUpload, formData)
          .then(response => {
            // 处理上传成功事件
            this.fileBackUrl = response.data.data;
            this.fileBackShow = "block";
          })
          .catch(error => {
            // 处理上传失败事件
            console.info(error)
          });
    },



    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
     // history.back();
    },
    dealChack() {
      let currentUrl = window.location.href;
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        frontUrl: this.fileFrontUrl,
        backUrl: this.fileBackUrl
      }

      let urlParams = "";
      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        urlParams = urlS[1]
      }



      if(this.fileFrontUrl==undefined || this.fileFrontUrl==""){
        Toast("请选择身份证正面照片上传后再提交");
        return;
      }


      if(this.fileBackUrl==undefined || this.fileBackUrl==""){
        Toast("请选择身份证反面照片上传后再提交");
        return;
      }
      urlParams=urlParams+ "&frontUrl=" + this.fileFrontUrl;
      urlParams=urlParams+ "&backUrl=" + this.fileBackUrl;
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.hmbRzCardCheck + "?" + urlParams, toSendParams
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          let targetUrl = res.data.data;
          Toast("上传成功，校验通过");
          window.location.href = targetUrl;

        } else {
          Dialog.alert({
            title: '温馨提示',
            message: res.data.message,
          }).then(() => {
            //后退一页
            //window.history.back();
          });
        }
      });
    },
  },
  created() {

    //   检查是否已经有过/
    // 理赔人的

  }
}
</script>

<style scoped lang="scss">

.testRem1 {
  display: flex;
  flex-direction: column;
  background: #F4F5F9 !important;
}


.fileFrontImg{
  height: 100%;
}
.fileBackImg{
  height: 100%;
}
.headTitle {
  background: #F4F5F9;

  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 0 12px;
  width: 351px;
  height: 48px;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 16px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #1A0C04;
  line-height: 24px;
  -webkit-background-clip: text;
}

.sectionList {

  background: #F4F5F9;
  border-radius: 0px 0px 0px 0px;
  padding: 12px;

}


.section {
  margin-bottom: 12px;
  width: 351px;
  height: 278px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  img {
    width: 100%;
  }
}


.section_front_bg {
  background-image: url('~@/assets/png/hmb/shenfen_front.png');
  background-repeat: no-repeat;
  background-size: 270px 186px;
  background-position: 50% 50%;

}

.section_back_bg {
  background-image: url('~@/assets/png/hmb/shenfen_back.png');
  background-repeat: no-repeat;
  background-size: 270px 186px;
  background-position: 50% 50%;
}

// 按钮
.bottomButton {
  width: 351px;
  height: 50px;
  flex-direction: row;
  position: fixed;
  display: flex;
  bottom: 0px;
  margin: 12px;

  background: #E86922;
  border-radius: 1000px 1000px 1000px 1000px;
  opacity: 1;
  align-content: center;
  justify-content: center;
  align-items: center;

  .textSon {
    justify-content: center;
    width: 54px;
    height: 26px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 26px;
    -webkit-background-clip: text;
    text-align: center;
  }


}
</style>