<template>
  <div class="myContainer">

    <van-nav-bar
        title="睿择健康"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
        placeholder="true"
    />
<div v-html="articleContent" >

</div>
  </div>
</template>

<script>

import {Button, NavBar, Toast} from 'vant';
import axios from "axios";


//import axios from "axios";

export default {
  name: "SingleArticlePage",
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar
  },
  created() {
    //获取请求参数.
    let params={
      articleId:this.$route.params.articleId
    }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getArticleById, params
      ).then(res => {
        if (res.data.code === 200) {
          this.articleContent=res.data.data.content
          //把当前激活的卡号，设置进去全局变量
        } else {
          Toast("文章不存在")
          this.$router.back()
        }
      });
    this.$Constants.setTitle("睿择健康");
  },
  data() {
    return {
      articleId: this.$route.params.articleId,
      articleContent: "",
      content1: "我是隐私保护协议",
      content2: "我是注册协议",
      content3: "我是个人健康信息告知书",
    };
  },
  methods: {
    onClickLeft() {
      //返回
      this.$router.back();
    }
  }
}
</script>

<style scoped lang="scss">

$nav-bar-text-color: #31E8C3;

.van-nav-bar .van-icon {
  color: #31E8C3 !important;
}

.ruizeNav{
  color:    #E86922;
}

.van-nav-bar__text {
  color: #31E8C3 !important;
}
.myContainer{
  padding: 0 20px 20px 20px;
}

</style>