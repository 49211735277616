<template>
  <div class="testRem1">
    testIIII


    <dev class="testMore van-multi-ellipsis--l3">

      111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111


    </dev>
  </div>
</template>

<script>

import axios from "axios";

import { Toast} from 'vant';

export default {
  name: "Empty",

    components: {
      [Toast.name]: Toast
    },
    created() {

      Toast("111111");
      this.testAxios();
    },
  methods: {
    testAxios() {





      let params =
          {brandNo: "HONDA", minYear: "1993"}


      axios.post('https://tmp-pre.fuse.co.th/pfm/vehicle/getYearListByBrand?_v=1695301105596_75507', params, {
        headers: {

          "fusetoken":"eyJhbGciOiJIUzI1NiIsInppcCI6IkRFRiJ9.eNpcUN1rwjAQ_1_uuYW01fTjTaZjMnCiDvZWziTObGla2nQo4v--W8KcLC-B-33eXcApi9bVWkKVMMbyvIhgGPdQAeesKMoyZSxJIIIvNFqiU3WHPTYDVHY0JoK2U9ar4dSfJm-zVTrbWKKPg-rrvjWKQCLD5UpDYVA3tWibDu25Hn9kwSUAR02au6keZrLRFqoDmkFF4M6doppEb0fr-vMylE4ynkXQtOJzeZOi8JzQ7N8mvpoHUkZQeEmS5HBzfmglJcHuCf6sLDZ-ttju4meaD6L9qQMQslceDulSCd2gmet37Wj5NAJ16qgrL_m05AXLybXrfo8-ofS7-x7RSqP62xHQeeU0Y8wrP5ymVMSsZPsDj7N0WsSTHIu4kKjibH-QTPAJfRmEGBGWWc7X9ePrdlGvNy9w_QYAAP__.d5bj1h_iLneN-4NPMyEmv4P0FvNlUBIRTLZhEmEGcUc",
          "Accept":"application/json, text/plain, */*",
          "Content-Type":"application/json;charset=utf-8",
          "language":"en",
          // "X-Trace-Id":"1695301105596_85519"

        }
      }).then(response =>{
        console.log(response.data);
      }).catch(error =>{
        console.log(error);
      });

      //检查用户当前激活的卡是否存在。
    }
  }
}
</script>

<style scoped lang="scss">
.testRem1{
  background: #00ff0d;
  width: 750px;
  height: 100px;
}

.testMore{
  width: 100%;

}
</style>