<template>
  <div>
    <div class="headBg">
      <img v-bind:src="jjj">
    </div>

    <div class="formContent">
      <span class="left">
        兑换新权益
      </span>
    </div>

    <van-form>
      <van-field
          v-model="kami"
          center
          name="smsCode"
          clearable
          size="large"
          placeholder="请输入权益激活码"
          class="kami"
          maxlength="14"
          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
      >
      </van-field>

      <wx-open-launch-weapp
          id="launch-btn"
          appid="wx0aa0a2e081c3f0b7"
          path="pages/home/index?user=123&action=abc"
          style="width: 200px;height: 200px"
      >
        <script type="text/wxtag-template">
          <style>.btn { padding: 12px }</style>
          <button class="btn">打开小程序</button>
        </script>
      </wx-open-launch-weapp>


      <div class="scanContainer" @click="toScan">
        <span class="right iconfont icon-iconfontscan" @click="toScan"></span>
        <span class="myScanLable">扫码输入激活码</span>

      </div>

      <div>
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="submitButton"
                    @click="submitKami"
        >确认激活码
        </van-button>
      </div>
    </van-form>

    <div class="descContainer">
      <p>
        使用方法：<br>
        1.刮开权益卡背面的卡密区域，即可看到14位激活码<br>
        2.激活码包括数字+字母，包含大小写，不可输入空格和特殊字符<br>
        3.根据引导完成三步后激活成功<br>
        4.如有疑问，请联系客服<a href="tel:4006998620">400 6998 620</a> <br>
      </p>
    </div>
  </div>
</template>

<script>


import jb from '@/assets/png/bind_first@2x.png'
import {Button, Field, Form, Toast} from "vant";
import Cookies from "js-cookie";
import wx from 'weixin-js-sdk';
import axios from "axios";
import {mapState, mapMutations} from 'vuex'

export default {
  name: "KycPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  created() {


    this.checkUserAndInit();

    this.checkPhoneStatus();
    this.initWechatJsSdk()
    this.$Constants.setTitle("睿择健康");

    //检查用户状态。

  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit:false
    }
  },
  computed: {
    ...mapState(["currentCardNumber"])
  },


  methods: {
    checkUserAndInit(){
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
              this.openId = openId;
            }
          });
        }
      }else {
        this.openId = cookieOpenId;
      }
    },
    checkPhoneStatus(){

    },
    initWechatJsSdk(){


      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.wxConfig=  JSON.parse(   res.data.data);
          this.wxConfig.debug=false
          wx.config(
              this.wxConfig
          );



          wx.ready(function () {


              }
          );

          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))

          });


        }
      });


    },
    submitKami() {
      //38971c9Db42AF3
      if (this.kami.length == 14) {
        let params = {
          "pass": this.kami,
          "openId": this.openId
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.cardLink, params
        ).then(res => {
              console.log(res);
              console.log("res.data:" + res.data);
              if (res.data.code === 200) {
                //绑定成功, 跳转到
                let cardNumber = res.data.data;
                this.setCurrentCardNumber(cardNumber);
                // 存取起来。
                this.$router.push("/linkSecondKycPage?toLinkCardNumber=" + cardNumber);

                console.log("cardNumber:" + cardNumber)
                return false;
              } else {
                Toast(res.data.message)
                return false;
              }
            }
        );
        // 提交卡密
        //进入下一个页面
        // Toast("进入下一个页面")
      } else {
        Toast("权益卡密错误")
        return false;
      }

    },
    toScan() {
      try {

        let that=this;
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            console.log("qrScan:" + result);
            that.kami = result
          }, error: function (res) {
            console.log("qrScan error :" + JSON.tringfy(res));
            if (res.errMsg.indexOf('function_not_exist') > 0) {
              Toast('当前版本低，请进行升级！');
            }
          }
        });
      } catch (e3) {
        Toast("e3:" + JSON.stringfy(e3))
      }


    },
    ...mapMutations(['setCurrentCardNumber']),
  }
}





function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}
</script>

<style scoped lang="scss">

.headBg {
  img {
    // width:100%;
    //width: 750px;
    height: 193px;
  }
}

.formContent {

  display: flex;
  justify-content: space-between;

  .left {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .right {
    margin-right: 25px;
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 25px;

  }

}

.kami {
  background: #F8F8F8;
  margin-left: 25px;
  width: 325px;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 40px;


}

.descContainer {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 40px;

  font-size: 12px;
  color: #B6B9C4;
}

.submitButton {
  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 16px;

  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}


.scanContainer{
  width: 100%;
  display: flex;
  flex-display: block;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-top: 28px;
  font-size: 14px;
  margin-bottom: 28px;

  .myScanLable{
    margin-top: -1px;
    margin-left: 5.6px;
  }
}
</style>