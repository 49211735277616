import Vue from 'vue'
import App from './App.vue'
import { Collapse, CollapseItem } from 'vant';

// 引入独立的css 文件，一般通用的css 都单独引入
//import DetailSon from "./assets/_global.scss";
import DetailSon2 from "./assets/DetailSon2.scss";

//引入路由组件
import router from './route'

//引入vuex的组件
import store from './store'


//rem transfer
import resetcss from "./assets/reset.css"
//import rem from './commom/rem/index'

// import 'lib-flexible'; //这个不行
import 'amfe-flexible'

//import 'lib-flexible'

//rem transfer
//import 'lib-flexible';

//引进 iconfont 的css 文件
import   "./assets/css/iconfont.css";


Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
    DetailSon2,
    //rem,
    resetcss

}).$mount('#app')

Vue.use(Collapse);
Vue.use(CollapseItem);
