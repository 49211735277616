let chatListStr ='[{"id":1,"targetUser":{"name":"我是阿里去哪个","icon":"https://tvax2.sinaimg.cn/crop.18.21.500.500.1024/60718250ly1fe7kog3jroj20f00f03zj.jpg?KID=imgbed,tva&Expires=1622535952&ssig=nveGiWuOBw","extIcon":["vip","ling"]},"newMsg":"wishing试试is","count":"1","willShowCount":false},{"id":2,"targetUser":{"name":"我是阿里去哪个","icon":"","extIcon":["vip"]},"newMsg":"wishing试试is","count":"1","willShowCount":true},{"id":3,"targetUser":{"name":"我是阿里去哪个","icon":"","extIcon":["vip"]},"newMsg":"wishing试试is","count":"1","willShowCount":false},{"id":4,"targetUser":{"name":"我是阿里去哪个","icon":"","extIcon":["vip"]},"newMsg":"wishing试试is","count":"1","willShowCount":false}]';
let chatList=JSON.parse(chatListStr)

let authToken;

// 当前操作绑定过程中的卡号
let currentCardNumber;

//当前使用且处于激活状态的卡（也可能为失效过期）
let currentActiveCard;




try {
    //这里英国是初始化的内容请求，
    if (localStorage.chatList) {
        chatList = localStorage.chatList
    }
} catch (e) {
    console.log(e)
}





let menuStatus={
    active:"home",
    home:{
        dot:false,
        count:0
    },
    userCenter:{
        dot:false,
        count:0
    }
}



//let contents = [{message: 'Foo'}, {message: 'Bar'}];

export default {
   // content: latestContent,
    chatList: chatList,
    authToken:authToken,
    menuStatus:menuStatus,
    currentCardNumber:currentCardNumber,
    currentActiveCard:currentActiveCard
}
