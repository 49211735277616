export default {
    getChatList(state) {
        console.log("getLatestContent by stata:" + state.chatList.chatList);
        // return 是无效的
        return state.content;
    },
    setAuthToken(state,authToken){
        state.authToken=authToken;
        localStorage.authToken=authToken;
    },

    setCurrentCardNumber(state,currentCardNumber){
        state.currentCardNumber=currentCardNumber;
        //localStorage.currentCardNumber=currentCardNumber;
        // 需要取一次才行
    },
    selectMenu(state,menuName) {
        console.log("selectMenu  :" + menuName);
        state.menuStatus.currentMenu=menuName;
    },
    setCurrentActiveCard(state,currentActiveCard){
        state.currentActiveCard=currentActiveCard;
    }
}
