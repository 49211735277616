<template>
  <div>


    <div class="formContent">
      <span class="left">
        兑换新权益
      </span>
    </div>

<!--    onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"-->
    <van-form>
      <van-field
          v-model="kami"
          center
          name="smsCode"
          clearable
          size="large"
          placeholder="请输入权益激活码"
          class="kami"
          maxlength="14"

      >
      </van-field>

      <div class="scanContainer" @click="toScan">
        <span class="right iconfont icon-iconfontscan" @click="toScan"></span>
        <span class="myScanLable">扫码输入激活码</span>

      </div>

      <div>
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="submitButton"
                    @click="submitKami"
        >确认激活码
        </van-button>
      </div>
    </van-form>




  </div>
</template>

<script>


import jb from '@/assets/img/yzHome/LinkBackImg.png'
import {Button, Field, Form, Toast} from "vant"; //Dialog
import Cookies from "js-cookie";
import axios from "axios";
import wx from "weixin-js-sdk";

export default {
  name: "GstCutBuyInputCardPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button
  },
  created() {
    this.checkUserAndInit();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("视频问诊");
    //检查用户状态。
    this.qdCode= getCurrentUrlQdCode();


  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit: false,
      originUrl:"",
      qdCode:"",
      cardList:[
        {}
      ],
    }
  },

  methods: {
    initWechatJsSdk(){
      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.wxConfig=  JSON.parse(   res.data.data);
          this.wxConfig.debug=false
          wx.config(
              this.wxConfig
          );
          wx.ready(function () {
              }
          );
          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))
          });
        }
      });
    },
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl="https://www.ruizehealth.com/#"+ url.split("#")[1];
              window.location.href =tagetOriginUrl;
                 // this.dealJumpParams();
              console.error("tagetOriginUrl:"+tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
        this.allCard();
        this.initWechatJsSdk();


      }
    },

    checkPhoneStatus() {


      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if(this.qdCode==""){
            this.qdCode=getCurrentUrlQdCode();

            if(this.qdCode==""){
              //todo
              Toast("非法访问")
            }
          }
          let targetUrl="//www.ruizehealth.com/#/GstCutBuyInputCardPage?qdCode="+this.qdCode;
          window.location.href =targetUrl;
        } else {

          // window.location.back();
          Toast("请先验证手机号");

          this.qdCode=getCurrentUrlQdCode();
          window.location.href="//www.ruizehealth.com/#/GstCutBuyLoginPage?qdCode="+this.qdCode;
          return false;
        }
      });
    },
    checkCardLink(){
      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.gstAllCard4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 曾经绑定过。
          window.location.href=res.data.targetUrl;
        }else {
          console.log("未绑定过卡");
        }
      });
    },
    submitKami() {
      this.qdCode=getCurrentUrlQdCode();
      //38971c9Db42AF3
        let params = {
          "pass": this.kami,
          "openId": this.openId,
          "qdCode":this.qdCode
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.gstCardLink4CutBuy, params
        ).then(res => {
              console.log(res);
              console.log("res.data:" + res.data);
              if (res.data.code === 200) {
                //绑定成功, 跳转到
                Toast("绑定成功！")
                 // 刷新。

                window.location.href=res.data.data.targetUrl;
               // location.reload();
                return false;
              } else {
                console.error("绑定失败:"+res.data.message)
                Toast(res.data.message)
                return false;
              }
            }
        );
        // 提交卡密
        //进入下一个页面
        // Toast("进入下一个页面")
     // }
      // else {
      //   Toast("卡密错误")
      //   return false;
      // }

    },
    toScan() {
      try {

        let that=this;
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            console.log("qrScan:" + result);
            that.kami = result
          }, error: function (res) {
            console.log("qrScan error :" + JSON.tringfy(res));
            if (res.errMsg.indexOf('function_not_exist') > 0) {
              Toast('当前版本低，请进行升级！');
            }
          }
        });
      } catch (e3) {
        Toast("e3:" + JSON.stringfy(e3))
      }


    },
    allCard(){
      this.qdCode=getCurrentUrlQdCode();
      //38971c9Db42AF3
        let params = {
          "openId": this.openId,
          "qdCode":this.qdCode
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.gstAllCard4CutBuy, params
        ).then(res => {
              console.log(res);
              console.log("res.data:" + res.data);
              if (res.data.code === 200) {
                console.error("res.data.targetUrl:"+res.data.targetUrl)
                window.location.href=res.data.data.targetUrl
              } else {
                return false;
              }
            }
        );
      }



  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}




function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");
  if(qdCode==undefined){
    let url = window.location.href;
    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">
.buyImgContainer{
  img{
    width: 100%;
    margin-top: 20px;
  }


}
.headBg {
  img {
    width: 100%;
    //width: 750px;
    height: auto;
  }
}

.formContent {

  display: flex;
  justify-content: space-between;

  .left {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .right {
    margin-right: 25px;
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 25px;

  }

}

.kami {
  background: #F8F8F8;
  margin-left: 25px;
  width: 325px;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 40px;


}


.submitButton {
  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 16px;

  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}


.scanContainer {
  width: 100%;
  display: flex;
  flex-display: block;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-top: 28px;
  font-size: 14px;
  margin-bottom: 28px;

  .myScanLable {
    margin-top: -1px;
    margin-left: 5.6px;
  }
}
</style>