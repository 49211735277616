<template>
    <div id="app">
        <router-view>

        </router-view>
      <span class="hideIcp" >
        沪ICP备2023011209号
      </span>
    </div>

</template>

<script>
    export default {
        name: 'App',
      provide() {
        return {
          reload: this.reload
        }
      },
      data() {
        return {
          isReload: true
        }
      },
      methods: {
        reload() {
          this.isReload = false
          this.$nextTick(() => {
            this.isReload = true
          })
        }
      }
    }
</script>


<style>
.app{
  padding: 0;
  margin: 0;
}

.hideIcp{
  display: none;
}

</style>