<template>
    <div class="container">
        <div class="doctor" v-for="(doctorData, index) in doctorDatas" :key="index">
            <!-- <img src= "doctorData.doctorAvator"> -->
            <div class="doctor_header">
                <img :src="doctorData.avator" style="border-radius: 10%;">
                <div class="doctor_header_right">
                    <div class="doctor_header_right_up">
                        <div class="doctor_name">
                            {{ doctorData.name }}
                        </div>
                        &nbsp;&nbsp;
                        <div class="doctor_title">
                            {{ doctorData.title }}
                        </div>
                    </div>
                    <div class="doctor_title">
                        {{ doctorData.hospital }} {{ doctorData.department }}
                    </div>
                </div>
            </div>
            <div style="width:100%">
                <div class="doctor_level">
                    <img src="~@/assets/img/gushengtang/maisui_left@2x.png">
                    {{ doctorData.honor }}
                    <img src="~@/assets/img/gushengtang/maisui_right@2x.png">
                </div>
            </div>

          <div class="doctor_desc">
            <div :style="getTextBoxStyle(index)">{{ getDisplayText(index) }}</div>
          </div>
          <div class="expandButtonContainerFather" >
          <div class="expandButtonContainer">
            <span class="expandButton" v-if="!doctorData.isExpand" @click="expandText(index)">展开更多</span>
            <span class="expandButtonClose" v-if="doctorData.isExpand" @click="collapseText(index)">收起更多</span>
          </div>
          </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.expandButtonContainerFather{
  width: 100%;
//   padding-right: 15px;
}
.expandButton{
  width: fit-content;
  height: 20px;
  font-size: 12px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #E86922;
  line-height: 20px;

}
.expandButtonClose{
  opacity: 0.8;
  width: fit-content;
  height: 20px;
  font-size: 12px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #1A0C04;
  line-height: 20px;
}




.container {
    background-color: #EEEEEE;
    padding: 12px;

    .doctor {
        background-color: white;
        border-radius: 12px;
        padding: 12px;
        margin-bottom: 12px;
        display: flex;
        flex-wrap: wrap;

        .doctor_header {
            display: inline-flex;
            width: 100%;

            img {
                margin-top: 6px;
                height: 48px;
                width: auto;
                object-fit: contain;
            }

            .doctor_header_right {
                margin-left: 12px;

                .doctor_header_right_up {
                    display: flex;
                    align-items: center;
                    height: 30px;

                    .doctor_name {
                        font-size: 18px;
                        color: #1A0C04;
                    }

                    .doctor_title {
                        display: flex;
                        height: 30px;
                        align-items: center;
                        font-size: 14px;
                        color: #1A0C04;
                        opacity: 0.7;
                    }
                }

                .doctor_title {
                    display: flex;
                    height: 30px;
                    align-items: center;
                    font-size: 14px;
                    color: #1A0C04;
                    opacity: 0.7;
                }
            }
        }

        .doctor_level {
            background-color: #F8F3ED;
            color: #B66922;
            font-size: 12px;
            position: absolute;
            display: inline-flex;
            align-items: center;

            img {
                width: 8px;
                height: 8px;
            }
        }

        .doctor_desc {
            margin-top: 20px;
            font-size: 14px;
            // height: auto;
            color: #1A0C04;
            opacity: 0.3;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            ::before {
                width: 0;
                height: 100%;
                /*先随便设置一个高度*/
                background: red;
                margin-bottom: 24px;
            }
        }
    }
}

.expandButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
</style>

<script>
export default {
    name: "GstDoctorList",
    components: {
    },
    data() {
        return {
            lineHeight: 20,
            hasExpand: [],
            isExpand: false,
            pageData: {
                activeName: '展开'
            },
            doctorDescTemp: [],
            doctorDatas: [
                {
                    avator: require("../../../assets/img/gushengtang/" + "xurunsan.png"),
                    isExpand: false,
                    name: '许润三',
                    area: '北京.鼓楼',
                    hospital: '中日友好医院',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：诊疗不孕症、子宫内膜异位症等妇科常见病和疑难杂病。临床研究方向为中医药治疗输卵管阻塞性不孕症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangqingguo.png"),
                    isExpand: false,
                    name: '王庆国',
                    area: '北京',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：对消化、内分泌、心脑血管系统疾病，自身免疫性疾病具有显著疗效，擅长治疗胃炎、胃溃疡、急慢性结肠炎、糖尿病、红斑狼疮、类风湿性关节炎、高血压、冠心病及其他内科、儿科、妇科常见病的诊断与治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "linyi.png"),
                    isExpand: false,
                    name: '林毅',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '乳腺科',
                    desc: '擅长：乳腺良恶性肿瘤尤其是三阴、双阴性乳腺癌，乳腺炎性疾病尤其是肉芽肿性乳腺炎，及乳腺增生性疾病、疑难病症的中医与中西医结合治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "zhoudaihan.png"),
                    isExpand: false,
                    name: '周岱翰',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：治疗晚期、难治癌症等疑难重疾，提出“带瘤生存”，更新治癌理念，奠定岭南中医肿瘤学术流派理论体系。创制“鹤蟾片”(获“1986年卫生部中医药重大科技成果乙等奖”)、“清金得生片”等制剂，代表性著作有《肿瘤治验集要》《中医肿瘤食疗学》等10部，主编《中医肿瘤学》并纳入全国高等中医院校专业课程，2010年获“教育部科学技术成果一等奖”、“广东省科学技术奖励二等奖”，2017获评“南粤楷模”称号，2019年获“全国中医药杰出贡献奖”。献身中医肿瘤医教研工作半个多世纪，是中医肿瘤学的主要奠基者，中医肿瘤学科教育的先行者，现代岭南中医肿瘤学术流派的开创者，为中医药传承精华、守正创新树立光辉典范。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "xuanguowei.jpeg"),
                    isExpand: false,
                    name: '禤国维',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：脱发病、痤疮、荨麻疹、红斑狼疮等结缔组织皮肤病和其他疑难皮肤病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "gelinyi.jpeg"),
                    isExpand: false,
                    name: '葛琳仪',
                    area: '杭州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：临床上以擅治肺系病、脾胃病、老年病、疑难杂症而著称。擅长采用中西医结合方法治疗呼吸系统疾病【慢性支气管炎、支气管哮喘、肺气肿、肺源性心脏病等】和消化系统疾病【慢性胃炎、消化性溃疡、消化道出血等疾病】及疑难杂症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yanshiyun.jpeg"),
                    isExpand: false,
                    name: '严世芸',
                    area: '上海',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：心脑血管疾病，肺及支气管疾病，消化道疾病，失寐，颈椎病等内科疾病及疑难杂症和保健调养。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "shiqi.png"),
                    isExpand: false,
                    name: '施杞',
                    area: '上海',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '骨伤科',
                    desc: '擅长：颈椎病，腰椎病，骨关节炎，类风湿性关节炎，强直性脊柱炎，股骨头无菌性坏死，骨延迟愈合，骨不连，慢性硬膜外血肿及各种骨伤科疑难杂症的诊断与治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "caijin.png"),
                    isExpand: false,
                    name: '蔡淦',
                    area: '上海',
                    hospital: '上海中医药大学附属曙光医院',
                    honor: '全国名中医',
                    title: '主任医师',
                    department: '脾胃科',
                    desc: '擅长：各种胃肠疾病（如慢性胃炎、消化性溃疡、胃癌前病变、慢性腹泻、胃肠道肿瘤手术后的调理），II型糖尿病以及各种内科疑难杂症的治疗和膏方的调理。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "fanzhongze.png"),
                    isExpand: false,
                    name: '范忠泽',
                    area: '上海',
                    hospital: '上海市普陀医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '肿瘤科',
                    desc: '擅长：肺癌、胃癌、大肠癌、乳腺癌、盆恶性肿瘤、脑瘤、肝癌、胰腺癌、食道癌、淋巴癌、小儿神经母细胞瘤等恶性肿瘤和癌前期疑难杂症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "lixiangyun.png"),
                    isExpand: false,
                    name: '李祥云',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：女性不孕、男性不育、男女性功能障碍、子宫内膜异位症、输卵管梗阻、黄体功能不健、更年期综合症，月经不调，疑难杂症，各种手术后及化疗后的调理等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wuyingen.jpeg"),
                    isExpand: false,
                    name: '吴银根',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '呼吸科',
                    desc: '擅长：支气管哮喘、慢性支气管炎、支气管扩张、间质性肺炎、慢性咳嗽、肿瘤术后调治以及中医肺肾系统的调理。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "xurongjuan.png"),
                    isExpand: false,
                    name: '徐蓉娟',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '内分泌科',
                    desc: '擅长：糖尿病及并发症、各种甲状腺疾病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "zhouyongming.png"),
                    isExpand: false,
                    name: '周永明',
                    area: '上海',
                    hospital: '上海中医药大学附属岳阳医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：擅长运用中医药为主、中西医结合治疗各种贫血、白血病、出凝血疾病、骨髓增生异常综合征、淋巴瘤、多发性骨髓瘤等血液病、免疫性疾病、恶性肿瘤和失眠、虚损等内科疑难杂证取得了显著疗效，积累了丰富的临床经验。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yujianer.png"),
                    isExpand: false,
                    name: '虞坚尔',
                    area: '上海',
                    hospital: '上海中医药大学附属中医医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '儿科',
                    desc: '擅长：治疗儿童哮喘、反复呼吸道感染、肺炎、小儿胃炎、儿童肾炎、肾病、紫癜、营养性 疾病及疑难杂病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "chenhongfeng.png"),
                    isExpand: false,
                    name: '陈红风',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '乳腺科',
                    desc: '擅长：乳腺癌、乳腺增生病、浆细胞性乳腺炎、肉芽肿性乳腺炎、急性乳腺炎等多种良恶性乳腺疾病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "suli.jpeg"),
                    isExpand: false,
                    name: '苏励',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '风湿病科',
                    desc: '擅长：治疗系统性红斑狼疮，类风湿关节炎，痛风，强直性脊柱炎，干燥综合症、白塞病、硬皮病、皮肌炎、骨关节炎等风湿性疾病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "linzhenshou.png"),
                    isExpand: false,
                    name: '林真寿',
                    area: '上海',
                    hospital: '上海中医药大学附属岳阳医院',
                    honor: '浙江省名中医',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：中医内、妇、儿科等疑难杂症，如胃肠肝胆病，萎缩性胃炎、返流性胃炎、食道炎、消化性溃疡、慢性肠炎、脂肪肝、慢性肝炎、肝硬化、胆囊炎、胆石症、各种消化功能障碍和高脂血症，甲状腺病、各种虚证加更年期综合症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "chenyiping.png"),
                    isExpand: false,
                    name: '陈以平',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '肾内科',
                    desc: '擅长：膜性肾病、IgA肾病等病理分型肾病，糖尿病肾病、痛风性肾病、狼疮性肾炎等继发性肾病，各期慢性肾功能不全，泌尿系感染及部分少见类型肾病的中西医结合诊治。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yaogaosheng.jpeg"),
                    isExpand: false,
                    name: '姚高升',
                    area: '北京.鼓楼',
                    hospital: '北京中医药大学',
                    honor: '国务院政府特殊津贴',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：治疗过敏性疾病，如过敏性鼻炎、各种皮炎、湿疹、荨麻疹及自身免疫性疾病如：红斑狼疮、硬皮病、重症肌无力、类风湿性关节炎；病毒性疾病如：带状疱疹后遗症、各种疣等；其他如牛皮癣、白癜风、黄褐斑、严重痤疮及其它损容性疾病等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangjinjue.jpeg"),
                    isExpand: false,
                    name: '王今觉',
                    area: '北京.鼓楼',
                    hospital: '中国中医科学院广安门医院',
                    honor: '首都国医名师',
                    title: '主任医师',
                    department: '内科',
                    desc: '擅长：高血压，失眠，痛风，糖尿病，高脂血症，消化系统疾（ 如胃肠病变，肝硬化、腹水，酒病，胆、胰病病），肾病，咳喘，脑梗，男科病，妇科病，皮肤科，脉管炎，疲劳综合症、干燥综合症等疑难杂病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "xuxian.jpeg"),
                    isExpand: false,
                    name: '许铣',
                    area: '北京.鼓楼',
                    hospital: '中国中医科学院广安门医院',
                    honor: '首都国医名师',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：银屑病、痤疮、湿疹皮炎、白癜风、疣、结缔组织病（红斑狼疮、皮肌炎、巩皮病）等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangsumei.jpeg"),
                    isExpand: false,
                    name: '王素梅',
                    area: '北京.鼓楼',
                    hospital: '东方医院',
                    honor: '首都国医名师',
                    title: '主任医师',
                    department: '儿科',
                    desc: '擅长：咳嗽、肺炎、哮喘、腹泻、血尿、厌食、过敏性紫癜、多发性抽动症、多动症等疾病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangxiaolian.png"),
                    isExpand: false,
                    name: '王晓莲',
                    area: '北京.鼓楼',
                    hospital: '北京市中医医院',
                    honor: '首都国医名师',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：过敏性皮肤病、银屑病﹑带状疱疹﹑痤疮﹑湿疹﹑蕁麻疹等；对于疱病类﹑系统性红斑狼疮﹑非淋菌性尿道炎等疑难杂症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "shahaiwen.jpeg"),
                    isExpand: false,
                    name: '沙海汶',
                    area: '北京望京',
                    hospital: '东直门医院',
                    honor: '首都国医名师',
                    title: '主任医师',
                    department: '儿科',
                    desc: '擅长：儿科常见病、多发病及小儿痿证、睑废、多动症、抽动症、汗症、语迟、哮喘、心肌炎、小儿发育迟缓等疑难症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "huanghuowen.jpeg"),
                    isExpand: false,
                    name: '黄火文',
                    area: '广州',
                    hospital: '中山大学肿瘤防治中心',
                    honor: '全国名中医',
                    title: '主任中医师',
                    department: '肿瘤科',
                    desc: '擅长：各种肿瘤中西医结合治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yangqunyu.jpeg"),
                    isExpand: false,
                    name: '杨群玉',
                    area: '广州',
                    hospital: '广州医科大学附属第一医院',
                    honor: '全国名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：呼吸疾病、小儿顽咳、哮喘，经产病、心脑血管病、糖尿病、肝胆病等，自拟双石汤治疗原发性高血压、小金丹治疗慢性肝炎血瘀症疗效显著。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "luosongping.jpeg"),
                    isExpand: false,
                    name: '罗颂平',
                    area: '广州',
                    hospital: '广州中医药大学第一附属医院',
                    honor: '全国名中医',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：治疗复发性流产、不孕症、月经不调等妇科疑难与常见病证。她出身于岭南中医世家，是全国著名中医妇科学家罗元恺教授的学术继承人；国家中医药管理局“岭南罗氏妇科流派传承工作室”负责人；广东省教育厅“中医女性生殖调节与安全性研究重点实验室”负责人。主编“十二五”规划教材《中医妇科学》。 曾获广东省科技二等奖2项、国家中医药管理局二等奖1项、广东省教学成果一等奖1项、二等奖1项。获国务院政府特殊津贴；国家人事部“有突出贡献的中青年专家”；“广东省高校教学名师；“南粤优秀教师”；“全国百名杰出女中医师”；“全国模范教师”和“全国医德标兵”、“广东省名中医”等称号。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "linlizhu.jpeg"),
                    isExpand: false,
                    name: '林丽珠',
                    area: '广州',
                    hospital: '广州中医药大学第一附属医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '肿瘤科',
                    desc: '擅长：肺癌、肝癌、大肠癌，以及胃、鼻咽癌、恶性淋巴瘤和妇科良、恶性肿瘤等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "laixinsheng.png"),
                    isExpand: false,
                    name: '赖新生',
                    area: '广州',
                    hospital: '广州中医药大学第一附属医院',
                    honor: '全国名中医',
                    title: '主任中医师',
                    department: '针灸推拿科',
                    desc: '擅长：针药结合治疗不孕不育、月经不调和痛经等妇科疾病；精于针刺补泻，对子午流注针法的应用独出杼机，运用通元法结合补泻治疗中风及相关疾病，对眩晕、头痛、失眠、血管性痴呆、颈椎病、腰椎间盘突出、过敏性鼻炎、急慢性荨麻疹、支气管哮喘、面瘫、小儿脑瘫、儿童精神发育迟滞、儿童自闭症、带下病、月经病、癫痫和帕金森综合症等有独到疗效。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "chenjinghe.jpeg"),
                    isExpand: false,
                    name: '陈镜合',
                    area: '广州',
                    hospital: '广州中医药大学第一附属医院',
                    honor: '全国名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：中西结合心脑血管病诊治。 兼攻：中医内科杂病如胃痛、风湿痹痛、郁证、哮证、喘证、咳证、头痛、中风、胸痛、心悸等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "huangsuiping.jpeg"),
                    isExpand: false,
                    name: '黄穗平',
                    area: '广州',
                    hospital: '广东省中医院',
                    honor: '广东省名中医',
                    title: '主任中医师',
                    department: '脾胃科',
                    desc: '擅长：胃、肠、肝、胆等消化系统的中医、中西医结合诊治预防保健、养生调理。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "liyunying.jpeg"),
                    isExpand: false,
                    name: '李云英',
                    area: '广州',
                    hospital: '广东省中医院',
                    honor: '广东省名中医',
                    title: '主任中医师',
                    department: '耳鼻喉科',
                    desc: '擅长：鼻炎、声嘶、咽喉炎、腺样体肥大、耳鸣耳聋、眩晕、鼻咽癌、喉癌等耳鼻喉科常见疑难病症的诊治。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangxiaoyun.jpeg"),
                    isExpand: false,
                    name: '王小云',
                    area: '广州',
                    hospital: '广东省中医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：“针药并用、心身同治”治疗妇科生殖内分泌疾病如卵巢早衰、更年期综合征、不孕症、复发性流产、抑郁症、重度睡眠障碍及复发性子宫内膜异位症、晚期妇科恶性肿瘤及复发性恶性肿瘤的中医药治疗等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "liangjianping.png"),
                    isExpand: false,
                    name: '梁建萍',
                    area: '广州',
                    hospital: '南昌大学附属第三医院',
                    honor: '江西省名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：1.内科：胃炎，萎缩性胃炎，胃肠息肉，肝胆结石；冠心病,高血压，糖尿病并发症，高脂血症；头痛，失眠，感冒咳嗽，气管炎，中风后遗症。 2.儿科：咳嗽，过敏性鼻炎，小儿腹泻，消化不良，腺样体肥大。 3.妇科：月经不调，不孕，肌腺症，子宫肌瘤，多囊卵巢综合征，更年期综合征，卵巢早衰。 4.泌尿系统：肾炎，肾结石，前列腺增生。 5.疑难杂症：中西结合治疗类风湿性关节炎，干燥综合征，股骨头坏死，肥胖症，肿瘤防治及心理调治，桥本甲状腺炎，乙肝,肝硬化。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "hudazhong.png"),
                    isExpand: false,
                    name: '胡大中',
                    area: '广州',
                    hospital: '江西抚州人民医院',
                    honor: '全国名中医',
                    title: '主任中医师',
                    department: '中医科',
                    desc: '擅长：擅治顽固性头痛、眩晕、失眠、抑郁症、焦虑症等；亦擅治妇女内分泌失调诸症，更年期综合症；肝炎、肾炎、小儿咳嗽、厌食、多动症；对胃炎、结肠炎、前列腺炎、阳痿亦有较高疗效。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "fanruiqiang.png"),
                    isExpand: false,
                    name: '范瑞强',
                    area: '广州',
                    hospital: '广东省中医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：痤疮、皮肤真菌病（外阴念珠菌病、足癣）、红斑狼疮、性病、湿疹以及其他疑难皮肤病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "huangjianling.jpeg"),
                    isExpand: false,
                    name: '黄健玲',
                    area: '广州',
                    hospital: '广东省中医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：月经失调，不孕不育症，盆腔炎性疾病，复发性流产，子宫肌瘤，子宫内膜异位症，子宫腺肌症，妇科恶性肿瘤 擅长中西医结合治疗月经失调、不孕不育症、盆腔炎性疾病、复发性流产、子宫肌瘤、子宫内膜异位症、子宫腺肌症等疾病，妇科恶性肿瘤的中医调理。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "huangxiaozhi.jpeg"),
                    isExpand: false,
                    name: '黄笑芝',
                    area: '广州',
                    hospital: '广州市第十二人民医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：糖尿病，各种发热病，急慢性支气管炎，慢性胃炎，失眠，眩晕，子宫肌瘤，带下病、更年期综合征，乳腺病'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "chenxiaoyi.jpeg"),
                    isExpand: false,
                    name: '陈小忆',
                    area: '广州',
                    hospital: '广州医科大学附属第二医院',
                    honor: '广东省名中医',
                    title: '主任中医师',
                    department: '中医科',
                    desc: '擅长：月经失调、闭经、不孕症、更年期综合症的诊治；擅长慢性咽炎、慢性咳嗽等上呼吸道疾病的诊治；尤其对不孕不育、顽固性咳嗽具有独到的治疗经验；对各种肿瘤的治疗颇有研究。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yesuilin.png"),
                    isExpand: false,
                    name: '叶穗林',
                    area: '广州',
                    hospital: '广州市中医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：运用中医方法治疗高血压病、高脂血症、冠心病、心肌病、病毒性心肌炎、风湿性心脏病、顽固性心力衰竭、顽固性心律失常等疾病。对中医心悸、胸痛、胸痹、眩晕、头痛、失眠、水肿、喘证、咳嗽等证有三十多年丰富的临床治疗经验。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "mawanli.jpeg"),
                    isExpand: false,
                    name: '马万里',
                    area: '广州',
                    hospital: '广州市中医院',
                    honor: '广州市名中医',
                    title: '主任中医师',
                    department: '中医科',
                    desc: '擅长：中医辨证治疗皮肤顽症及疑难病证，如银屑病、慢性湿疹、神经性皮炎、慢性荨麻疹、红斑狼疮等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "haojianjun.png"),
                    isExpand: false,
                    name: '郝建军',
                    area: '广州',
                    hospital: '暨南大学附属第一医院黄埔分院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '中医科',
                    desc: '擅长：运用中医、中西医结合方法治疗心脑血管学科、肿瘤学科、妇科和内科杂病、疑难病。目前治疗较多的病种有:肺癌、肝癌、胃肠癌等良、恶性肿瘤的中医药治疗，不孕症、卵巢功能减退、冠心病、高血压、糖尿病、心律失常等。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "ruanyan.jpeg"),
                    isExpand: false,
                    name: '阮岩',
                    area: '广州',
                    hospital: '广州中医药大学第一附属医院',
                    honor: '广东省名中医',
                    title: '主任医师',
                    department: '耳鼻喉科',
                    desc: '擅长：中西医结合治疗过敏性鼻炎、鼻窦炎、鼾症 (阻塞性睡眠呼吸暂停低通气综合征)等。'
                },
            ],
        }
    },
    created() {
        for (let i = 0; i < this.doctorDatas.length; i++) {
            if (this.doctorDatas[i].desc.length > 40) {
                this.doctorDescTemp.push(this.doctorDatas[i].desc.slice(0, 40))
            }
            else {
                this.doctorDescTemp.push(this.doctorDatas[i].desc)
            }
            this.hasExpand.push(false)
        }
    },
    methods: {
        changeFoldState(index) {
            if (this.hasExpand[index]) {
                this.doctorDescTemp[index] = this.doctorDatas[index].desc.slice(0, 40)
            }
            else {
                this.doctorDescTemp[index] = this.doctorDatas[index].desc
            }
            this.hasExpand[index] = !this.hasExpand[index]
            this.isExpand = !this.isExpand
        },



        getTextBoxStyle(index) {
            return {
                // width: "100%", // 文本框宽度自适应屏幕
                maxHeight: this.doctorDatas[index].isExpand ? "none" : this.lineHeight * 2 + "px",
                overflow: "hidden",
            };
        },
        getDisplayText(index) {
            return this.doctorDatas[index].isExpand ? this.doctorDatas[index].desc : this.doctorDatas[index].desc.slice(0, this.lineHeight * 2);
        },
        expandText(index) {
            this.doctorDatas[index].isExpand = true;
        },
        collapseText(index) {
            this.doctorDatas[index].isExpand = false;
        },
    },
    computed: {
        showDesc: {
            get: function () {
                if (this.hasExpand) {
                    if (this.detailList.length < 7) {
                        return this.detailList
                    }
                    let newArr = []
                    for (var i = 0; i < 6; i++) {
                        let item = this.detailList[i]
                        newArr.push(item)
                    }
                    return newArr
                }
                return this.detailList
            },
            set: function (val) {
                this.showdetailList = val
            }
        }
    }
}



</script>