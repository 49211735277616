<template>
  <div class="testRem1">
    testIIII
  </div>
</template>

<script>


import { Toast} from 'vant';

export default {
  name: "Empty",

    components: {
      [Toast.name]: Toast
    },
    created() {

      Toast("111111");
    }
}
</script>

<style scoped lang="scss">
.testRem1{
  background: #00ff0d;
  width: 750px;
  height: 100px;
}
</style>