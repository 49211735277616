<template>

  <div>
    <div class="myStick">
      <van-form @click="toShowPop">
        <van-checkbox v-model="checked"
                      class="myCheckBox"
                      icon-size="0.36rem"
                      label-disabled
                      @click="toShowPop"
        >
          <span class="checkBoxDesc">根据监管要求，需要权益人查看并确认，</span>
          <span class="checkBoxLink" v-on:click="showPrivateDetail">《个人健康收集处理告知书》 </span>
          <span class="checkBoxDesc">我们将保存您的操作记录。</span>
        </van-checkbox>
      </van-form>


      <!-- todo 这是跳转到 -->
      <img class="submitButton"
           :src="buttonConfirmSrc"
           @click="dealToSend"
      />
    </div>


    <div class="detail">
      <img v-bind:src="headImg">
      <img v-bind:src="showConfigVO.link3PageShowVO.headBackImg">
      <img v-bind:src="showConfigVO.link3PageShowVO.summeryYear">
      <img v-bind:src="showConfigVO.link3PageShowVO.spe" @click="toArticle">
      <img v-bind:src="showConfigVO.link3PageShowVO.simple">
      <img v-bind:src="showConfigVO.link3PageShowVO.ask">
    </div>


    <van-popup v-model="show" round position="bottom" :style="{ height: '70%' }">

      <img v-bind:src="popHeadImg" class="popHeadImg">

      <div class="pipArticle">
        <p>

          1.权益人未曾被医疗机构确诊或疑似诊断患有恶性肿瘤（包括癌症、白血病、淋巴瘤、骨髓瘤）、癌前病变、原位癌、类癌、肝硬化、不明性质的肿瘤/新生物/肿块/恶性性质的息肉/恶性性质的结节。<br>
          2.权益人未曾出现肿瘤标志物或组织及其细胞病理学检查异常。<br>
          肿瘤标志物：甲胎蛋白（AFP）、癌胚抗原（CEA）、前列腺抗异性抗原（PSA）、癌抗原125（CA125）、癌抗原199（CA99）、癌抗原15-3（CA15-3）、癌抗原50（CA50）、糖类抗原242（CA242）、胃癌相关抗原（CA72-4）、铁蛋白（SF）、β2微球蛋白（β2-MG）、神经元特异性烯醇化酶（NSE）、鳞状细胞癌抗原（SCCA）、人绒毛膜促性腺激素（HCG）。<br>
          3.权益人未曾被医疗机构确诊或诊断疑似患有发作性睡病、肥大细胞增生症、肺动脉高压、骨髓纤维化、骨髓增生异常综合症、胱氨酸贮积症、华氏巨球蛋白血症、进行性肺纤维化、肾上腺功能不全、先天性肾上腺皮质增生（CAH）、重症肌无力。<br>
          {{showNotic}}<br>
          <br>
          本人确认截止至签名当时，本人不存在健康告知书提及的健康问题，健康告知签名确认前权益不生效。<br>

        </p>

      </div>
      <div class="bottomButton">


        <img v-bind:src="buttonLaterSrc"
             @click="dealLater" alt="deal"
        >
        <img v-bind:src="buttonNextSrc"
             @click="dealNext"  alt="deal"
         >
      </div>

    </van-popup>


  </div>


</template>

<script>


import thirdHeadImg from '@/assets/png/bind_third@2x.png'

// 图片加载
import detail_head_first from '@/assets/png/detail_head_first@2x.png'
import detail_first_1 from '@/assets/png/detail_first_1_750326@2x.png'
import detail_second from '@/assets/png/detail_second@2x.png'
import detail_third from '@/assets/png/detail_third@2x.png'
import detail_four from '@/assets/png/detail_four@2x.png'


import artileHealthHead from '@/assets/png/artile_health_head.png'
import buttonLater from '@/assets/png/button_later.png'
import buttonNext from '@/assets/png/button_next.png'

import button_confirm_no_active from '@/assets/png/button_confirm_no_active.png'
import button_confirm_active from '@/assets/png/button_confirm_active.png'


import {Button, Field, Form, Sticky, Checkbox, CheckboxGroup, Popup, Dialog, Toast} from "vant";
import axios from "axios";
import {mapMutations, mapState} from "vuex";
import Cookies from "js-cookie";

export default {
  name: "KycPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Sticky.name]: Sticky,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup,
    [Dialog.name]: Dialog
  },
  created() {

    // let allHigh = document.body.clientHeight;
    //
    // let target = allHigh - 150;
    //
    // this.topOffset = target;

    let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
    if (cookieOpenId === undefined) {
      this.$router.push("/")
    }
    this.openId = cookieOpenId

    let cardNumber=this.currentCardNumber;
    if(cardNumber===undefined){
      this.$router.push("/")
    }
    this.initShowPhoto();
    this .toLinkCardNumber =this.$route.query.toLinkCardNumber;
    console.log("toLinkCardNumber:"+this .toLinkCardNumber)
    this.$Constants.setTitle("睿择健康");
  },

computed: {
...mapState(["currentCardNumber"])
},
  data() {
    return {
      headImg: thirdHeadImg,
      headFirst: detail_head_first,
      first: detail_first_1,
      second: detail_second,
      third: detail_third,
      four: detail_four,
      topOffset: 1,
      //协议弹框
      show: false,
      // 健康告知书头部,
      popHeadImg: artileHealthHead,
      //  提交内容
      checked: false,
      buttonLaterSrc: buttonLater,
      buttonNextSrc: buttonNext,
      buttonConfirmSrc: button_confirm_no_active,
      buttonConfirmSrcA: button_confirm_active,

      //检查结果是否可以提交
      canNext: false,
      openId:"",
      //这是配置的内容
      showConfigVO:{},
      toLinkCardNumber:"",
      showNotic:"4.权益人激活时的年龄范围为28天（含）至70周岁（含）。"

    }
  }, methods: {
    toArticle(){
      //todo
      // 查看权益
      this.$router.push({
        name:"detailShow",
        query:{
          from:"third",
          toShowCard:this.currentCardNumber
        }
      })
      },
    initShowPhoto(){
      let
          params={
            openId:this.openId,
            cardNumber:this.currentCardNumber,
          }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCardShow, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.showConfigVO=res.data.data;
          //绑定要映射的内容
          console.log("showConfigVO:"+this.showConfigVO);
          if(this.showConfigVO.cardInfoVO.templateId==5){
            this.showNotic="4.权益人激活时的年龄范围为71至85周岁（含）。"
          }
        } else {
          Toast(res.data.message)
        }
      });
    },
    dealToSend() {
      if (this.canNext) {

        // 调用后台的接口激活成功。
       // Toast("this.currentCardNumber："+this.currentCardNumber)

        let params = {
          openId: this.openId,
          //todo 如果没有完整激活，使用currentCardNumber 会有bug
          cardNumber: this.currentCardNumber
        }

        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.confirmCardActive, params
        ).then(res => {
          console.log(res);
          console.log("res.data:" + res.data);
          if (res.data.code === 200) {

            this.setCurrentActiveCard(this.currentCardNumber)

            //this.can
            Dialog.alert({
              title: '激活成功',
              message: '恭喜领取会员成功，快去查看使用权益吧！',
            }).then(() => {
              // todo 跳转到首页
              //let kkk=Math.random();
               let targetUrl="//www.ruizehealth.com/#/home"
              window.location.href=targetUrl
            });
          } else {
            Toast(res.data.message)
          }
        });
      } else {
        Toast("请先确认告知书！")
      }
    },
    showPrivateDetail() {
      console.log("------------")
    },
    toShowPop() {
      this.show = true;
    },
    dealLater() {
      Dialog.alert({
        title: '激活失败',
        message: '没有确认健康告知书，未能完成激活',
      }).then(() => {
        this.show = false;
        this.canNext = false;
        this.checked = false;
        this.buttonConfirmSrc = button_confirm_no_active
      });
    },
    dealNext() {
      this.checked = true;
      this.show = false;
      this.buttonConfirmSrc = button_confirm_active
      this.canNext = true
    },
    ...mapMutations(['setCurrentCardNumber','setCurrentActiveCard'])
  }
}
</script>

<style scoped lang="scss">


.submitButton {
  width: 100%;
  height: 44px;
}

.detail {
  img {
    width: 100%;
  }
  margin-bottom: 205px;
  padding-bottom: 205px;
}

.myCheckBox {
  margin: 15px;
}

.checkBoxDesc {
  color: #B6B9C4;
  line-height: 15px;
  font-size: 15px;
}

.checkBoxLink {
  line-height: 15px;
  font-size: 15px;
}


.myStick {
  position: fixed;
  bottom: 0px;
  background: #F8F8F8;
}

.popHeadImg {
  width: 100%;
}

.bottomButton {
  position: fixed;
  height: 44px;
  display: flex;
  flex-direction: row;;
  bottom: 0px;
  img{
    width: 50%;
  }
}



.pipArticle {
  font-size: 14px;
  padding: 20px;
  padding-bottom: 100px;
}

</style>