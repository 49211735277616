<template>

  <div>

    <div class="detail">
      <img v-bind:src="showConfigVO.homeShowVO.headBackImg" class="headFirst">
      <img v-bind:src="showConfigVO.homeShowVO.spe" class="useFirst">
      <img v-bind:src="showConfigVO.homeShowVO.ask" class="headFirst" @click="toShowAlert">
      <img v-bind:src="showConfigVO.homeShowVO.simple" class="useFirst" @click="toBuy">
    </div>
    <ruize-menu>
    </ruize-menu>

    <van-overlay :show="showDialog" @click="showDialog = false">
      <div class="wrapper">
        <img :src="showAlertPng" class="showAlertPngClass"/>
      </div>
    </van-overlay>

  </div>


</template>

<script>


import {Button, Overlay, Form, Toast, Dialog} from "vant";


import use_first_right from "@/assets/png/use_first_right_button@2x.png";
import showAlertPng from "@/assets/img/yzHome/showAlert2.png";


import ruizeMenu from "@/components/ruize/RuizeMenu.vue"
import axios from "axios";
import Cookies from "js-cookie";
import wx from "weixin-js-sdk";


export default {
  name: "Home",
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    ruizeMenu
  },
  created() {

    this.checkUserAndInit();
    // 初始化标记来自于药诊卡,改为枚举
    Cookies.set("from","yaozhen");
    this.dealJumpParams();
    this.initWechatJsSdk()

    this.$Constants.setTitle("首页");
  },

  data() {
    return {

      useFirstRight: use_first_right,

      openId: "",
      yzJumpUrl: "",
      showConfigVO: "",
      activeCardInfo: {},
      //这是配置的内容
      wxConfig: {},

      //yz
      linkCardNumber: "",
      buyJumpUrl: "",
      showDialog: false,
      showAlertPng: showAlertPng,
      //当前激活的卡号
      currentActiveCard: "",
    }
  },
  methods: {
    dealJumpParams(){
      let url = window.location.href;
      let qdCode = getUrlParams(url, "qdCode");
      let sign=getUrlParams(url,"sign");
      let requestParams=getUrlParams(url,"params")

      // if(qdCode=="" || requestParams=="" || sign==""){
      //   Dialog.alert({
      //     title: '非法访问',
      //     message: '请联系客服',
      //   }).then(() => {
      //     //后退一页
      //     window.location.back();
      //   });
      //   return false;
      // }


      if(requestParams!="" && requestParams!=undefined){
      console.error("requestParams:"+requestParams)
        let params = {
          qdCode: qdCode,
          sign:sign,
          params:requestParams,
          openId:this.openId
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.dealJump, params
        ).then(res => {
          console.log(res);
          if (res.data.code === 200) {
            this.checkPhoneStatus();
            //todo 后续的内容展示
            // 跳转卡号
            this.checkActiveCard();

            //init ShowConfigVO
            this.initShowPhoto();

          } else {
            //验证码错误
            Dialog.alert({
              title: '非法访问',
              message: '请联系客服',
            }).then(() => {
              //后退一页
              window.history.back();
            });
            console.error("res.data.message:" + res.data.message);
          }
        })
      }else {
        this.checkPhoneStatus();
        //todo 后续的内容展示
        // 跳转卡号
        this.checkActiveCard();
        // 获取用户当前绑定的卡号
        this.initActiveCard();
        //init ShowConfigVO
        this.initShowPhoto();
      }
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
              this.openId = openId;
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
      }
    },
    initWechatJsSdk() {
      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        if (res.data.code === 200) {
          this.wxConfig = JSON.parse(res.data.data);
          this.wxConfig.debug = false
          wx.config(
              this.wxConfig
          );


          wx.ready(function () {


              }
          );

          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))

          });


        }
      });


    },
    toShowAlert() {
      this.showDialog = true
      console.error("toShowAlert")
    },

    initShowPhoto() {

      let
          params = {
            openId: this.openId,
            cardNumber: this.currentActiveCard,
          }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.yzGetCardShow, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          console.log("res.data:" + res.data.data);
          this.showConfigVO = res.data.data;
          //绑定要映射的内容
          console.log("showConfigVO:" + this.showConfigVO);

          this.buyJumpUrl = res.data.data.yzCardInfoVO.yzJumpUrl;

        } else if (res.data.code === -9999) {
          //todo 跳去另外一个首页
          console
              .error("-9999:跳去另外一个首页")
          // Toast(res.data.message)
        } else {
          // Toast(res.data.message)
          console
              .error("卡渲染异常：" + res.data.message)
        }
      });
    },
    checkPhoneStatus() {
      let params = {
        openId: this.openId,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到下一页
        } else {
          Toast("用户不存在，非法访问");
          window.history.back();
          return false;
        }
      });
    },
    initActiveCard() {
      let params = {
        openId: this.openId
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getActiveCardInfo, params
      ).then(res => {
        debugger
        if (res.data.code === 200) {
          this.activeCardInfo = res.data.data
          //todo 跳转去权益卡首页。
          if (this.activeCardInfo.cardType == 1) {
            //如果当前激活的是药诊卡，则
            this.$router.push("/home");
          }
        } else {
          Toast("请先去激活一张卡")
          this.$router.push("/yzLinkFirstInputCardPage");
          //   console.error('this.$router.push("/yzLinkFirstInputCardPage");')

        }
      });
    },

    toDetailShow() {
      this.$router.push("detailShow");
    },


    checkActiveCard() {

      console.info("-------------init--------checkActiveCard")
      let params = {
        openId: this.openId,
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCurrentActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          //todo
          this.currentActiveCard = res.data.data;

          // 获取用户当前绑定的卡号
          this.initActiveCard();

        } else {
          console.error(' this.$router.push("/yzLinkFirstInputCardPage");')
          //this.$router.push("/yzLinkFirstInputCardPage");
        }
      });
    },
    toBuy() {
      window.location.href = this.buyJumpUrl;
    }
  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


</script>


<style scoped lang="scss">


.nameLst {

  margin-left: 22px;
  margin-bottom: 10px;
}

.sectionHead {
  display: flex;
  flex-direction: row;
}

.sectionHeadRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.headImg {
  width: 42px;
  height: 42px;
  border-radius: 60.5px;
  margin: 20px 15px 28.5px 15px;
}


.service-list-container {
  display: flex;
  flex-direction: row;
  margin: 5.5px 5.5px 0 5.5px;
  flex-wrap: wrap;

  span {
    line-height: 14px;

  }

  .singleServiceTag {

    height: 0.58rem;
    border-radius: 0.12rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.26rem;
    width: 1.76rem;
    border: 1px solid black;
    margin: 4px 4px;

  }


}

.nameFirstContainer {
  .userName {
    font-size: 20px;
    color: #333C4F;
  }
}

.nameSecondContainer {

  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .cardDate {
    font-size: 13px;
    color: #333C4F;
    margin-bottom: 5px;
  }
}

.quanyiButton {
  width: 65px;
  height: 25px;
  background-image: linear-gradient(270deg, #31E8C3 0%, #16CC90 100%);
  border-radius: 7px;
  margin-right: 15px;

  font-family: PingFang-SC-Bold;
  font-size: 10px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 700;;
}

.headFirst {
  width: 100%;
}

.useFirst {
  width: 100%;

}

.listButton {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;

  img {
    width: 100%;
    height: 63.83px;
  }
}

.detail {
  padding-bottom: 110px;
}

.showAlertPngClass {
  width: 344.5px;
  height: auto;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

</style>