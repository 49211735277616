<template>
  <div class="detail">

    <img  class="cardFirst" :src="cardFirst">
    <div class="listButton" >
      <img :src="cardSecond_left" @click="toBuy">
      <img :src="cardSecond_right" @click="toExt"  >
    </div>
    <img class="cardThird"  :src="cardThird" @click="toBuy">
    <img class="cardFour"  :src="cardFour" @click="toExt">

    <ruize-menu>

    </ruize-menu>
  </div>



</template>
<script>


import jb from '@/assets/img/yzHome/LinkBackImg.png'
import {Button, Field, Form, Toast} from "vant"; //Dialog
import Cookies from "js-cookie";
import axios from "axios";
import wx from "weixin-js-sdk";

import ruizeMenu from "@/components/ruize/cheche/RuizeChecheMenu.vue"

//图片
import cardFirst_80 from "@/assets/img/cheche/cardFirst_80.png";
import cardFirst_100 from "@/assets/img/cheche/cardFirst_100.png"
import cardFour from "@/assets/img/cheche/cardFour.png"
import cardSecond_left from "@/assets/img/cheche/cardSecond_left.png"
import cardSecond_right from "@/assets/img/cheche/cardSecond_right.png"
import cardThird_80 from "@/assets/img/cheche/cardThird_80.png"
import cardThird_100 from "@/assets/img/cheche/cardThird_100.png"




export default {
  name: "ChecheCardDetailPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    ruizeMenu
  },
  created() {


    this.checkUserAndInit();

    this.getCardShow();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("睿择健康");
    //检查用户状态。
    this.qdCode = getCurrentUrlQdCode();



  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit: false,
      originUrl: "",
      qdCode: "",
      cardList: [
        {}
      ],
      cardShowInfo: {},

      cardFirst_80:cardFirst_80,
      cardFirst_100:cardFirst_100,
      cardFour:cardFour,
      cardSecond_left:cardSecond_left,
      cardSecond_right:cardSecond_right,
      cardThird_80:cardThird_80,
      cardThird_100:cardThird_100,
      cardThird:"",
      cardFirst:"",


    }
  },

  methods: {
    initWechatJsSdk() {
      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.wxConfig = JSON.parse(res.data.data);
          this.wxConfig.debug = false
          wx.config(
              this.wxConfig
          );
          wx.ready(function () {
              }
          );
          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))
          });
        }
      });
    },
    getCookieOpenIdKey() {
      let qdCode = getCurrentUrlQdCode();
      if (qdCode == undefined) {
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return this.$Constants.RzCookieKey.cookieOpenId + "_" + qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl = "/#" + url.split("#")[1];
              window.location.href = tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:" + tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();

        this.initWechatJsSdk();


      }
    },

    checkPhoneStatus() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }

        } else {

          // window.location.back();
          Toast("请先验证手机号");

          this.qdCode = getCurrentUrlQdCode();
          window.location.href = "/#/ChecheLoginPage?qdCode=" + this.qdCode;
          return false;
        }
      });
    },
    checkCardLink() {
      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.gstAllCard4Cheche, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200 && res.data.data.cardSize > 0) {
          console.log("res.data.data.cardSize");
        } else {
          console.log("未绑定过卡");
          // 曾经绑定过。
          window.location.href = "/#/ChecheInputCardPage?qdCode=" + getCurrentUrlQdCode();
        }
      });
    },
    getCardShow() {
      this.qdCode = getCurrentUrlQdCode();
      //38971c9Db42AF3
      let params = {
        "openId": this.openId,
        "qdCode": this.qdCode
      }
      axios.post(
          this.$Constants.RzApiMethod.checheCardShow, params
      ).then(res => {
        debugger
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              if (res.data.data.showType === 80) {
                //显示80面额

                this.cardFirst=this.cardFirst_80;
                this.cardThird=this.cardThird_80;
              } else {
                this.cardFirst=this.cardFirst_100;
                this.cardThird=this.cardThird_100;
                //显示100面额
              }
            } else {
              return false;
            }
          }
      );
    },
    toBuy(){

      // url 写死
      let url="https://www-test.heyejk.com/heye-h5/open?channelCode=8236&params=5A0601966F45C0786ECEB808C949F69B3073A97F5725455681B3F30F6D2DCD4BC0C2FB844175205562457A11FB4C222319C02292EEA3B8372A654583862F35CEED17333A802419AF2F89FA9BA63FA2071EF7C972F16EB235D06987C485DD0F611C3C5680FB16FC852FADF8A030426495A66E06CDBCF812D39BD98F882028BC86"
      window.location.href=url;


    },
    toExt(){

      console
          .log("---------------++++++++++++----------")
       this.$router.push({path: `/ChecheExtShowPage`})
    }


  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode() {
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode = getUrlParams(url, "qdCode");
  if (qdCode == undefined) {
    let url = window.location.href;
    let originUrl = url.split("#")[1];

    qdCode = getUrlParams(originUrl, "qdCode");

    console.log("qdCode:" + qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">

.cardThird{
  width:100%;
}
.cardFirst{
  width:100%;
}
.cardFour{
  width:100%;
  padding-bottom: 80px;
}
.listButton {
  display: flex;
  flex-direction: row;

  img {
    width: 50%;
  }
}

</style>