<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />
    <DIV>
      <van-tabs  background="#F4F5F9" @click="changeSelectOrderStatus">
        <van-tab  title="全部" name=""  ></van-tab>
        <van-tab title="待付款" name="10" @click="changeSelectOrderStatus(10)"></van-tab>
        <van-tab title="待发货" name="30" @click="changeSelectOrderStatus(30)"></van-tab>
        <van-tab title="待收货" name="40" @click="changeSelectOrderStatus(40)"></van-tab>
        <van-tab title="已取消" name="60" @click="changeSelectOrderStatus(60)"></van-tab>
      </van-tabs>


      <div class="listConstainer" ref="listConstainerObj">
        <div class="section" v-for="item in orderList"   :key="item.orderNo" @click="toDetail(item.orderNo)" >

          <div class="firstHead">
            <div class="leftText">健康福利商城</div>
            <div class="rightText">{{ item.statusName }}</div>
          </div>

          <div class="itemDetailContainer" v-for="sonItem in item.orderDetailList" :key="sonItem.id">

            <div class="leftIconContainer">
              <img :src="sonItem.picUrl">
            </div>

            <div class="middleContainer">
              <van-tag round type="danger">{{ sonItem.tagName }}</van-tag>
              {{ sonItem.name }}
            </div>

            <div class="rightContainer">
              <div class="topText">
                ¥{{ sonItem.priceStr }}
              </div>
              <DIV CLASS="bottomText">
                共{{ sonItem.quantity }}件
              </DIV>
            </div>


          </div>
          <DIV class="priceContainer">
            <div class="leftText"> 实付款</div>
            <div class="rightText">¥{{ item.payAmountStr }}</div>
          </DIV>

          <div class="lineDIV">

          </div>

          <div class="buttonListContainer">
            <div
                class="activeButton" v-if="item.payUrl" @click.stop="toPay(item.payUrl)">
              去支付
            </div>

            <div
                class="simpleButton" v-if="item.payUrl" @click.stop="toCancle(item.orderNo)">
              取消
            </div>


            <div
                class="simpleButton" v-if="item.status==40 || item.status==50" @click.stop="toRefund(item.orderNo)">
              申请退款
            </div>


            <!--            <div-->
            <!--                class="simpleButton" >-->
            <!--              查看详情-->
            <!--            </div>-->

          </div>

        </div>

      </div>

    </DIV>


  </div>
</template>

<script>


import {Toast, Tab, Tabs, Tag, NavBar, Dialog} from 'vant';
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "Empty",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag
  },
  data() {
    return {
      pageTitle: "购药订单",
      activeTabName: 'a',
      orderList: [],
      selectOrderStatus:"",
      openId:"",
      qdCode:""

    }
  },
  created() {
    this.checkUserAndInit();
    this.initUrlAndInfo();
  },
  methods: {
    checkPhoneStatus() {


      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
      axios.post(
           this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if(this.qdCode==""){
            this.qdCode=getCurrentUrlQdCode();

            if(this.qdCode==""){
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl="/#/XihuLoginPage?qdCode="+this.qdCode;
          window.location.href =targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      });
    },
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:"+code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
                this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode=getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          axios.post(
              this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl="/#"+ url.split("#")[1];
              window.location.href =tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:"+tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
      }
    },
    toCancle(){
      //todo 这里提示去找客服

      Dialog.alert({
        title: '温馨提示',
        message: '暂不支持相关操作，请联系“在线客服”获取帮助。',
      }).then(() => {
        //后退一页
        //window.history.back();
      });
    },
    toRefund(){
      //todo 这里提示去找客服

      Dialog.alert({
        title: '温馨提示',
        message: '暂不支持相关操作，请联系“在线客服”获取帮助。',
      }).then(() => {
        //后退一页
        //window.history.back();
      });
    },
    toPay(payUrl){
      console.log("payUrl:"+payUrl)
      window.location.href=payUrl;
      return false;
    },
    initUrlAndInfo() {
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }

      let params={
        openId:this.openId,
        qdCode:qdCode,
        status:this.selectOrderStatus
      }


      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数

      let that=this;

      //检查用户当前激活的卡是否存在。
      axios.post(
            this.$Constants.RzApiMethod.xihuOrderList , params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.orderList = res.data.data;
          console.log("this.orderList.length:"+this.orderList.length)
          if(this.orderList.length<=0){
            console.log("this.$refs.listConstainerObj:"+that.$refs.listConstainerObj)
            const listConstainerObj = that.$refs.listConstainerObj;
            // 修改 class 属性值
            listConstainerObj.classList.add('listConstainer_empty');
          }else {
            const listConstainerObj = that.$refs.listConstainerObj;
            // 修改 class 属性值
            listConstainerObj.classList.remove('listConstainer_empty');
          }

        } else {
          console.error(res)
        }
      });
    },
    toDetail(orderNo) {


      this.qdCode=getCurrentUrlQdCode();
      let tarUrl= "/#/XihuOrderDetail"+"?orderNo="+orderNo+"&qdCode="+this.qdCode;
      window.location.href=tarUrl;

    }, onClickLeft() {
      //返回
      window.history.back();
    },
    changeSelectOrderStatus(name, title ){
      console.error("lll:"+name);
      this.selectOrderStatus=name;
      console.log(title)
      this.initUrlAndInfo();
    }


  }

}



function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}


</script>

<style scoped lang="scss">


body{
  background: #F4F5F9 !important;
}
.testRem1 {
  background: #F4F5F9 !important;
  height: 100%;

}



.listConstainer {

  background: #F4F5F9 !important;
  height: auto;
  padding-bottom: 12px;
}

.listConstainer_empty{
  background-image: url("~@/assets/png/hmb/order_list_empty_logo.png") !important;
  background-repeat: no-repeat !important;
  background-size: 200px 220px !important;
  background-position: 50% 50% !important;
  height: 420px  !important;
}



.section {
  margin: 8px 12px;
  width: 351px;
  height: auto;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
}


.firstHead {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: space-between;
}


.leftText {
  font-size: 14px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 350;
  color: rgba(26, 12, 4, 0.7);
  line-height: 22px;
}

.rightText {
  color: #1A0C04;
  font-size: 14px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 350;
  line-height: 22px;
}


.itemDetailContainer {
  margin-top: 12PX;
  margin-left: 12PX;
  margin-right: 12PX;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  .leftIconContainer {

    img {
      width: 72px;
      height: 72px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;

    }


  }


  .middleContainer {
    margin-left: 8PX;
    width: 187px;
    height: 44px;
    font-size: 14px;
    font-weight: 400;
    color: #1A0C04;
    line-height: 22px;

    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }


  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .topText {
      text-align: right;
      height: 20px;
      font-size: 12px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 20px;
    }

    .bottomText {
      height: 20px;
      font-size: 12px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: rgba(26, 12, 4, 0.5);
      line-height: 20px;
    }
  }


}

.priceContainer {
  display: flex;
  justify-content: flex-end;

  margin-top: 12PX;
  margin-right: 12px;

  .leftText {
    height: 22px;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 400;
    color: #1A0C04;
    line-height: 24px;
    margin-right: 8px;
  }

  .rightText {
    font-size: 20px;
    font-family: OPPOSans-Bold, OPPOSans;
    font-weight: 350;
    color: #FA5151;
    line-height: 24px;
  }


}

.lineDIV {
  margin: 12px;
  width: 327px;
  height: 0px;
  opacity: 1;
  border: 1px solid rgba(26, 12, 4, 0.04);
}

.buttonListContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
  margin-bottom: 12px;

  .activeButton {
    margin-left: 8px;
    width: 88px;
    height: 38px;
    background: #E86922;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;

    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


  .simpleButton {
    margin-left: 8px;
    width: 88px;
    height: 38px;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    border: 1px solid rgba(26, 12, 4, 0.2);
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

  }

}


::v-deep  .van-tab--active{
  color: #E86922 ;
}
</style>