<template>


  <div>


    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        placeholder="true"
    />




    <div class="listConstainer">



    <div class="sectionHead"
         v-for="singleCard in allCardList  "
         v-bind:key="singleCard.cardNumber"
         :cardNumber="singleCard.cardNumber"
         @click="toChangeActiveCard($event)"
    >
      <div class="leftContainer">
        <img class="headImg"
             :src="singleCard.cardLogo"/>
        <div class="headImgBottom">
          卡号： {{singleCard.cardNumber}}
        </div>
      </div>
      <div class="sectionHeadRight">
        <div class="nameList">
              <span class="userName">
              权益人：{{singleCard.authName}}
              </span>
              <span class="userName">
                               有效期：{{singleCard.canUseYear}}年
              </span>
              <span class="userName">
               激活时间：{{singleCard.activeTimeStr}}
              </span>
          <span class="userName">
               当前状态： <spa class="statusShow">生效中 </spa>
              </span>
        </div>

      </div>

    </div>

    </div>

    <van-button class="addButton" @click="toAddCard">
      绑定其他权益卡
    </van-button>

  </div>
</template>

<script>

import {Button, NavBar, Toast} from 'vant';
import Cookies from "js-cookie";
import axios from "axios";
import {mapMutations} from "vuex";


export default {
  name: "CardListPage",
  computed: {

  },
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar
  },
  created() {
    //this.checkLogin();//改为直接获取openId
    this.checkUserAndInit();

    this.checkPhoneStatus();


    this.checkActiveCard();
    this.initAllCardList();

  },
  data() {
    return {
      openId: "",
      allCardList:{},
      pageTitle:"权益卡包"
    }
  },
  methods: {

    checkPhoneStatus(){
      let params = {
        openId: this.openId,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到下一页
        }else {
          //跳转去绑定
          this.$router.push("/");
        }
      });
    },
    checkUserAndInit(){
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
              this.openId = openId;
            }
          });
        }
      }else {
        this.openId = cookieOpenId;
      }
    },
    checkActiveCard() {
      let params = {
        openId: this.openId
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCurrentActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.setCurrentActiveCard(res.data.data);
        } else {
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },
    toChangeActiveCard(e){
      let targets=e.currentTarget;
      let cardNumber= targets.getAttribute("cardNumber");
      console.log("change cardNumber:"+cardNumber);
      let params={
        openId:this.openId,
        cardNumber:cardNumber
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.changeActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          this.setCurrentActiveCard(res.data.data);
          //把当前激活的卡号，设置进去全局变量
          this.$router.push("/home")
        } else {
          Toast("请先去激活一张卡")
          this.$router.push("/linkFirstInputCardPage");
        }
      });

    },
    initAllCardList(){
      let params={
        openId:this.openId
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getAllCardList, params
      ).then(res => {
        if (res.data.code === 200) {
          this.allCardList=res.data.data
          //把当前激活的卡号，设置进去全局变量
        } else {
          Toast("请先去激活一张卡")
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },
    checkLogin(){
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        this.$router.push("/")
      }
      this.openId = cookieOpenId;
    },
    toAddCard() {
      this.$router.push("linkFirstInputCardPage")
    },
    onClickLeft() {
      //返回
      this.$router.back();
    },
    ...mapMutations(['setCurrentActiveCard']),
  }
}





function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}
</script>


<style scoped lang="scss">

.all {
  margin: 5.5px 5.5px 0 5.5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 7px;
  border-radius: 0.2rem;

}


.company-head-container {
  margin: 5.5px 5.5px 0 5.5px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  span {
    margin-left: 5.5px;

  }
}


.sectionHead {
  display: flex;
  flex-direction: row;
  background: #F8F8F8;
  color: black;


}

.sectionHeadRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.service-list-container {
  display: flex;
  flex-direction: row;
  margin: 5.5px 5.5px 0 5.5px;
  flex-wrap: wrap;

  span {
    line-height: 14px;

  }

  .singleServiceTag {

    height: 0.58rem;
    border-radius: 0.12rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.26rem;
    width: 1.76rem;
    border: 1px solid black;
    margin: 4px 4px;

  }


}


.anno-container {
  word-break: break-all;
  margin: 5.5px 5.5px 5.5px 5.5px;
}



  .userName {
    font-size: 13px;
    margin-top: 4.5px;
  }




  .cardDate {
    font-size: 13px;
  }

.quanyiButton {
  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);
  color: white;
  width: 50px;
  height: 25px;
  font-size: 13px;
  line-height: 13px;
  border-radius: 5px;
  margin-right: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  user-select: none;

}


.headImg {
  height: 105px;
  width: 165px;
  margin-top: 32.5px;
  margin-right: 15px;
  margin-left: 15px;
}

.headImgBottom {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  font-size: 13px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
}


.detail {
  margin: 5px;

  img {
    height: 45px;
  }

}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.sectionHead {
  width: 355px;
  margin: auto;
  margin-top: 20px;
}

.addButton {
  position: fixed;
  bottom: 5px;
  width: 325px;
  height: 43px;
  border-radius: 22.5px;
  font-size: 16px;
  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);
  margin-left: 25px;
  margin-right: 25px;
  color: white;
}


.nameList{
  display: flex;
  flex-direction: column;

}

.sectionHead:last-child{

  margin-bottom: 40px;
}


.listConstainer{
  padding-bottom: 40px;

}


.statusShow{
  color: #1AD098;
}
</style>