<template>
  <div class="testRem1">



    <div class="headFirstBg">
      <div class="logoContainer">
        <img :src="cardDetailLeftLogo" class="leftLogo"/>
        <img :src="cardDetailRightLogo" class="rightLogo"/>
      </div>


    </div>

    <div class="bigLogoContainer">
      <img :src="cardDetailBigLogo" class="bigLogo"/>
    </div>

    <div class="firstContainer">

      江苏服务型普惠医疗险
    </div>
    <div class="firstContainer">
      <div class="title2Container">
        <div class="textLeft">被保险人</div>
        <div class="textRight">{{ claimInfo.userName }}</div>
      </div>

      <div class="title2Container">
        <div class="textLeft">手机号</div>
        <div class="textRight">{{ claimInfo.phone }}</div>
      </div>
      <!--      <div class="title2Container">-->
      <!--        <div class="textLeft">保障期限</div>-->
      <!--        <div class="textRight">{{claimInfo.startDate}}-{{claimInfo.endDate}}</div>-->
      <!--      </div>-->


<!--      <div class="title2Container">-->
<!--        <div class="textLeft">保单号</div>-->
<!--        <div class="textRight">{{ claimInfo.policyNo }}</div>-->
<!--      </div>-->
    </div>

    <div class="secondContainer">
      <div class="myTitle">
        一般门诊责任
      </div>

      <div class="s_first_container">
        <div class="title2Container">
          <div class="textLeft">累计剩余理赔额度</div>
          <div class="textRight">
            <span class="leftNumber">{{claimInfo.shandongAllBalanceVO.medicineRight.leftAmountStr}}</span><span class="rightNumber">/200000</span>
          </div>
        </div>


        <div class="title2Container">
          <div class="textLeft">累计剩余理赔次数</div>
          <div class="textRight">
            <span class="leftNumber">{{claimInfo.shandongAllBalanceVO.medicineRight.totalLeft}}</span><span class="rightNumber">/20</span>
          </div>
        </div>
      </div>

      <div class="s_second_container">

        <div class="leftContainer">
          <div class="firstTitle">
            在线图文问诊
          </div>
          <div class="secondTitle">
            不限次
          </div>
        </div>
        <div class="rightContainer">
          <div class="rightSonContainer" >
            <div class="firstTitleSon">
              单次理赔限额
            </div>
            <div class="secondTitleSon">
              1000元
            </div>
          </div>

          <div class="rightSonContainer" >
            <div class="firstTitleSon">
              购药直赔
            </div>
            <div class="secondTitleSon">
              首次理赔100%，后续70%
            </div>
          </div>

          <div class="rightSonContainer endSon" >
            <div class="firstTitleSon">
              全国配送
            </div>
            <div class="secondTitleSon">
              首次包邮
            </div>
          </div>




        </div>

      </div>



      <div class="buttonContainer">
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="toSubmitButton"
                    @click="toLipei"
        >去问诊
        </van-button>
      </div>

    </div>
    <div class="secondContainer endClass">
      <div class="myTitle">
        中医门诊责任
      </div>

      <div class="s_first_container">
        <div class="title2Container">
          <div class="textLeft">累计剩余理赔额度</div>
          <div class="textRight">
            <span class="leftNumber">{{claimInfo.jiangsuExtBalanceVO.moneyBalance}}</span><span class="rightNumber">/10000</span>
          </div>
        </div>




        <div class="title2Container">
          <div class="textLeft">累计剩余理赔次数</div>
          <div class="textRight">
            <span class="leftNumber">{{claimInfo.jiangsuExtBalanceVO.twiceBalance}}</span><span class="rightNumber">/20</span>
          </div>
        </div>
      </div>

      <div class="s_second_container">

        <div class="leftContainer">
          <div class="firstTitle">
            在线图文问诊
          </div>
          <div class="secondTitle">
            不限次
            <br>
            理赔50%
          </div>
        </div>
        <div class="rightContainer">
          <div class="rightSonContainer" >
            <div class="firstTitleSon">
              单次理赔限额
            </div>
            <div class="secondTitleSon">
              1000元
            </div>
          </div>

          <div class="rightSonContainer" >
            <div class="firstTitleSon">
              购药直赔
            </div>
            <div class="secondTitleSon">
              理赔50%
            </div>
          </div>

          <div class="rightSonContainer endSon" >
            <div class="firstTitleSon">
              全国配送
            </div>
            <div class="secondTitleSon">
              首次包邮，后续满88包邮
            </div>
          </div>




        </div>

      </div>
      <div class="buttonContainer">
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="toSubmitButton"
                    @click="toZhongYi"
        >去中医问诊
        </van-button>
      </div>

    </div>
    <ruize-menu>


    </ruize-menu>


  </div>
</template>


<script>


import {Button, NavBar, Toast, Uploader,Dialog} from 'vant';
import axios from "axios";

import cardDetail_leftLogo from "@/assets/img/jiangsu/jiangsu_word.png"
import cardDetail_rigghtLogo from "@/assets/img/shandong/cardDetail_rightLogo.png"
import cardDetailBigLogo from "@/assets/img/jiangsu/cardDetailBigLogo.png"
import Cookies from "js-cookie";
import ruizeMenu from "@/components/ruize/jiangsu/RuizeJiangSuMenu.vue"
import {mapState} from "vuex";

//import axios from "axios";

export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader,
    ruizeMenu,
    [Dialog.name]:Dialog

  }, data() {
    return {
      pageTitle: "保单详情",
      claimUrl: "",
      //todo 更新这个地址
      orderListUrl: "/#/ShandongOrderList?qdCode=jiangsu1001",
      buyShopUrl: "",
      claimInfo: {
        "shandongAllBalanceVO": {
          "medicineRight": {
            "leftAmountStr": "1234",
            "totalLeft": "123",
            "monthLeft": "1234",
            "usableMoneyStr": "1234",
          }
        }
      },
      urlParams: "",

      //shandong
      cardDetailLeftLogo: cardDetail_leftLogo,
      cardDetailRightLogo: cardDetail_rigghtLogo,
      cardDetailBigLogo: cardDetailBigLogo,
      //shandong
      openId: "",
      qdCode: "",
    }
  },
  methods: {
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要登陆。
        window.location.href="/#/JiangsuLoginPage?qdCode="+getCurrentUrlQdCode();
        //code 都没有，则需要同步访问了。
      } else {
        this.openId = cookieOpenId;
        await this.checkPhoneStatus();
      }
    },

    toLipei() {
      if (this.claimUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.claimUrl
      }
    },
    toZhongYi(){

      Dialog.alert({
        title: '服务流程',
          message: '1、添加专属客服企业微信，描述您的需求，客服会核实您的保险信息。\n2、您的专属客服会组建多对一的服务群，群内为您推荐名中医，协助完成预约。\n3、相关诊疗及药品费用您只需支付经保险理赔后的金额。\n客服在线时间：9:00~18:00',
          messageAlign:"left",
        confirmButtonText:"联系客服",
        confirmButtonColor:"#E86922",
        closeOnClickOverlay:true
      }).then(() => {
        window.location.href="https://work.weixin.qq.com/ca/cawcded07c8ddd0f27";
      });


    },
    toShop() {
      if (this.buyShopUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.buyShopUrl
      }
    } ,

    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
      //不支持火腿了
      //window.history.back();
    },
    async initUrlAndInfo() {

      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      this.openId = cookieOpenId;
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: this.qdCode,
        openId: this.openId
      }


      //检查用户当前激活的卡是否存在。
      const res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuDetail, toSendParams
      );

      {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.claimUrl = res.data.data.claimUrl;
          this.buyShopUrl = res.data.data.buyShopUrl;
          //this.orderListUrl = res.data.data.orderListUrl;
          this.claimInfo = res.data.data.info;

         let tempPhone= this.claimInfo.phone;
           tempPhone=tempPhone.substring(0,4)+"****"+ tempPhone.substring(8,tempPhone.length);
          this.claimInfo.phone=tempPhone;

          this.claimInfo.jiangsuExtBalanceVO.moneyBalance=formatNumber(this.claimInfo.jiangsuExtBalanceVO.moneyBalance)
        } else {
          console.error(res)
          alert(res.data.msg);
        }
      }
    }
    ,
    async checkCard() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());

      let params = {
        openId: cookieOpenId,
        qdCode: this.qdCode,
      }

      let res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuCheckPolicyLink, params
      );

      {

        console.log(res);
        if (res.data.code === 200) {
          console.log("ok")
        } else {
          console.log("not link phone")
          // 跳转到输入卡密页面。
          let targetUrl = "/#/JiangsuInputCardPage?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          return false;
        }
      }

    },
    async checkPhoneStatus() {

      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      if(this.openId==null||this.openId==undefined){
        Toast("请先登陆");

        let targetUrl = "/#/ShandongLoginPage?qdCode=" + this.qdCode;
        window.location.href = targetUrl;
        // window.location.back();
        console.log("not link phone")
      }

      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      let res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuCheckPhone, params
      );

      {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl = "/#/JiangsuLoginPage?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      }
    },
  },
  created() {

    this.checkUserAndInit();
    this.checkPhoneStatus();
    this.checkCard();
    this.initUrlAndInfo();

    //菜单默认选择
    this.menuStatus.currentMenu="home"
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("首页");
  },
  computed: {
    ...mapState(["menuStatus"])
  },


}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}

function getCurrentUrlQdCode() {
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode = getUrlParams(url, "qdCode");

  if (qdCode == undefined) {
    let url = window.location.href;

    let originUrl = url.split("#")[1];

    qdCode = getUrlParams(originUrl, "qdCode");

    console.log("qdCode:" + qdCode)

  }
  return qdCode;
}


function formatNumber(number) {
  // 判断是否为小数
  if (number % 1 !== 0) {
    // 将小数四舍五入到两位小数，并保留小数点
    number = number.toFixed(2);
  } else {
    // 去掉小数点及后面的 0
    number = Math.floor(number);
  }

  return number;
}

</script>

<style scoped lang="scss">


.toSubmitButton {


  margin-bottom: 10px;


  width: 200px;
  height: 46px;
  background: #E86922;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
}


.testRem1 {
  height: auto;
  display: flex;
  flex-direction: column;
  background: #E86922 !important;
}

.firstContainer {
  //margin-top: -40px;
  //12 0.32
  padding-top: 13px;
  margin: -80px 12px 12px 12px;
  width: 351px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;

  .title1Container {
    margin: 12px 12px 36px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textLeft {
      margin: auto auto;
      align-content: center;
      justify-content: center;
      width: 263px;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      color: #1A0C04;
      line-height: 34px;
      opacity: 0.6;
    }

    .imgRight {
      width: 56px;
      height: 56px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }


}


.title2Container {
  display: flex;
  flex-direction: row;
  margin: 0 12px 12px 12px;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;

  .textLeft {
    color: rgba(26, 12, 4, 0.4);
    font-family: OPPOSans-Medium, OPPOSans;
  }

  .textRight {
    font-weight: 500;
    color: #1A0C04;
    font-family: OPPOSans-Medium, OPPOSans;
  }


}


.secondContainer {
  width: 351px;

  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;

}

.secondContainer:last-of-type{
  margin: 0px 12px 24px 12px;
}

.myTitle {
  border-left: solid 2px #E86922;
  margin-left: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #31231B;
  padding-left: 6px;
}


.firstContainer {
  .textLeft {
    color: #E86922;
  }
}

.secondContainer {
  .textLeft {
    color: #E86922;
  }
}


.thirdContainer {
  width: 351px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;


}

.iconContainer {


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;

  .buttonAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 98px;
    height: 62px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 14px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 22px;
    }
  }
}


.headFirstBg {
  height: 180px;
  background-image: url(~@/assets/img/jiangsu/cardDetailBg.png);
  background-size: cover;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.logoContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .rightLogo {
    margin-right: -10px;
  }

}

.leftLogo {
  width: 8rem;
  height: 30px;
}

.rightLogo {
  height: 22px;
}

.bigLogoContainer {
  margin-top: 20px;

  .bigLogo {
    width: 10rem;
    margin-top: -4rem;
  }
}

.buttonContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

}

.addButton {
  margin-top: 10px;
  bottom: 5px;
  width: 325px;
  height: 43px;
  border-radius: 22.5px;
  font-size: 16px;
  background: #F8F8F8;
  margin-left: 25px;
  margin-right: 25px;
  color: #7F8389;

}

.s_first_container {

  background-color: #FFFDF1;
  margin: 12px;
  padding-top: 12px;

}

.leftNumber {
  color: #FA5151;
}

.rightNumber {
  color: #1A0C04;
  opacity: 0.4;
}

.s_second_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .leftContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px 12px 12px;

    align-content: center;
    width: 131px;
    height: 204px;
    justify-content: center;
    background-color: #FEF9F3;

    .firstTitle {
      color: #E86922;
      width: 131px;
      height: 24px;
      font-size: 16px;
      text-align: center;

    }

    .secondTitle {
      color: #1A0C04;
      opacity: 0.5;
      height: 22px;
      font-size: 14px;
      text-align: center;
      margin-top: 8px;

    }
  }

  .rightContainer{
    width: 188px;
    height: 204px;
    background-color: #FEF9F3;
    margin: 0 12px 12px 0;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    justify-content: space-between;
    font-size: 12px;

    .rightSonContainer{
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      .firstTitleSon{
        color: #E86922;
        font-size: 14px;
        line-height: 22px;
      }
      .secondTitleSon{
        color: #1A0C04;
        opacity: 0.5;
        font-size: 14px;
        line-height: 22px;
             }
    }
    .endSon{
      margin-bottom: 12px;
    }

  }
}

.endClass{
  margin-bottom: 100px;
}
</style>