const jttUser = "jttUser";


//let devHost = "http://124.221.43.94/gst";
// let devHost = "http://localhost:19998";
//let devHost="https://270192bn06.zicp.fun/";
  let devHost="//www.ruizehealth.com/";

let RzCookieKey = {
    cookieOpenId: "cookieOpenId",
}

let RzApiMethod= {
    getBaseUserRedirectUrl: "/api/rzUser/getBaseUserRedirectUrl",
    getUserOpenId:"/api/rzUser/getUserOpenId",
    getUserPhoneStatus:"/api/rzUser/getUserPhoneStatus",
    sendCode: "/api/phone/sendCode",
    savePhone:"/api/rzUser/savePhone",
    getWxConfig:"/api/rzUser/getJsApiConfig",
    cardLink:"/api/rzUser/cardLink",
    bindCard:"/api/rzUser/bindCard",
    confirmCardActive:"/api/rzUser/confirmCardActive",
    getCurrentActiveCard:"/api/rzUser/getCurrentActiveCard",
    getBuyTargetUrl:"/api/rzUser/getBuyTargetUrl",
    getBuyOrderListUrl:"/api/rzUser/getBuyOrderListUrl",

    getAllCardList:"/api/rzUser/getAllCardList",
    getActiveCardInfo:"/api/rzUser/getActiveCardInfo",

    //展示的配置
    getCardShow:"/api/rzUser/getCardShow",

    getUserAuthStatus:"/api/rzUser/getUserAuthStatus",
    changeActiveCard:"/api/rzUser/changeActiveCard",

    getArticleById:"/api/rzUser/getArticleById",

    //理赔url
    getClaimUrl:"/api/rzUser/getClaimUrl" ,

    checkHasCard:"/api/rzUser/checkHasCard",

    // 药诊卡
    yzCardLink:"/api/yz/cardLink",
    getYzBuyTargetUrl:"/api/yz/getBuyTargetUrl",
    //展示的配置
    yzGetCardShow:"/api/yz/getCardShow",
    yzGetCurrentActiveCard:"/api/yz/getCurrentActiveCard",
    yzChangeActiveCard:"/api/yz/changeActiveCard",
    yzGetAllCardList:"/api/yz/getAllCardList",
    //获取最新绑定那个药诊卡的购买链接，用于查看订单列表
    getYzBuyOrderListUrl:"/api/yz/getBuyOrderListUrl",

    //免密登陆跳转
    dealJump:"/api/yz/dealJump",

    // 湖北惠民保,卡的详情
    hmbRzDetail:"/api/huimin/rzDetail",
    hmbRzOrderList:"/api/rz/hmb/order/list",
    hmbRzOrderDetail:"/api/rz/hmb/order/detail",
    hmbRzDeliverDetail:"/api/huimin/order/deliverInfo",
    hmbRzCardUpload:"/api/huimin/upload/deal",
    hmbRzCardCheck:"/api/huimin/upload/check",

    // 固生堂
    gstGetHasReg: "/api/gst/getHasReg",
    // todo 用不上的url 就删除掉
    // gstSetReg: "/api/gst/setReg",
    // gstCreateUser: "/api/user/create",
    // gstQueryRemain: "/api/inquiryVideo/queryRemainNum"


    //cutBuy 打折购药
    getUserOpenId4CutBuy:"/api/cutBuy/getUserOpenId",
    getUserPhoneStatus4CutBuy:"/api/cutBuy/getUserPhoneStatus",
    savePhone4CutBuy:"/api/cutBuy/savePhone",
    yzCardLink4CutBuy:"/api/cutBuy/cardLink",
    allCard4CutBuy:"/api/cutBuy/allCard",

    loginOut4CutBuy:"/api/cutBuy/loginOut",

    // gst cutBuy

    gstCardLink4CutBuy:"/api/gst/cutBuy/cardLink",
    gstAllCard4CutBuy:"/api/gst/cutBuy/allCard",



    // 混合配置 ，一个卡对应多种组合权益
    mixCardLink:"/api/mix/cardLink",
    mixAllCard4CutBuy:"/api/mix/allCard",


    //山东
    //获取用户信息

    shandongCheckPolicy:"/api/shandong/checkPolicy",
    shandongCheckPolicyLink:"/api/shandong/checkPolicyLink",
    shandongRzDetail:"/api/shandong/shandongRzDetail",
    shandongOrderList:"/api/shandong/order/orderList",
    shandongOrderDetail:"/api/shandong/order/detail",
    shandongDeliveryInfo:"/api/shandong/order/deliverInfo",


    //xihu
    xihuSendCode: "/api/phone/xihuSendCode",
    xihuJumpUrl: "/api/xihu/jumpUrl",
    xihuOrderList: "/api/xihu/order/orderList",
    xihuOrderDetail: "/api/xihu/order/detail",

    //cheche

    checheCardLink4CutBuy:"/api/cheche/cardLink",
    getAllCard4Cheche:"/api/cheche/allCard",
    checheCardShow:"/api/cheche/checheCardShow",


    // 江苏
    jiangsuCheckPhone:"/api/jiangsu/checkPhone",
    // 检查与绑定身份证
    jiangsuCheckPolicy:"/api/jiangsu/checkPolicy",
    //保存手机号
    jiangsuSavePhone:"/api/jiangsu/savePhone",
    //详情
    jiangsuDetail:"/api/jiangsu/jiangsuDetail",
    //简单手机号
    getSinglePhone:"/api/jiangsu/getSinglePhone",
jiangsuCheckPolicyLink:"/api/jiangsu/checkPolicyLink",





}

let loginConsants = {
    authToken: "authToken",
}


let rzRoutePath={
    hmbOrderDetailPath:"/#/hmbOrderDetail",
    hmbDeliveryDetailPath:"/#/hmbDeliveryDetail",
}


let shandongPath={
    orderDetailPath:"/#/ShandongOrderDetail",
    deliveryDetailPath:"/#/ShandongDeliveryDetail",
}



// let xihuRoutePath={
//     hmbOrderDetailPath:"/#/hmbOrderDetail",
//     hmbDeliveryDetailPath:"/#/hmbDeliveryDetail",
// }


let allJttApiMethod = {
    loginSmScode: "/api/login/loginSmScode",
    submitLogin: "/api/login/submitLogin",
    addNewContent: "/api/content/add"
}



function   setTitle(title) {
    document.title = title;
}

function getUrlParams(url, key) {
    // 通过 ? 分割获取后面的参数字符串

    if (url.indexOf("?") > 0) {
        let urlStr = url.split('?')[1]
        // 创建空对象存储参数
        let obj = {};
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
            // 再通过 = 将每一个参数分割为 key:value 的形式
            let arr = paramsArr[i].split('=')
            obj[arr[0]] = arr[1];
        }
        return obj[key]
    }
    return null;
}



export default {
    jttUser,
    devHost,
    allApiMethod: allJttApiMethod,
    loginConsants,
    RzCookieKey,
    RzApiMethod,
    setTitle,
    getUrlParams,
    rzRoutePath,
    shandongPath
}