<template>
  <div>
    <div class="headBg">

      <img v-bind:src="jjj"
      >
    </div>
    <div class="formContent">
      <span class="left">
        权益使用人信息
      </span>

    </div>


    <van-form @submit="toShowPage">

      <van-field
          v-model="userName"
          label="姓名"
          placeholder="请输入姓名"
          input-align="right"
          class="myForm"
          @change="changeForm"

      />

      <van-cell title="证件类型"
                is-link
                class="myForm"
                v-model="cardType"
                @click="showCardType = true"

      />

      <van-field
          v-model="cardNumber"
          label="证件号"
          placeholder="请输入证件号码"
          input-align="right"
          class="myForm"
          @change="changeForm"
          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"

      />


      <van-cell
          @click="showSelectBirthday=true"
          is-link
          v-model="selectDate"
          title="出生日期"
          v-show="showExt"
          value="请选择出生日期"
          input-align="right"
          class="myForm"
          @change="changeForm"

      />

      <van-cell
          title="性别"
          is-link
          v-show="showExt"
          v-model="genderType"
          placeholder="请选择性别"
          input-align="right"
          class="myForm"
          @click="showGenderType = true"
          @change="changeForm"

      >
      </van-cell>

    </van-form>


    <div>
      <van-button round
                  block
                  type="info"
                  native-type="submit"
                  class="submitButton"
                  @click="dealSubmit"
      >确认绑定
      </van-button>
    </div>


    <!-- 证件类型选项 -->
    <van-popup v-model="showCardType" position="bottom">
      <van-picker
          show-toolbar
          :columns="columns"
          @confirm="comfirmShowCardType"
          @cancel="showCardType = false"
      />
    </van-popup>

    <!-- 出生日期选择 -->
    <van-popup v-model="showSelectBirthday" position="bottom">
      <van-datetime-picker
          v-model="selectDateOrigin"
          type="date"
          title="选择出生日期"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="confirmBirthday"
      />
    </van-popup>


    <!-- 性别选项 -->
    <van-popup v-model="showGenderType" position="bottom">
      <van-picker
          show-toolbar
          :columns="genderList"
          @confirm="comfirmGenderType"
          @cancel="showGenderType = false"
      />
    </van-popup>
  </div>


</template>

<script>


import jb from '@/assets/png/bind_second@2x.png'
import {Button, Field, Form, Cell, Checkbox, CheckboxGroup, Toast, Popup, Picker, DatetimePicker} from "vant";
import {mapState} from "vuex";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "KycFirstPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker
  },
  created() {
    let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
    if (cookieOpenId == undefined) {
      this.$router.push("/")
    }
    this.openId = cookieOpenId;

    this.toLinkCardNumber = this.$route.query.toLinkCardNumber;

    console.log("this.toLinkCardNumber:"+this.toLinkCardNumber)

    //检查用户的认证状态，认证通过直接就过了。
    //this.checkUserAuthStatus();
    this.$Constants.setTitle("睿择健康");
  },
  data() {
    return {
      jjj: jb,
      openId: "",
      showCardType: false,
      columns: ['居民身份证', '护照', '香港居住证', '澳门居住证', '台湾居住证'],
      selectDateOrigin: new Date(1990, 1, 1),
      minDate: new Date(1900, 1, 1),
      maxDate: new Date(2025, 12, 1),
      genderList: ["男", "女"],
      canNext: false,
      showExt: false,
      showSelectBirthday: false,
      showGenderType: false,
      //用户名字
      userName: "",
      //证件类型
      cardType: "居民身份证",
      //证件编号
      cardNumber: "",
      //性别
      genderType: "请选择性别",
      //出生年月
      selectDate: "请选择出生日期",
      toLinkCardNumber: ''

    }
  },
  computed: {
    ...mapState(["currentCardNumber"])
  },
  methods: {
    checkUserAuthStatus() {

      let params = {
        openId: this.openId
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserAuthStatus, params
      ).then(res => {
        //todo      这里比较特殊，用了4
        if (res.data.code === 4) {
          //把当前激活的卡号，设置进去全局变量
          //直接跳到去第三个页面
          this.$router.push("/LinkThirdShowProfit");
        } else {
          console.log("需要做实名认证")
        }
      });

    }
    ,
    comfirmShowCardType(value) {
      this.cardType = value;
      this.showCardType = false;
      if (value != "居民身份证") {
        this.showExt = true;
      } else {
        this.showExt = false;
      }
    },
    onSubmit(value2) {
      console.log(value2)
    },
    toShowPage() {
      console.log('-------')
    },
    comfirmGenderType(value) {
      this.genderType = value;
      this.showGenderType = false;
    },
    confirmBirthday(value) {
      this.showSelectBirthday = false;
      this.selectDate = formatDate(value, "yyyy-MM-dd");
    },
    changeForm() {
      //监听整个表的变化
      if (this.cardType == "居民身份证") {
        if (this.cardNumber.length == 18 && this.userName.length >= 2 ) {
          // 检查当前生日是否大于70岁
          //let tempBirthday = this.cardNumber
          //return dealCardDate(tempBirthday);
          return true;//交给后端判断
        } else {
          return false;
        }
      } else {
        if (this.selectDate != "请选择出生日期") {
          // let oldYear = getCurrentAgeByBirthDate(this.selectDate);
          // if (oldYear >= 70) {
          //   Toast("大于70周岁不能使用,请更换使用人")
          //   return false;
          // } else {
          //   return true;
          // }
          return true;//交给后端判断
        } else {
          return false;
        }
      }
    },


    dealSubmit() {


      //跳转到下一个页面
      //Toast("xiayie 。")

      //检查输入项
      if (this.userName == "") {


        Toast("请输入姓名");
        return false;
      } else if (this.userName.length < 2) {
        Toast("请输入正确姓名");
        return false
      }
      if (this.cardNumber == "") {
        Toast("请输入证件号码");
        return false;


      } else if (this.cardNumber.length < 3) {
        Toast("请输入正确证件号码");
        return false;
      }

      if (this.cardType != "居民身份证") {

        if (this.genderType == "") {
          Toast("请选择性别");
          return false;
        }

        if (this.selectDate == "") {
          //核对年龄
          if (this.cardType == "居民身份证") {
            if (this.cardNumber.length != 18) {
              Toast("请输入正确的身份证号码");
              return false;
            } else {
              // let tempBirthday = this.cardNumber
              // let checkResult = dealCardDate(tempBirthday);
              // if (!checkResult) {
              //   Toast("大于70周岁不能使用,请更换使用人")
              //   return false;
              // }
            }
          } else {
            Toast("请选择出生日期");
            return false;
          }
        } else {
          //核对年龄，使用输入值
          try {
            // let oldYear = getCurrentAgeByBirthDate(this.selectDate);
            // if (oldYear >= 70) {
            //   Toast("大于70周岁不能使用,请更换使用人")
            //   return false;
            // }
          } catch (E) {
            Toast("请确认出生日期")
            return false;
          }
        }
      }

      //表单内容是否校验通过，可以提交
      //校验通过了年龄


      let params = {
        openId: this.openId,
        //用户名字
        userName: this.userName,
        //证件类型
        cardType: this.cardType,
        //证件编号
        authCardNumber: this.cardNumber,
        //性别
        genderType: this.genderType,
        //出生年月
        selectDate: this.selectDate,
        currentCardNumber: this.currentCardNumber
      }

      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.bindCard, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          //进入下一页。
          this.$router.push("/linkThirdShowProfit?toLinkCardNumber=" + this.toLinkCardNumber);
        } else {
          Toast(res.data.message)
        }
      });


    },
    toArticleSingleInfo() {
      // 这是去
      let articleId = "3";
      this.$router.push("/singleArticlePage?articleId=" + articleId);
    }
  },
}


function formatDate(date, format) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  format = format.replace('yyyy', year);
  format = format.replace('MM', padZero(month));
  format = format.replace('dd', padZero(day));
  format = format.replace('HH', padZero(hours));
  format = format.replace('mm', padZero(minutes));
  format = format.replace('ss', padZero(seconds));

  return format;
}


/**
 * 通过出生日期获取当前年龄
 * @param strBirthday：指的是出生日期，格式为"1990-01-01",字符串类型
 */
// function getCurrentAgeByBirthDate(strBirthday) {
//   // 将出生日期的字符串通过"-"分割成数组
//   const strBirthdayArr = strBirthday.split("-")
//   // 拿到出生日期的年
//   const birthYear = strBirthdayArr[0]
//   // 拿到出生日期的月
//   const birthMonth = strBirthdayArr[1]
//   // 拿到出生日期的日
//   const birthDay = strBirthdayArr[2]
//   // 创建一个时间对象
//   const d = new Date()
//   // 拿到当前时间的年
//   const nowYear = d.getFullYear()
//   // 拿到当前时间的月
//   const nowMonth = d.getMonth() + 1
//   // 拿到当前时间的日
//   const nowDay = d.getDate()
//   // 如果出生日期的年等于当前时间的年
//   if (nowYear === birthYear) return 0 // 返回周岁年龄 0,并终止函数执行
//   // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
//   const ageDiff = nowYear - birthYear; // 年之差
//   // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
//   if (ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
//   // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
//   if (nowMonth !== Number(birthMonth)) {
//     // 拿到出生日期的日与当前时间的月之差
//     const monthDiff = nowMonth - birthMonth; // 月之差
//     // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
//     return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
//   }
//   // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
//   const dayDiff = nowDay - birthDay;
//   // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
//   return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
// }


function padZero(n) {
  return n < 10 ? '0' + n : n;
}


// function dealCardDate(tempBirthday) {
//   let tempBirthdayA = tempBirthday.substring(6, 14);
//   let year = tempBirthdayA.substring(0, 4);
//   let month = tempBirthdayA.substring(4, 6);
//   let day = tempBirthdayA.substring(6, 8);
//
//   let willOld = year + "-" + month + "-" + day
//   let oldYear = getCurrentAgeByBirthDate(willOld);
//   if (oldYear >= 70) {
//     return false;
//   } else {
//     return true;
//   }
// }

</script>

<style scoped lang="scss">


.headBg {
  img {
    // width:100%;
    //width: 750px;
    height: 193px;
  }
}

.formContent {

  display: flex;
  justify-content: space-between;

  .left {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 28px;
  }

}

.myForm {
  background: #F8F8F8;
  margin-left: 23px;
  width: 325px;
  margin-right: 25px;
  margin-bottom: 14px;
  font-size: 14px;
}


.submitButton {
  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 16px;

  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}


.myCheckBox {
  margin-left: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;

  span {
    font-size: 12px;
    line-height: 12px;
  }
;
}


.checkBoxDesc {
  color: #B6B9C4;
}

</style>