<template>
  <div class="allParent">

    <img :src="findFirst"/>
    <img :src="findSecond"/>

    <van-button round
                block
                type="info"
                native-type="submit"
                class="toSubmitButton"
                @click="toCall"
    >拨打找药热线
    </van-button>

  </div>
</template>

<script>


import jb from '@/assets/img/yzHome/LinkBackImg.png'
import {Button, Field, Form} from "vant"; //Dialog


import findFirst from "@/assets/img/cheche/findFirst.png"
import findSecond from "@/assets/img/cheche/findSecond.png"


export default {
  name: "GstCutBuyInputCardPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button
  },
  created() {

    this.$Constants.setTitle("全球找药权益");
  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit: false,
      originUrl: "",
      qdCode: "",
      cardList: [
        {}
      ],
      findFirst: findFirst,
      findSecond: findSecond
    }
  },

  methods: {
    toCall(){
      window.location.href="tel:4006998620";
    }


  }
}


</script>

<style scoped lang="scss">

.allParent{
  img{
    width: 100%;
  }
}
.toSubmitButton {


  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 25px;
  font-size: 16px;
  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}
</style>