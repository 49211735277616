<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="section deliveryNoClass"  >
      <div  style="margin-left: 12px">
        {{ deliveryDetail.deliverCompany }}： {{ deliveryDetail.deliverNo }}
      </div>
      <van-button style="margin-right: 12px" type="default">复制</van-button>
    </div>

    <div class="section firstSection">
      <div class="contractLink">
        <div class="leftText">收</div>
        <div class="rightText">{{orderDetail.name}}</div>
        <div class="rightText">{{orderDetail.phone}}</div>
      </div>
      <div class="detailAddressContainer">
        <div class="detailAddress">
          {{orderDetail.address}}
        </div>
      </div>
    </div>

    <div class="lietContaner">
      <div class="son" v-for="item in deliveryDetail.deliverList" :key="item.id">
        <div class="first">
          <div class="dot">
          </div>
          <div class="status"> {{item.deliverStatus}} </div>
        </div>
        <div class="statusTime">{{dealShowTime(item.deliverTime)}} </div>
      </div>
    </div>
  </div>
</template>

<script>


import {Toast, Tab, Tabs, Tag, NavBar, Cell} from 'vant';
import axios from "axios";
import moment from 'moment'
import Cookies from "js-cookie";

export default {
  name: "Empty",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [Cell.name]: Cell
  },
  data() {
    return {
      pageTitle: "订单详情",
      activeTabName: 'a',
      orderDetail: {},
      items: [{"orderNo": "1234"}, {"orderNo": "1234"}],
      deliveryDetail:{},
      openId:""
    }
  },
  created() {
    this.checkUserAndInit();
    this.initOrderDetail();
    this.checkPhoneStatus();
    this.initDeliveryDetail();
  },
  methods: { getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:"+code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode=getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl="/#"+ url.split("#")[1];
              window.location.href =tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:"+tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
      }
    },checkPhoneStatus() {


      this.qdCode=getCurrentUrlQdCode();
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
      axios.post(
          this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if(this.qdCode==""){
            this.qdCode=getCurrentUrlQdCode();

            if(this.qdCode==""){
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl="/#/XihuLoginPage?qdCode="+this.qdCode;
          window.location.href =targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      });
    },
    dealShowTime(time){
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    initOrderDetail() {
      let currentUrl = window.location.href;

      debugger

      let orderNo =  getUrlParams(currentUrl,"orderNo");
      debugger
      console.log("orderNo="+orderNo)

      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }

      console.log("qdCode="+ this.qdCode)
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: this.qdCode,
        orderNo: orderNo,
        openId:this.openId
      }

      let urlParams = "";
      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        urlParams = urlS[1]
      }


      let that = this;
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.RzApiMethod.shandongOrderDetail + "?" + urlParams, toSendParams
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量

          if (res.data.data.length > 0) {
            this.orderDetail = res.data.data[0];
            console.info("orderDetail:" + this.orderDetail)

            //设置状态颜色
            if (this.orderDetail.statusName == "待支付") {
              that.$refs.statusNameDom.classList.add("orderStatusName_toPay");
            }

            if(this.orderDetail.status==40 || this.orderDetail.status==50){
              this.showDelivery=true;
            }

          }
        } else {
          console.error(res)
        }
      });
    },

    initDeliveryDetail() {
      let currentUrl = window.location.href;
      let originParams = new URLSearchParams(currentUrl);
      let sign = originParams.get("sign");
      let qdCode = originParams.get("qdCode");
      let fromParams = originParams.get("params");

      let orderNo =  getUrlParams(currentUrl,"orderNo");
      this.qdCode= getUrlParams(currentUrl,"qdCode");
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        sign: sign,
        qdCode: qdCode,
        params: fromParams,
        openId:this.openId,
        orderNo:orderNo
      }

      let urlParams = "";
      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        urlParams = urlS[1]
      }


      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.RzApiMethod.shandongDeliveryInfo + "?" + urlParams, toSendParams
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量

          this.deliveryDetail =JSON.parse(res.data.data);
          console.info("deliveryDetail:" + this.deliveryDetail)



        } else {
          console.error(res)
        }
      });
    },

    onClickLeft() {
      //返回
      window.history.back();
    }
  }
}




function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}





</script>

<style scoped lang="scss">
.testRem1 {
  background: #F4F5F9 !important;
  height: auto;
}
.ruizeNav{
  color:    #E86922;
}

.orderStatusName {

  width: 375px;
  height: 60px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  font-size: 20px;
  font-family: OPPOSans-Bold, OPPOSans;
  font-weight: bold;
  color: rgba(26, 12, 4, 0.3);
  line-height: 28px;

}


.orderStatusName_toPay {
  color: #E86922;
}

.section {
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px !important;
  margin: 0px 12px 8px 12px;
  opacity: 1;
  display: flex;
}

.firstSection {
  width: 351px;
  display: flex;
  flex-direction: column;
}


.firstHead {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: space-between;


  .leftText {

  }

  .rightText {
    color: #1A0C04;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 350;
    line-height: 22px;
  }

}


.contractLink {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;


  .leftText {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #E86922;
    line-height: 22px;

    width: 32px;
    height: 32px;
    background: rgba(232, 105, 34, 0.1);
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    margin-right: 4px;
  }

  .rightText {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 22px;
    margin-left: 8px;
    height: 22px;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #1A0C04;
    line-height: 22px;
  }

}

.detailAddressContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 6px 12px 16px 16px;

  .detailAddress {
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: rgba(26, 12, 4, 0.6);
    line-height: 22px;
    width: 283px;


  }
}


.deliveryNoClass{

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  margin: 12px;
  width: 351px;
  height: 62px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  font-size: 16px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: #333333;
  line-height: 62px;



}




.van-cell {
  border-radius: 12px 12px 12px 12px !important;
}


.dot{
  margin: 12px;
  display: flex;
  width: 10px;
  height: 10px;
  // background: #E86922;

  background: #C9CDD4;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
}

.lietContaner{
  display: flex;
  flex-direction: column;

}

.son{
  display: flex;
  flex-direction: column;
}

.first{
  display: flex;
  flex-direction: row;
}

.status{
  font-size: 16px;
  font-weight: 500;
  color: #1D2129;
  line-height: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

}

.statusTime{
  margin-top: -12px;
  padding-top: 12px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
  height: 38px;
  margin-left: 17px;
  border-left: solid 1px #E5E6EB ;
  padding-left: 20px;
}
</style>