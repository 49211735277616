<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />
    <img :src="picUrl" >

  </div>
</template>

<script>


import {Toast, Tab, Tabs, Tag, NavBar} from 'vant';

export default {
  name: "Empty",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag
  },
  data() {
    return {
      pageTitle: "处方单",
      orderList: [],
      picUrl:""
    }
  },
  created() {
    this.initUrl();
  },
  methods: {

    initUrl() {
      let currentUrl = window.location.href;
      let originParams = new URLSearchParams(currentUrl);
      this.picUrl= originParams.get("picUrl")
      debugger

      console.error("picUrl:"+this.picUrl)

    } , onClickLeft() {
      //返回
      window.history.back();
    }
  }

}
</script>

<style scoped lang="scss">
.testRem1 {
  background: #F4F5F9 !important;
  height: auto;

  .picImg{
    width: 100%;
  }
}



</style>