import Vue from 'vue'
import Router from 'vue-router'
import firstLoginPage from "@/page/ruize/FIrstLoginPage.vue"
import linkFirstInputCardPage from "@/page/ruize/LinkFirstInputCardPage.vue"
import linkSecondKycPage from  "@/page/ruize/LinkSecondKycPage.vue"
import linkThirdShowProfit from "@/page/ruize/LinkThirdShowProfit.vue"
import singleArticlePage from "@/page/ruize/SingleArticlePage.vue"
import home from "@/page/ruize/Home.vue"
import detailShow from "@/page/ruize/DetailShow.vue"
import ruizeCenter from "@/page/ruize/RuiUserCenter.vue"
import cardListPage from  "@/page/ruize/CardListPage.vue"

// 固生堂
import GstConsultation from "@/page/ruize/gushengtang/GstConsultation.vue"
import GstMasterThrough from "@/page/ruize/gushengtang/GstMasterThrough.vue"
import GstDoctorList from "@/page/ruize/gushengtang/GstDoctorList.vue"

// 药诊卡
import yzDetail from "@/page/ruize/yz/Detail.vue"
import yzLinkFirstInputCardPage from "@/page/ruize/yz/YzLinkFirstInputCardPage.vue"
import yzHome from  "@/page/ruize/yz/YzHome.vue"
import yzCardListPage from  "@/page/ruize/yz/YzCardListPage.vue"

// 湖北惠民保
import hmbClaim from  "@/page/ruize/hmb/HmbClaim.vue"
import HmbCardDetail from  "@/page/ruize/hmb/HmbCardDetail.vue"
import hmbOrderList from  "@/page/ruize/hmb/HmbOrderList.vue"
import HmbOrderDetail from  "@/page/ruize/hmb/HmbOrderDetail.vue"
import hmbOrderPrescription from  "@/page/ruize/hmb/HmbOrderPrescription.vue"
import  hmbDeliveryDetail from "@/page/ruize/hmb/HmbDeliveryDetail.vue"

// new
import Empty from "@/page/ruize/Empty.vue"



// test
import emptyTest from "@/page/ruize/EmptyTest.vue"
import firstLoginTestPage from "@/page/ruize/FIrstLoginTestPage.vue"


// cutBuy
import  CutBuyLoginPage from "@/page/ruize/cutShop/CutBuyLoginPage.vue"
import  CutBuyInputCardPage from "@/page/ruize/cutShop/CutBuyInputCardPage.vue"


// js 配置文件,全局常量
import Constants from "../commom/constants";


// gst cutBuy
import  GstConsultationByActive from "@/page/ruize/gushengtang2scan/GstConsultationByActive.vue";
import  GstCutBuyInputCardPage from "@/page/ruize/gushengtang2scan/GstCutBuyInputCardPage.vue";
import  GstCutBuyLoginPage from "@/page/ruize/gushengtang2scan/GstCutBuyLoginPage.vue";


// mix cutBuy
import  MixCutBuyInputCardPage from "@/page/ruize/mix/MixCutBuyInputCardPage.vue";
import  MixCutBuyLoginPage from "@/page/ruize/mix/MixCutBuyLoginPage.vue";
import  MixDetailPage from "@/page/ruize/mix/MixDetailPage.vue";


// xihu

import XihuCardDetail from  "@/page/ruize/xihu/XihuCardDetail.vue" ;
import XihuLoginPage from  "@/page/ruize/xihu/XihuLoginPage.vue" ;
import XihuUserCenter from  "@/page/ruize/xihu/XihuUserCenter.vue" ;
import XinhuSingleArticlePage from  "@/page/ruize/xihu/XinhuSingleArticlePage.vue" ;
import XihuOrderList from  "@/page/ruize/xihu/XihuOrderList.vue" ;
import XihuOrderDetail from  "@/page/ruize/xihu/XihuOrderDetail.vue" ;
import XihuDeliveryDetail from  "@/page/ruize/xihu/XihuDeliveryDetail.vue" ;



//shandong
import ShandongLoginPage from  "@/page/ruize/shandong/ShandongLoginPage.vue" ;
import ShandongInputCard from  "@/page/ruize/shandong/ShandongInputCard.vue" ;
import ShandongCardDetail from  "@/page/ruize/shandong/ShandongCardDetail.vue" ;
import ShandongOrderList from  "@/page/ruize/shandong/ShandongOrderList.vue" ;
import ShandongOrderDetail from  "@/page/ruize/shandong/ShandongOrderDetail.vue" ;
import ShandongDeliveryDetail from  "@/page/ruize/shandong/ShandongDeliveryDetail.vue" ;
import ShandongOrderPrescription from  "@/page/ruize/shandong/ShandongOrderPrescription.vue" ;

//cheche

import ChecheCardDetailPage from  "@/page/ruize/cheche/ChecheCardDetailPage.vue" ;
import ChecheExtShowPage from  "@/page/ruize/cheche/ChecheExtShowPage.vue" ;
import ChecheInputCardPage from  "@/page/ruize/cheche/ChecheInputCardPage.vue" ;
import ChecheLoginPage from  "@/page/ruize/cheche/ChecheLoginPage.vue" ;
import ChecheUserCenter from  "@/page/ruize/cheche/ChecheUserCenter.vue" ;
import ChecheOrderList from  "@/page/ruize/cheche/ChecheOrderList.vue" ;

// jiangsu
import JiangsuCardDetail from  "@/page/ruize/jiangsu/JiangsuCardDetail.vue" ;
import JiangsuInputCardPage from  "@/page/ruize/jiangsu/JiangsuInputCardPage.vue" ;
import JiangsuLoginPage from  "@/page/ruize/jiangsu/JiangsuLoginPage.vue" ;
import JiangsuDeliveryDetail from  "@/page/ruize/jiangsu/JiangsuDeliveryDetail.vue" ;
import JiangsuOrderDetail from  "@/page/ruize/jiangsu/JiangsuOrderDetail.vue" ;
import JiangsuOrderList from  "@/page/ruize/jiangsu/JiangsuOrderList.vue" ;
import JiangsuUserCenter from  "@/page/ruize/jiangsu/JiangsuUserCenter.vue" ;


Vue.use(Router)
Vue.prototype.$Constants = Constants

export default new Router({
    routes: [


  // jiangsu
        {
            path: '/JiangsuCardDetail',
            name: 'JiangsuCardDetail',
            component: JiangsuCardDetail
        },
        {
            path: '/JiangsuInputCardPage',
            name: 'JiangsuInputCardPage',
            component: JiangsuInputCardPage
        },

        {
            path: '/JiangsuLoginPage',
            name: 'JiangsuLoginPage',
            component: JiangsuLoginPage
        },

        {
            path: '/JiangsuDeliveryDetail',
            name: 'JiangsuDeliveryDetail',
            component: JiangsuDeliveryDetail
        },
        {
            path: '/JiangsuOrderDetail',
            name: 'JiangsuOrderDetail',
            component: JiangsuOrderDetail
        },
        {
            path: '/JiangsuOrderList',
            name: 'JiangsuOrderList',
            component: JiangsuOrderList
        },
        {
            path: '/JiangsuUserCenter',
            name: 'JiangsuUserCenter',
            component: JiangsuUserCenter
        },
        //cheche
        {
            path: '/ChecheOrderList',
            name: 'ChecheOrderList',
            component: ChecheOrderList
        },
        {
            path: '/ChecheUserCenter',
            name: 'ChecheUserCenter',
            component: ChecheUserCenter
        },

        {
            path: '/ChecheCardDetailPage',
            name: 'ChecheCardDetailPage',
            component: ChecheCardDetailPage
        },

        {
            path: '/ChecheExtShowPage',
            name: 'ChecheExtShowPage',
            component: ChecheExtShowPage
        },


        {
            path: '/ChecheInputCardPage',
            name: 'ChecheInputCardPage',
            component: ChecheInputCardPage
        },


        {
            path: '/ChecheLoginPage',
            name: 'ChecheLoginPage',
            component: ChecheLoginPage
        },

        //shandong
        {
            path: '/ShandongOrderDetail',
            name: 'ShandongOrderDetail',
            component: ShandongOrderDetail
        },
        {
            path: '/ShandongDeliveryDetail',
            name: 'ShandongDeliveryDetail',
            component: ShandongDeliveryDetail
        },
        {
            path: '/ShandongOrderPrescription',
            name: 'ShandongOrderPrescription',
            component: ShandongOrderPrescription
        },


        {
            path: '/ShandongOrderList',
            name: 'ShandongOrderList',
            component: ShandongOrderList
        },

        {
            path: '/ShandongCardDetail',
            name: 'ShandongCardDetail',
            component: ShandongCardDetail
        },
        {
            path: '/ShandongInputCard',
            name: 'ShandongInputCard',
            component: ShandongInputCard
        },
        {
            path: '/ShandongLoginPage',
            name: 'ShandongLoginPage',
            component: ShandongLoginPage
        },



        //xihu
        {
            path: '/XihuDeliveryDetail',
            name: 'XihuDeliveryDetail',
            component: XihuDeliveryDetail
        },
        {
            path: '/XihuOrderDetail',
            name: 'XihuOrderDetail',
            component: XihuOrderDetail
        },
        {
            path: '/XihuOrderList',
            name: 'XihuOrderList',
            component: XihuOrderList
        },
        {
            path: '/XihuCardDetail',
            name: 'XihuCardDetail',
            component: XihuCardDetail
        },

        {
            path: '/XihuLoginPage',
            name: 'XihuLoginPage',
            component: XihuLoginPage
        },

        {
            path: '/XihuUserCenter',
            name: 'XihuUserCenter',
            component: XihuUserCenter
        }  ,
        {
            path: '/XinhuSingleArticlePage',
            name: 'XinhuSingleArticlePage',
            component: XinhuSingleArticlePage
        }



        //mix

        ,{
            path: '/MixCutBuyInputCardPage',
            name: 'MixCutBuyInputCardPage',
            component: MixCutBuyInputCardPage
        },  {
            path: '/MixCutBuyLoginPage',
            name: 'MixCutBuyLoginPage',
            component: MixCutBuyLoginPage
        },  {
            path: '/MixDetailPage',
            name: 'MixDetailPage',
            component: MixDetailPage
        },

        //gstCutbuy
        {
            path: '/GstCutBuyLoginPage',
            name: 'GstCutBuyLoginPage',
            component: GstCutBuyLoginPage
        },
        {
            path: '/GstCutBuyInputCardPage',
            name: 'GstCutBuyInputCardPage',
            component: GstCutBuyInputCardPage
        },
        {
            path: '/GstConsultationByActive',
            name: 'GstConsultationByActive',
            component: GstConsultationByActive
        },
        //cutBuy
        {
            path: '/CutBuyLoginPage',
            name: 'CutBuyLoginPage',
            component: CutBuyLoginPage
        },
        {
            path: '/CutBuyInputCardPage',
            name: 'CutBuyInputCardPage',
            component: CutBuyInputCardPage
        },
        //test
        {
            path: '/firstLoginTestPage',
            name: 'firstLoginTestPage',
            component: firstLoginTestPage
        },
        //test
        {
            path: '/emptyTest',
            name: 'emptyTest',
            component: emptyTest
        },
        {
            path: '/GstConsultation',
            name: 'GstConsultation',
            component: GstConsultation
        },
        {
            path: '/GstMasterThrough',
            name: 'GstMasterThrough',
            component: GstMasterThrough
        },
        {
            path: '/GstDoctorList',
            name: 'GstDoctorList',
            component: GstDoctorList
        },

        //湖北惠民保
        {
            path: '/hmbDeliveryDetail',
            name: 'hmbDeliveryDetail',
            component: hmbDeliveryDetail
        },
        {
            path: '/hmbOrderPrescription',
            name: 'hmbOrderPrescription',
            component: hmbOrderPrescription
        },
        {
            path: '/HmbOrderDetail',
            name: 'HmbOrderDetail',
            component: HmbOrderDetail
        },
             {
            path: '/hmbOrderList',
            name: 'hmbOrderList',
            component: hmbOrderList
        },
        {
            path: '/HmbCardDetail',
            name: 'HmbCardDetail',
            component: HmbCardDetail
        },

        {
            path: '/hmbClaim',
            name: 'hmbClaim',
            component: hmbClaim
        },
        {
            path: '/',
            name: 'firstLoginPage',
            component: firstLoginPage
        },
        {
            path: '/linkFirstInputCardPage',
            name: 'linkFirstInputCardPage',
            component: linkFirstInputCardPage
        },
        {
            path: '/linkSecondKycPage',
            name: 'linkSecondKycPage',
            component: linkSecondKycPage

        },
        {
            path:'/linkThirdShowProfit',
            name:'LinkThirdShowProfit',
            component:linkThirdShowProfit
        },
        {
            path:'/singleArticlePage',
            name:'singleArticlePage',
            component:singleArticlePage
        },


        {
            path: '/home',
            name: 'home',
            component: home
        },

        {
            path: '/detailShow',
            name: 'detailShow',
            component: detailShow
        },
        {
            path: '/ruizeCenter',
            name: 'ruizeCenter',
            component: ruizeCenter
        },
        {
            path:'/CardListPage',
            name:'cardListPage',
            component:cardListPage
        },
        {
            path:'/Empty',
            name:'Empty',
            component:Empty
        },
        {
            path: '/yzLinkFirstInputCardPage',
            name: 'yzLinkFirstInputCardPage',
            component: yzLinkFirstInputCardPage
        },
        {
            path: '/yzHome',
            name: 'yzHome',
            component: yzHome
        },
        {
            path: '/yzCardListPage',
            name: 'yzCardListPage',
            component: yzCardListPage
        },
        {
            path: '/detail',
            name: 'detail',
            component: yzDetail
        },
    ],
    // scrollBehavior (to, from, savedPosition) {
    //     return { x: 0, y: 0 }
    // }
})


