<template>


  <div class="firstLoginContainer">
    <div class="headFirst">
      <span>
       登录更精彩
      </span>
    </div>

    <van-form >
      <van-field
          v-model="phone"
          name="phone"
          placeholder="请输入手机号"
          type="tel"
          size="large"
          class="myFormFontSize"
          @change="changeToSend"
      />
      <van-field
          v-model="smsCode"
          center
          name="smsCode"
          clearable
          size="large"
          type="digit"
          placeholder="请输入短信验证码"
          class="myFormFontSize"
          style="margin-top:15px"
          @change="changeToSend"

      >
        <template #button>
          <van-button size="large"
                      native-type="button"
                      type="primary"
                      class="myFormFontSize sendCodeButton"
                      @click="sendSmsCode"
          >{{ sendCodeTitle }}

          </van-button>
        </template>
      </van-field>
      <van-checkbox v-model="checked"
                    class="myCheckBox"
                    icon-size="0.36rem"
                    label-disabled
                    @change="changeToSend">
        <span class="checkBoxDesc">我已阅读并同意</span>
        <span class="checkBoxLink" v-on:click="toArticlePrivate">《隐私政策》 </span>
        <span class="checkBoxLink" v-on:click="toArticleRegister">《平台注册及服务协议》 </span>
      </van-checkbox>


<!--      <div style="margin-top: 46px;">-->
<!--        <img v-bind:src="showButtonBg" id="submitButton" @click="toSubmit()"-->
<!--        >-->
<!--      </div>-->
      <div>
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="toSubmitButton"
                    @click="toSubmitForm"
        >登录
        </van-button>
      </div>


    </van-form>


  </div>
</template>

<script>


import axios from "axios";
import {CountDown, Field, Form, Button, Checkbox, CheckboxGroup, Toast, NavBar} from 'vant';
import Cookies from 'js-cookie'
import {mapState, mapMutations} from 'vuex'

import disableButton from "@/assets/png/button_send_code_disable.png"
import okButton from "@/assets/png/button_send_code_ok.png"


export default {
  name: "FirstLoginPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [CountDown.name]: CountDown,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar
  },
  created() {
    let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
    debugger
    if (cookieOpenId == undefined) {

      debugger
      //如果不存在，则需要判断是否有code
      let url = window.location.href;
      let code = getUrlParams(url, "code")
      if (code == null) {
        console.log("--------")
        let params = {
          originUrl: url,
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
        ).then(res => {
          console.log(res);
          console.log("res.data:" + res.data);
          if (res.data.code === 200) {
            let redirectUrl = res.data.data;
            console.log("redirectUrl:" + redirectUrl);
            //重定向
            window.location.href = redirectUrl
          } else {
            //验证码错误
            console.error("res.data.message:" + res.data.message);
          }
        })
        //code 都没有，则需要同步访问了。
      } else {
        console.log("=++++++++++++")
        // 再去调用服务器，获取用户openid
        let params = {
          code: code,
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
        ).then(res => {
          console.log(res);
          if (res.data.code === 200) {
            let openId = res.data.data;
            console.log("openId:" + openId);
            //设置cookie的id
            Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
            this.openId = openId;
            //检查手机号状态
            let params = {
              openId: openId,
            }
            axios.post(
                this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus, params
            ).then(res => {
              console.log(res);
              if (res.data.code === 200) {
               this.checkActiveCard();
              }
            });
          } else {
            //验证码错误
            console.error("res.data.message:" + res.data.message);
          }
        });
      }
    } else {

      this.openId = cookieOpenId;
      let params = {
        openId: cookieOpenId,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到下一页
          this.checkActiveCard();

        }
      });
    }
    //todo 檢查登錄
    //數據初始化
    // Toast("不知道的异常")

    this.callBackUrl = this.$route.params.callBackUrl;
    if (this.callBackUrl != undefined || this.callBackUrl != "") {
      //todo
      this.$Constants.setTitle("");
    } else {
      this.$Constants.setTitle("");
    }

  },
  data() {
    return {
      phone: '',
      smsCode: '',
      sendCodeTitle: '发送验证码',
      time2: 2,
      checked: false,
      showButtonBg: disableButton,
      disableButtonBg: disableButton,
      okButtonBg: okButton,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60,
      //是否允许到下一步
      canNext: false,
      openId: "",
      callBackUrl:""
    };
  },
  computed: {
    ...mapState(["currentActiveCard"])
  },
  methods: {
    checkActiveCard() {
      let params = {
        openId: this.openId
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCurrentActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.setCurrentActiveCard(res.data.data);
          this.$router.push("/home");
        } else {
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },
    toArticlePrivate() {
      console.log("click")

      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:"2"
        }
      })
    },
    toArticleRegister() {
      // 这是去
      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:"3"
        }
      })
    },
    sendSmsCode() {

      if (this.sendCodeTitle == "发送验证码") {


        // 判断手机号是否已经输入
        if (!this.phone) {
          Toast('请输入手机号')
          return false
        }
        // 判断手机号是否符合要求
        if (this.phone.length !== 11) {
          Toast('请输入11位手机号')
          return false
        }

        // 调用接口，发送短信验证码
        // 这部分放调用发送短信的接口，这里先不做任何功能，主要先把按钮倒计时的功能实现
        // 将按钮禁用，防止再次点击

        if (!this.isSmsSend) {

          this.isSmsSend = true


          let params = {
            phone: this.phone,
            openId: this.openId
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.sendCode, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              // 发送成功
              Toast("短信验证码发送成功，60s内有效");
              this.toCountDown();// 这里实现倒计时的功能，文章下面开始介绍
            } else {
              Toast(res.data.msg);
            }
          }).catch(res=>{
            Toast("发送失败")
            console.error(res)
          })
          // 开始倒计时，60s之后才能再次点击
        }else {
          Toast("请稍后再试")
        }

      }
    },
    toCountDown() {
      let that = this;
      // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
      this.timer = setInterval(() => {
        // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
        that.sendCodeTitle = `(${that.counter}秒)后重新发送`
        console.log(that.sendCodeTitle)
        that.counter--
        if (that.counter <= 1) {
          // 当计时小于零时，取消该计时器
          that.isSmsSend=false;
          that.sendCodeTitle="发送验证码"
          clearInterval(that.timer)

          //设置为按正常状态
        }
      }, 1000)
    },
    changeToSend() {
      if (this.checked == true && this.phone.length == 11 && this.smsCode.length == 4) {
        this.showButtonBg = okButton
        this.canNext = true;
      } else {
        this.showButtonBg = disableButton
      }
    },
    toSubmitForm() {

      //这里是保存用户信息，和手机号的


      // 判断手机号是否已经输入
      if (!this.phone) {
        Toast('请输入手机号')
        return false
      }
      // 判断手机号是否符合要求
      if (this.phone.length !== 11) {
        Toast('请输入11位手机号')
        return false
      }

      if(!this.checked){
        Toast('请阅读并确认协议')
        return false
      }

      if(this.smsCode==''){
        Toast('请输入验证码')
        return false
      }

      let params={
        phone: this.phone,
        code:this.smsCode,
        openId:this.openId
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.savePhone, params
      ).then(res => {
            console.log(res);
            if (res.data.code === 200) {

              this.$router.push("/linkFirstInputCardPage");
              // 发送成功
              // if(this.callBackUrl!=""){
              //   window.href=this.callBackUrl;
              // }else {
              //   this.$router.push("/linkFirstInputCardPage");
              // }
            } else {
              Toast(res.data.message);
              return false;
            }
          }
      );

    },
    ...mapMutations(['setCurrentActiveCard'])

  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}
</script>

<style scoped lang="scss">

.firstLoginContainer {


  .headFirst {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #333C4F;
    font-size: 0.52rem;
    font-weight: bold;
    margin-top: 0.93rem;
    margin-bottom: 0.93rem;
  }

  .van-form {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  .myCheckBox {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;

    span {
      font-size: 12px;
      line-height: 20px;
    }
  ;
  }
}

.myFormFontSize {
  font-size: 18px;
}

.sendCodeButton {

  border: 0;
  background: none;
  color: #16CC90;
}



.toSubmitButton{


  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  margin-top: 25px;
  font-size: 16px;
  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}




.checkBoxDesc {
  color: #B6B9C4;
}

.testRem1 {
  background: #00ff0d;
  width: 750px;
  height: 12px;
}
</style>