<template>
    <div class="container">
        <div class="title_style">
            <img class="title_icon" src="~@/assets/img/gushengtang/texticon@2x.png">
            国医大师名中医坐诊，专属特约通道
        </div>
        <div class="avatorsRow">
            <img class="img" src="~@/assets/img/gushengtang/Component 2@2x.png">
            <img class="img" src="~@/assets/img/gushengtang/Component 3@2x.png">
            <img class="img" src="~@/assets/img/gushengtang/Component 4@2x.png">
        </div>
        <div class="avatorsRow">
            <img class="img" src="~@/assets/img/gushengtang/Component 5@2x.png">
            <img class="img" src="~@/assets/img/gushengtang/Component 6@2x.png">
            <img class="img" src="~@/assets/img/gushengtang/Component 7@2x.png">
        </div>

        <div class="title_style">
            <img class="title_icon" src="~@/assets/img/gushengtang/texticon@2x.png">
            坐诊国医大师名中医介绍:
            <img style="position: absolute; right: 5px; height: 24px; width: 85px;"
                src="~@/assets/img/gushengtang/arrow@2x.png" @click="gotoDoctorList()">
        </div>

        <div class="doctor_list">
            <div class="doctor_container" v-for="(doctorData, index) in doctorDatas" :key="index">
                <div class="doctor">
                    <img :src="doctorData.avator">
                    <div class="doctor_name">{{ doctorData.name }}</div>
                    <div class="doctor_title">
                        <img src="~@/assets/img/gushengtang/maisui_left@2x.png">
                        {{ doctorData.honor }}
                        <img src="~@/assets/img/gushengtang/maisui_right@2x.png">
                    </div>
                    <div class="doctor_level">
                        {{ doctorData.title }} {{ doctorData.department }}
                    </div>
                    <div class="doctor_hospital">{{ doctorData.area }}</div>
                </div>
            </div>
        </div>

        <img src="~@/assets/img/gushengtang/QRCode.png">

        <div class="title_style">
            <img class="title_icon" src="~@/assets/img/gushengtang/texticon@2x.png">
            预约说明
        </div>
        <div class="illustrate">
            1.添加线下预约专属客服进行预约；<br>
            2.预约需要提供权益卡号、就诊人姓名、手机号以及身份证号；<br>
            3.每个权益卡仅限一次线下国医大师名中医预约绿通权益，预约后不可调整；<br>
            4.有任何疑问，可通过专属客服进行联系。<br><br>
        </div>
    </div>
</template>

<style scoped lang="scss">
.container {
    background-image: url(~@/assets/img/gushengtang/bg2.png);
    background-size: cover;
    flex-wrap: wrap;
    display: flex;
    padding: 12px;

    img {
        width: 100%;
        height: auto;
    }

    .illustrate {
        font-size: 14px;
        color: #1A0C04;
        opacity: 0.6;
        line-height: 20px;
    }

    .title_style {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;

        .title_icon {
            height: 16px;
            width: 3px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            height: 16px;
            padding-right: 4px;
        }
    }

    .avatorsRow {
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        img {
            width: 33.3%;
            height: auto;
        }
    }

    .doctor_list {
        overflow-x: scroll;
        display: flex;
        margin-bottom: 10px;

        .doctor_container {
            margin: 3px;
            flex: 0 0 150px;
            border-radius: 12px;
            background-color: white;

            /* 设置容器的宽度为160px */
            .doctor {
                border-radius: 12px;
                padding: 10px;
                border-width: 2px;
                border-style: solid;
                border-color: #CCCCCC;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: auto; // 如果不加，div的宽度为100%，设置圆角会失效
                    margin: auto;
                    height: 48px;
                    object-fit: contain;
                    background-color: white;
                    border-radius: 10%;
                }

                .doctor_name {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }

                .doctor_title {
                    width: fit-content;
                    display: inline-flex;
                    background-color: #F8F3ED;
                    font-size: 12px;
                    color: #B66922;
                }

                .doctor_title img {
                    width: 8px;
                    height: 8px;
                }

                .doctor_level {
                    margin-top: 5px;
                    font-size: 14px;
                    color: #1A0C04;
                    opacity: 0.7;
                }

                .doctor_hospital {
                    margin-top: 5px;
                    font-size: 14px;
                    color: #1A0C04;
                    opacity: 0.3;
                }
            }


        }
    }
}
</style>

<script>
export default {
    name: "GstMasterThrough",

    components: {

    },
    data() {
        return {
            consultationTimes: 3,
            doctorDatas: [
                {
                    avator: require("../../../assets/img/gushengtang/" + "xurunsan.png"),
                    name: '许润三',
                    area: '北京.鼓楼',
                    hospital: '中日友好医院',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：诊疗不孕症、子宫内膜异位症等妇科常见病和疑难杂病。临床研究方向为中医药治疗输卵管阻塞性不孕症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "wangqingguo.png"),
                    name: '王庆国',
                    area: '北京',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：对消化、内分泌、心脑血管系统疾病，自身免疫性疾病具有显著疗效，擅长治疗胃炎、胃溃疡、急慢性结肠炎、糖尿病、红斑狼疮、类风湿性关节炎、高血压、冠心病及其他内科、儿科、妇科常见病的诊断与治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "linyi.png"),
                    name: '林毅',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '乳腺科',
                    desc: '擅长：乳腺良恶性肿瘤尤其是三阴、双阴性乳腺癌，乳腺炎性疾病尤其是肉芽肿性乳腺炎，及乳腺增生性疾病、疑难病症的中医与中西医结合治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "zhoudaihan.png"),
                    name: '周岱翰',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：治疗晚期、难治癌症等疑难重疾，提出“带瘤生存”，更新治癌理念，奠定岭南中医肿瘤学术流派理论体系。创制“鹤蟾片”(获“1986年卫生部中医药重大科技成果乙等奖”)、“清金得生片”等制剂，代表性著作有《肿瘤治验集要》《中医肿瘤食疗学》等10部，主编《中医肿瘤学》并纳入全国高等中医院校专业课程，2010年获“教育部科学技术成果一等奖”、“广东省科学技术奖励二等奖”，2017获评“南粤楷模”称号，2019年获“全国中医药杰出贡献奖”。献身中医肿瘤医教研工作半个多世纪，是中医肿瘤学的主要奠基者，中医肿瘤学科教育的先行者，现代岭南中医肿瘤学术流派的开创者，为中医药传承精华、守正创新树立光辉典范。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "xuanguowei.jpeg"),
                    name: '禤国维',
                    area: '广州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '皮肤科',
                    desc: '擅长：脱发病、痤疮、荨麻疹、红斑狼疮等结缔组织皮肤病和其他疑难皮肤病。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "gelinyi.jpeg"),
                    name: '葛琳仪',
                    area: '杭州',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：临床上以擅治肺系病、脾胃病、老年病、疑难杂症而著称。擅长采用中西医结合方法治疗呼吸系统疾病【慢性支气管炎、支气管哮喘、肺气肿、肺源性心脏病等】和消化系统疾病【慢性胃炎、消化性溃疡、消化道出血等疾病】及疑难杂症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "yanshiyun.jpeg"),
                    name: '严世芸',
                    area: '上海',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '中医内科',
                    desc: '擅长：心脑血管疾病，肺及支气管疾病，消化道疾病，失寐，颈椎病等内科疾病及疑难杂症和保健调养。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "shiqi.png"),
                    name: '施杞',
                    area: '上海',
                    hospital: '固生堂',
                    honor: '国医大师',
                    title: '主任医师',
                    department: '骨伤科',
                    desc: '擅长：颈椎病，腰椎病，骨关节炎，类风湿性关节炎，强直性脊柱炎，股骨头无菌性坏死，骨延迟愈合，骨不连，慢性硬膜外血肿及各种骨伤科疑难杂症的诊断与治疗。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "caijin.png"),
                    name: '蔡淦',
                    area: '上海',
                    hospital: '上海中医药大学附属曙光医院',
                    honor: '全国名中医',
                    title: '主任医师',
                    department: '脾胃科',
                    desc: '擅长：各种胃肠疾病（如慢性胃炎、消化性溃疡、胃癌前病变、慢性腹泻、胃肠道肿瘤手术后的调理），II型糖尿病以及各种内科疑难杂症的治疗和膏方的调理。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "fanzhongze.png"),
                    name: '范忠泽',
                    area: '上海',
                    hospital: '上海市普陀医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '肿瘤科',
                    desc: '擅长：肺癌、胃癌、大肠癌、乳腺癌、盆恶性肿瘤、脑瘤、肝癌、胰腺癌、食道癌、淋巴癌、小儿神经母细胞瘤等恶性肿瘤和癌前期疑难杂症。'
                },
                {
                    avator: require("../../../assets/img/gushengtang/" + "lixiangyun.png"),
                    name: '李祥云',
                    area: '上海',
                    hospital: '上海中医药大学附属龙华医院',
                    honor: '上海市名中医',
                    title: '主任医师',
                    department: '妇科',
                    desc: '擅长：女性不孕、男性不育、男女性功能障碍、子宫内膜异位症、输卵管梗阻、黄体功能不健、更年期综合症，月经不调，疑难杂症，各种手术后及化疗后的调理等。'
                }
            ],
        }
    },
    created() {
    },
    methods: {
        gotoDoctorList() {
            this.$router.push({ name: 'GstDoctorList' })
        },

    }
}


</script>