<template>

  <div class="allContainer">
    <div class="sectionHead">

      <div class="sectionHeadRight">

          <img class="headImg"
               v-bind:src="headImg"/>
        <div class="nameList">
              <span class="userName">
             {{ singleInfo.phone }}
              </span>
              <span class="phone">
                守护您和家人的健康
              </span>
        </div>
<!--        <span round-->
<!--              block-->
<!--              type="info"-->
<!--              native-type="submit"-->
<!--              class="quanyiButton"-->
<!--        >权益人-->
<!--          </span>-->

      </div>

    </div>

    <div class="detail">

      <img v-bind:src="药诊卡购药订单" class="headFirst" @click="toOrderList"   >
<!--      <img v-bind:src="联系客服" class="headFirst" @click="showPhone">-->
    </div>

    <a href="tel:123456789" id="toPhone" style="display: none"></a>

    <van-button class="addButton" @click="toLoginOut">
      退出登录
    </van-button>


    <ruize-menu>


    </ruize-menu>


  </div>


</template>

<script>


import {Button, Dialog, Form, Toast} from "vant";


import 药诊卡购药订单 from "@/assets/img/jiangsu/usercenter_orderIcon.png";

import 联系客服 from "@/assets/img/jiangsu/usercenter_phone.png";
import headImg from "@/assets/img/jiangsu/touxiang_moren.png";





import ruizeMenu from "@/components/ruize/jiangsu/RuizeJiangSuMenu.vue"
import axios from "axios";
import Cookies from "js-cookie";
import {mapMutations, mapState} from "vuex";


export default {
  name: "RuiUserCenter",
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast,
    ruizeMenu
  },
  created() {
     this.checkUserAndInit();
    this.checkPhoneStatus();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("我的");

    this.initSingleInfo();
  },
  computed: {
    ...mapState(["currentActiveCard"])
  },
  data() {
    return {
      药诊卡购药订单:药诊卡购药订单,
      联系客服:联系客服,
      headImg:headImg,
      buyOrderListUrl: "",
      openId: "",
      activeCardInfo:{
        "phone":"1212121212"
      },
      claimJumpUrl:"",
      yzBuyOrderListUrl: "",
      hasYz:false,
      hasQy:false,
      singleInfo:""
    }
  },
  methods: {

   async initSingleInfo(){
     if (this.qdCode == "" || this.qdCode==undefined) {
       this.qdCode = getCurrentUrlQdCode();
       console.info("init again qdCOde="+this.qdCode)
     }
     let params = {
       openId: this.openId,
       qdCode: this.qdCode,
     }
     let res = await axios.post(
         this.$Constants.devHost + this.$Constants.RzApiMethod.getSinglePhone, params
     );
     {
       console.log(res);

       if (res.data.code === 200) {

          this.singleInfo=res.data.data;
       }  else {
         let targetUrl = "/#/JiangsuLoginPage?qdCode=" + this.qdCode;
         window.location.href = targetUrl;
         // window.location.back();
         console.log("not link phone")
         return false;
       }
     }
    },
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }else {
        console
            .error("ssss:"+this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode)
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
       console.error("cookieOpenId="+cookieOpenId);

      if (cookieOpenId == undefined) {
        //如果不存在，则需要登陆。
        window.location.href="/#/JiangsuLoginPage?qdCode="+getCurrentUrlQdCode();
        //code 都没有，则需要同步访问了。
      } else {
        this.openId = cookieOpenId;
        await this.checkPhoneStatus();
      }
    },
    toOrderList() {
      console.log("toOrderList:" + this.buyOrderListUrl)
      this.qdCode=getCurrentUrlQdCode();
      //todo 药拼接qdCode
      let url="/#/JiangsuOrderList?qdCode="+this.qdCode;

      window.location.href = url;
    },
    async checkPhoneStatus() {

      if (this.qdCode == "" || this.qdCode==undefined) {
        this.qdCode = getCurrentUrlQdCode();
        console.info("init again qdCOde="+this.qdCode)
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      let res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuCheckPhone, params
      );
      {
        console.log(res);

        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }

        }  else {
          let targetUrl = "/#/JiangsuLoginPage?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          // window.location.back();
          console.log("not link phone")
          return false;
        }
      }
    },
    showPhone() {
      Dialog.confirm({
        message: '客服电话：12345678测试，确认拨打？',
      })
          .then(() => {
            setTimeout(() => {
              document.getElementById("toPhone").click();
            }, 300);
          })
          .catch(() => {
            // on cancel

          });
    },
    toLoginOut(){



      Dialog.confirm({
        message: '退出登录后无法使用相关服务，是否继续退出',
      })
          .then(() => {

            document.cookie.split(';')
                .forEach(cookie => document.cookie =
                    cookie.replace(/^ +/, '')
                        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));



            window.location.href="/#/JiangsuLoginPage?qdCode="+getCurrentUrlQdCode();
          })
          .catch(() => {

          });

    }
    ,
    ...mapMutations(['setCurrentCardNumber', 'setCurrentActiveCard']),
  }

}



function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }

  return qdCode;
}
</script>


<style scoped lang="scss">


.allContainer {
margin-top: -12px;
  background-color: #F4F5F9;
  height: 100%;
}

.all {
  margin: 5.5px 5.5px 0 5.5px;
  background-color: #F4F5F9;
  display: flex;
  flex-direction: column;
  font-size: 7px;
  border-radius: 0.2rem;

}

.headImg {
  width: 44px;
  height: 44px;
  background: #E86922;
  border-radius: 45px 45px 45px 45px;
  opacity: 1;
  margin: 12px 23px;
}

.company-head-container {
  margin: 5.5px 5.5px 0 5.5px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  span {
    margin-left: 5.5px;

  }
}


.sectionHead {
  display: flex;
  height: 131px;
  background-image: linear-gradient(to right, #FFCEB2 0%, #FFEDD3 100%);
  color: #E86922;
  padding: 0px;
}

.sectionHeadRight {
  width: 351px;
  height: 90px;
  background: rgba(255,255,255,0.5);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 24px;
  margin-right: 12px;
  margin-left: 12px;
}


.service-list-container {
  display: flex;
  flex-direction: row;
  margin: 5.5px 5.5px 0 5.5px;
  flex-wrap: wrap;

  span {
    line-height: 28px;

  }

  .singleServiceTag {

    height: 0.58rem;
    border-radius: 0.12rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.26rem;
    width: 1.76rem;
    border: 1px solid black;
    margin: 2px 2px;

  }


}


.anno-container {
  word-break: break-all;
  margin: 5.5px;
}

.nameFirstContainer {

  margin-bottom: 5px;

}

.userName {
  font-size: 20px;
}
.phone {
  font-size: 14px;
  margin-top: 5.5px;
  opacity: 0.6;
}
.cardDate {
  font-size: 11px;
  margin-top: 10.5px;

}

.nameSecondContainer {
  margin-top: 5px;


}

.quanyiButton {
  background: white;
  color: green;
  width: 50px;
  height: 25px;
  font-size: 13px;
  line-height: 13px;
  border-radius: 5px;
  margin-right: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}




.detail {

  img {
    height: 70px;
  }

}

.addButton {
  margin-top: 56px;
  bottom: 5px;
  width: 351px;
  height: 43px;
  border-radius: 22.5px;
  font-size: 16px;
  background: #E86922;
  margin-left: 12px;
  margin-right: 12px;
  color: white;

}


.nameList{
  display: flex;
  flex-direction: column;
}


</style>