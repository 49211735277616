<template>

  <div class="allContainer">
    <div class="sectionHead">
      <img class="headImg"
           src="//www.ruizehealth.com/img/commonHead.png"/>
      <div class="sectionHeadRight">
        <div class="nameList">
              <span class="userName">
             {{ activeCardInfo.phone }}
              </span>
            <!--            <span>-->
            <!--                icon-->
            <!--              </span>-->
              <span class="phone">
                为您的健康生活保驾护航
              </span>
            <!--            <span>-->
            <!--                icon-->
            <!--              </span>-->

            <!--            <span>-->
            <!--                icon-->
            <!--              </span>-->


        </div>
<!--        <span round-->
<!--              block-->
<!--              type="info"-->
<!--              native-type="submit"-->
<!--              class="quanyiButton"-->
<!--        >权益人-->
<!--          </span>-->

      </div>

    </div>

    <div class="detail">

      <img v-bind:src="购药订单" class="headFirst" @click="toOrderList"   >
      <img v-bind:src="联系客服" class="headFirst" @click="showPhone">
    </div>

    <a href="tel:123456789" id="toPhone" style="display: none"></a>

    <van-button class="addButton" @click="toLoginOut">
      退出登录
    </van-button>


    <ruize-menu>


    </ruize-menu>


  </div>


</template>

<script>


import {Button, Dialog, Form, Toast} from "vant";


import 药诊卡购药订单 from "@/assets/png/ucenterMenu/药诊卡购药订单.png";
import 药诊卡卡包 from "@/assets/png/ucenterMenu/药诊卡卡包.png";
import 购药订单 from "@/assets/png/ucenterMenu/购药订单.png";
import 权益卡包 from "@/assets/png/ucenterMenu/权益卡包.png";
import 理赔记录 from "@/assets/png/ucenterMenu/理赔记录.png";
import 联系客服 from "@/assets/png/ucenterMenu/联系客服.png";



import ruizeMenu from "@/components/ruize/cheche/RuizeChecheMenu.vue"
import axios from "axios";
import Cookies from "js-cookie";
import {mapMutations, mapState} from "vuex";


export default {
  name: "RuiUserCenter",
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Toast.name]: Toast,
    ruizeMenu
  },
  created() {
    //判断cookieopenid
    //判断是否有最新的卡
    this.checkUserAndInit();
  },
  computed: {
    ...mapState(["currentActiveCard"])
  },
  data() {
    return {
      药诊卡购药订单:药诊卡购药订单,
      药诊卡卡包:药诊卡卡包,
      购药订单:购药订单,
      权益卡包:权益卡包,
      理赔记录:理赔记录,
      联系客服:联系客服,
      buyOrderListUrl: "",
      openId: "",
      activeCardInfo:{},
      claimJumpUrl:"",
      yzBuyOrderListUrl: "",
      hasYz:false,
      hasQy:false
    }
  },
  methods: {
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
              this.openId = openId;
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
      }
    },
    showPhone(){
      Dialog.confirm({
        message: '客服电话：4006998620，确认拨打？',
      })
          .then(() => {
            setTimeout(() => {
              let url="tel:4006998620";
              window.location.href=url;
            }, 300);
          })
          .catch(() => {
            // on cancel

          });
    },
    toCardList() {
      this.$router.push("cardListPage")
    },
    toYzCardList(){
      this.$router.push("yzCardListPage")
    },
    toYzOrderList(){
      // 这里todo 需要改一下逻辑，获取一下激活的卡，否则不跳转，改为弹窗。
      if(this.yzBuyOrderListUrl==""){
        Dialog.alert({
          title: '药诊卡订单记录',
          message: '请绑定一张药诊卡后再查看。',
        }).then(() => {
          // todo 跳转到首页
          //this.$router.push("home")
        });
      }else {
        console.log("yzBuyOrderListUrl:" + this.yzBuyOrderListUrl)
        window.location.href = this.yzBuyOrderListUrl;
      }
    },
    toOrderList() {
      console.log("toOrderList:" + this.buyOrderListUrl)
      this.$router.push({
        name:"ChecheOrderList",
        params:{
          qdCode:"cheche001"
        }
      })
      //window.location.href = this.buyOrderListUrl;
    },

    toLoginOut(){
      Toast("暂不支持退出登录")
    }
    ,
    ...mapMutations(['setCurrentCardNumber', 'setCurrentActiveCard']),
  }

}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串
  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }
  return null;
}
</script>


<style scoped lang="scss">


.allContainer {

}

.all {
  margin: 5.5px 5.5px 0 5.5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: 7px;
  border-radius: 0.2rem;

}


.company-head-container {
  margin: 5.5px 5.5px 0 5.5px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  span {
    margin-left: 5.5px;

  }
}


.sectionHead {
  display: flex;
  flex-direction: row;
  height: 131px;
  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);
  color: white;
}

.sectionHeadRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headImg {
  width: 85px;
  height: 85px;
  margin: 20px 10px 20px 15px;
  border-radius: 22.5px;
}

.service-list-container {
  display: flex;
  flex-direction: row;
  margin: 5.5px 5.5px 0 5.5px;
  flex-wrap: wrap;

  span {
    line-height: 28px;

  }

  .singleServiceTag {

    height: 0.58rem;
    border-radius: 0.12rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.26rem;
    width: 1.76rem;
    border: 1px solid black;
    margin: 2px 2px;

  }


}


.anno-container {
  word-break: break-all;
  margin: 5.5px;
}

.nameFirstContainer {

  margin-bottom: 5px;

}

.userName {
  font-size: 20px;
}
.phone {
  font-size: 14px;
  margin-top: 5.5px;
}
.cardDate {
  font-size: 11px;
  margin-top: 10.5px;

}

.nameSecondContainer {
  margin-top: 5px;


}

.quanyiButton {
  background: white;
  color: green;
  width: 50px;
  height: 25px;
  font-size: 13px;
  line-height: 13px;
  border-radius: 5px;
  margin-right: 18px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}




.detail {
  margin: 5px;

  img {
    height: 45px;
  }

}

.addButton {
  margin-top: 10px;
  bottom: 5px;
  width: 325px;
  height: 43px;
  border-radius: 22.5px;
  font-size: 16px;
  background: #F8F8F8;
  margin-left: 25px;
  margin-right: 25px;
  color: #7F8389;

}


.nameList{
  display: flex;
  flex-direction: column;
}


</style>