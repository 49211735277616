<template>
  <div class="firstLoginContainer">
    <div class="headFirstBg">
    </div>

    <van-form >
      <van-field


          v-model="phone"
          name="phone"
          placeholder="请输入手机号"
          type="tel"

          class="myFormFontSize"
          @change="changeToSendPhone"
      />
      <van-field

          v-model="smsCode"
          center
          name="smsCode"
          clearable
          type="digit"
          placeholder="请输入短信验证码"
          class="myFormFontSize"
          style="margin-top:15px"
          @change="changeToSend"

      >
        <template #button>
          <van-button size="large"
                      native-type="button"
                      type="primary"
                      class="myFormFontSize sendCodeButton"
                      @click="sendSmsCode"
          >{{ sendCodeTitle }}

          </van-button>
        </template>
      </van-field>
      <van-checkbox v-model="checked"
                    class="myCheckBox"
                    icon-size="0.36rem"
                    label-disabled
                    checked-color="#E86922"
                    @change="changeToSend">

                    <div class="checkBoxTextContainer">
                      <span class="checkBoxDesc">我已阅读并同意</span>
                      <span :class="descLinkClass" v-on:click="toArticleRegister" style="{'color:':signColor}">《平台注册及服务协议》 </span>
                    </div>

      </van-checkbox>


      <!--      <div style="margin-top: 46px;">-->
      <!--        <img v-bind:src="showButtonBg" id="submitButton" @click="toSubmit()"-->
      <!--        >-->
      <!--      </div>-->
      <div class="buttonContainer">
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="toSubmitButton"
                    @click="toSubmitForm"
        >登录
        </van-button>
      </div>


    </van-form>


  </div>
</template>

<script>


import axios from "axios";
import {CountDown, Field, Form, Button, Checkbox, CheckboxGroup, Toast, NavBar} from 'vant';
import Cookies from 'js-cookie'

import disableButton from "@/assets/png/button_send_code_disable.png"
import okButton from "@/assets/png/button_send_code_ok.png"


export default {
  name: "FirstLoginPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [CountDown.name]: CountDown,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar
  },
  async created() {
    await  this.checkUserAndInit();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("");

    //页面UI初始化的内容
    {
      let ss=Cookies.get("toReadSign");
      console.log("toReadSign:"+ss)
      if(ss=="green"){
        this.descLinkClass="checkBoxLinkAfter";
      }


      //手机号初始化
      this.phone=Cookies.get("toSendPhone")


    }
  },
  data() {
    return {
      phone: '',
      sendCodeTitle: '获取短信验证码',
      smsCode: '',
      time2: 2,
      checked: false,
      showButtonBg: disableButton,
      disableButtonBg: disableButton,
      okButtonBg: okButton,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60,
      //是否允许到下一步
      canNext: false,
      openId: "",
      callBackUrl:"",
      qdCode:"",
      signColor:"#E86922",
      descLinkClass:"checkBoxLink"
    };
  },
  computed: {
  },
  methods: {
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      console.log("console:cookieKey:"+this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode)

      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      console.log("cookieOpenId:    "+cookieOpenId)
      if (cookieOpenId == undefined) {
          //如果不存在，则需要登陆。

          //code 都没有，则需要同步访问了。
      } else {
        this.openId = cookieOpenId;
        await this.checkPhoneStatus();
      }
    },
    async checkPhoneStatus() {

      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }


      try {

        let res = await axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuCheckPhone, params
        );
        {
          console.log(res);
          if (res.data.code === 200) {
            // 跳转到输入卡密页面。
            if (this.qdCode == "") {
              this.qdCode = getCurrentUrlQdCode();

              if (this.qdCode == "") {
                debugger
                //todo
                Toast("非法访问")
              }
              debugger
            }
            let targetUrl = "/#/JiangsuInputCardPage?qdCode=" + this.qdCode;
            window.location.href = targetUrl;
          } else {

            // window.location.back();
            console.log("not link phone")

            return false;
          }
        }


      } catch (err) {
        console.log(err)
        alert('请求出错！')
      }


    },

    sendSmsCode() {


      if (this.sendCodeTitle == "获取短信验证码") {


        // 判断手机号是否已经输入
        if (!this.phone) {
          Toast('请输入手机号')
          return false
        }
        // 判断手机号是否符合要求
        if (this.phone.length !== 11) {
          Toast('请输入11位手机号')
          return false
        }

        if(!this.checked){
          Toast('请阅读并确认协议')
          return false
        }



        // 调用接口，发送短信验证码
        // 这部分放调用发送短信的接口，这里先不做任何功能，主要先把按钮倒计时的功能实现
        // 将按钮禁用，防止再次点击

        if (!this.isSmsSend) {

          this.isSmsSend = true


          let params = {
            phone: this.phone,
            openId: "null"
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.sendCode, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              // 发送成功
              Toast("短信验证码发送成功，60s内有效");
              this.toCountDown();// 这里实现倒计时的功能，文章下面开始介绍
            } else {
              Toast(res.data.msg);
            }
          }).catch(res=>{
            Toast("发送失败")
            console.error(res)
          })
          // 开始倒计时，60s之后才能再次点击
        }else {
          Toast("请稍后再试")
        }

      }
    },

    toArticlePrivate() {
      console.log("click")

      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:"2"
        }
      })
    },
    toArticleRegister() {

      Cookies.set("toReadSign","green")
      console.log("toReadSign")

      // 这是去
      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:"3"
        }
      })
    },
    toCountDown() {
      let that = this;
      // 将setInterval()方法赋值给前面定义的timer计时器对象，等用clearInterval()方法时方便清空这个计时器对象
      this.timer = setInterval(() => {
        // 替换文本，用es6里面的``这个来创建字符串模板，让秒实时改变
        that.sendCodeTitle = `(${that.counter}秒)后重新发送`
        console.log(that.sendCodeTitle)
        that.counter--
        if (that.counter <= 1) {
          // 当计时小于零时，取消该计时器
          that.isSmsSend=false;
          that.sendCodeTitle="发送验证码"
          clearInterval(that.timer)

          //设置为按正常状态
        }
      }, 1000)
    },
    changeToSend() {
      if (this.checked == true && this.phone.length == 11 && this.smsCode.length == 4) {
        this.showButtonBg = okButton
        this.canNext = true;
      } else {
        this.showButtonBg = disableButton
      }
    },

    changeToSendPhone() {
      if (this.checked == true && this.phone.length == 11 && this.smsCode.length == 4) {
        this.showButtonBg = okButton
        this.canNext = true;
      } else {
        this.showButtonBg = disableButton
      }

      Cookies.set("toSendPhone",this.phone);

    },


    toSubmitForm() {



      //这里是保存用户信息，和手机号的
      // 判断手机号是否已经输入
      if (!this.phone) {
        Toast('请输入手机号')
        return false
      }
      // 判断手机号是否符合要求
      if (this.phone.length !== 11) {
        Toast('请输入11位手机号')
        return false
      }

      if(!this.checked){
        Toast('请阅读并确认协议')
        return false
      }

      if(this.smsCode==''){
        Toast('请输入验证码')
        return false
      }


      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
        if(this.qdCode==""||undefined==this.qdCode){
          Toast('非法访问')
          return false
        }

      }
      let params={
        phone: this.phone,
        code:this.smsCode,
        openId:"null",
        qdCode:this.qdCode
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.jiangsuSavePhone, params
      ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
             let openId= res.data.data;
             this.openId=openId;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);

              let targetUrl="/#/JiangsuInputCardPage?qdCode="+this.qdCode;
              window.location.href =targetUrl;

            } else {
              Toast(res.data.message);
              return false;
            }
          }
      );

    },

  }
}




function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">

.firstLoginContainer {


  .headFirst {

    border-radius: 20px;
    margin-top: -10px;
    display: flex;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #333C4F;
    font-size: 0.52rem;
    font-weight: bold;
    margin-top: 0.93rem;
    margin-bottom: 0.93rem;
  }

  .van-form {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  .myCheckBox {
    margin-top: 16px;
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 20px;
    }
  ;
  }
}

.myFormFontSize {

  font-size: 16px;
  font-family: OPPOSans, OPPOSans;
  font-weight: bold;
  color: #333333;
  line-height: 24px;

  margin-left:6px;
  margin-right:24px;
}

.sendCodeButton {

  border: 0;
  background: none;
  color: #E86922;
  opacity: 0.6;


  font-size: 16px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  color: #E86922;
  line-height: 24px;
}



.toSubmitButton{


  //width: 325px;
  //height: 44px;
  //margin-right: 25px;
  //margin-left: 25px;
  //margin-top: 25px;
  //font-size: 16px;
  //background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);



  margin-top: 36px;


  width: 327px;
  height: 46px;
   background: #E86922;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;



  font-size: 18px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  color: #FFFFFF;
}




.checkBoxDesc {
  font-size: 12px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  color: rgba(26,12,4,0.8);
  line-height: 20px;
}

.testRem1 {
  background: #00ff0d;
  width: 750px;
  height: 12px;
}

.headFirstBg{
  height: 298px;
  background-image: url(~@/assets/img/jiangsu/loginBg.png);
  background-size: cover;
  flex-wrap: wrap;
  display: flex;

}

.buttonContainer{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

}


.checkBoxTextContainer{
  align-content: center ;
  display: flex ;

}

.checkBoxLink{
  font-size: 12px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  color: #E86922;
  line-height: 20px;
}
.checkBoxLinkAfter{
  font-size: 12px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  color: green;
  line-height: 20px;
}
.van-icon-smile-o{
 margin-right: 6px ;
}
</style>