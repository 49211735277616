<template>
  <div>
    <div class="headBg">
      <img v-bind:src="jjj">
    </div>

    <div class="formContent">
      <span class="left">
        兑换新权益
      </span>
    </div>

    <van-form>
      <van-field
          v-model="kami"
          center
          name="smsCode"
          clearable
          size="large"
          placeholder="请输入权益激活码"
          class="kami"
          maxlength="14"
          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
      >
      </van-field>



      <div>
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="submitButton"
                    @click="submitKami"
        >确认激活码
        </van-button>
      </div>
    </van-form>

    <div class="descContainer">
      <p>
        使用方法：<br>
        <br>
        1.激活码由⼤⼩写字⺟+数 字组成，共计14位 <br>
        2.输⼊激活码时，不可输 ⼊空格和特殊字符 <br>
        3.如有疑问，请联系客服<a href="tel:4006998620" style="color:#b6b9c4">400 6998 620</a> <br>
      </p>
    </div>

    <ruize-menu>
    </ruize-menu>

  </div>
</template>

<script>


import jb from '@/assets/img/yzHome/LinkBackImg.png'
import {Button, Dialog, Field, Form, Toast} from "vant";
import Cookies from "js-cookie";
import wx from 'weixin-js-sdk';
import axios from "axios";
import {mapState, mapMutations} from 'vuex'
import ruizeMenu from "@/components/ruize/RuizeMenu.vue"

export default {
  name: "YzKycPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    ruizeMenu
  },
  created() {
    this.checkUserAndInit();
    // 初始化标记来自于药诊卡,改为枚举

    this.dealJumpParams();

    this.initWechatJsSdk();
    this.$Constants.setTitle("首页");

    //检查用户状态。

  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit: false,
      originUrl:""
    }
  },
  computed: {
    ...mapState(["currentCardNumber"])
  },


  methods: {
    dealJumpParams() {
      let url = window.location.href;
      let qdCode = getUrlParams(url, "qdCode");
      let sign = getUrlParams(url, "sign");
      let requestParams = getUrlParams(url, "params")
      console.error("1")
      debugger
      if (qdCode == null || requestParams == null || sign == null) {
        let form = this.$route.params.from;
        console.error("this.$route.params.from:"+this.$route.params.from)
        console.error("2")
        if ("yaozhen" == form) {
          console.log('ok');
          console.error("4")
        } else {
          // Dialog.alert({
          //   title: '非法访问',
          //   message: '请联系客服',
          // }).then(() => {
          //   //后退一页
          //   //window.history.back();
          // });
          //todo 先注释掉
          console.error("非法访问！！！1")
          console.error("4")
        }
      }else {
        Cookies.set("from", "yaozhen");
        console.error("5")
        let params = {
          qdCode: qdCode,
          sign: sign,
          params: requestParams,
          openId: this.openId
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.dealJump, params
        ).then(res => {
          console.log(res);
          if (res.data.code === 200) {
            this.checkPhoneStatus();

            //todo 后续的内容展示

            console.error("6")
          } else {
            console.error("7")
            //验证码错误
            Dialog.alert({
              title: '非法访问',
              message: '请联系客服',
            }).then(() => {
              //后退一页
              window.location.back();
            });
            console.error("res.data.message:" + res.data.message);
          }
        })
      }

    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.$Constants.RzCookieKey.cookieOpenId, openId);
              this.openId = openId;

              //这里要跳转回去原来的url

              let tagetOriginUrl="https://www.ruizehealth.com/#"+ url.split("#")[1];
              window.location.href =tagetOriginUrl;
                 // this.dealJumpParams();
              console.error("tagetOriginUrl:"+tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.dealJumpParams();
      }
    },
    checkPhoneStatus() {
      let params = {
        openId: this.openId,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到下一页
        } else {
          Toast("用户不存在，非法访问");
          window.location.back();
          return false;
        }
      });
    },
    initWechatJsSdk() {
      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.wxConfig = JSON.parse(res.data.data);
          this.wxConfig.debug = false
          wx.config(
              this.wxConfig
          );


          wx.ready(function () {


              }
          );

          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))

          });


        }
      });


    },
    submitKami() {
      //38971c9Db42AF3
      if (this.kami.length == 14) {
        let params = {
          "pass": this.kami,
          "openId": this.openId
        }
        axios.post(
            this.$Constants.devHost + this.$Constants.RzApiMethod.yzCardLink, params
        ).then(res => {
              console.log(res);
              console.log("res.data:" + res.data);
              if (res.data.code === 200) {
                //绑定成功, 跳转到
                let cardNumber = res.data.data;
                this.setCurrentCardNumber(cardNumber);
                // 存取起来。
                this.$router.push({
                  path: "/yzHome",
                  params: {
                    linkCardNumber: cardNumber
                  }
                });

                console.log("cardNumber:" + cardNumber)
                return false;
              } else {
                Toast(res.data.message)
                return false;
              }
            }
        );
        // 提交卡密
        //进入下一个页面
        // Toast("进入下一个页面")
      } else {
        Toast("权益卡密错误")
        return false;
      }

    },
    toScan() {
      try {

        let that = this;
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            console.log("qrScan:" + result);
            that.kami = result
          }, error: function (res) {
            console.log("qrScan error :" + JSON.tringfy(res));
            if (res.errMsg.indexOf('function_not_exist') > 0) {
              Toast('当前版本低，请进行升级！');
            }
          }
        });
      } catch (e3) {
        Toast("e3:" + JSON.stringfy(e3))
      }


    },
    ...mapMutations(['setCurrentCardNumber']),
  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}
</script>

<style scoped lang="scss">

.headBg {
  img {
    width: 100%;
    //width: 750px;
    height: auto;
  }
}

.formContent {

  display: flex;
  justify-content: space-between;

  .left {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .right {
    margin-right: 25px;
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 25px;

  }

}

.kami {
  background: #F8F8F8;
  margin-left: 25px;
  width: 325px;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 40px;


}

.descContainer {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 40px;

  font-size: 12px;
  color: #B6B9C4;
}

.submitButton {
  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 16px;

  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}


.scanContainer {
  width: 100%;
  display: flex;
  flex-display: block;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-top: 28px;
  font-size: 14px;
  margin-bottom: 28px;

  .myScanLable {
    margin-top: -1px;
    margin-left: 5.6px;
  }
}
</style>