<template>
  <div class="firstLoginContainer">

    <div class="headFirstBg">
    </div>

    <van-form >
      <van-field
          v-model="cardName"
          name="cardName"
          placeholder="请输入被保险人姓名"
          size="large"
          class="myFormFontSize"
      />
      <van-field
          v-model="cardId"
          name="cardId"
          size="large"
          placeholder="请输入被保险人证件号"
          class="myFormFontSize"
      >
      </van-field>
      <div
                    class="myCheckBox"
                    icon-size="0.36rem"
                    label-disabled
                    >
        <span class="checkBoxDesc">绑定后理赔需使用当前登录手机号操作</span>
      </div>


      <!--      <div style="margin-top: 46px;">-->
      <!--        <img v-bind:src="showButtonBg" id="submitButton" @click="toSubmit()"-->
      <!--        >-->
      <!--      </div>-->
      <div class="buttonContainer">
        <van-button round
                    block
                    type="info"
                    native-type="submit"
                    class="toSubmitButton"
                    @click="toSubmitForm"
        >确认绑定
        </van-button>
      </div>

      <div class="buttonContainer">
        <van-button round
                    block
                    type="info"
                    class="toSubmitButton"
                    @click="toLoginOut"
        >退出登录
        </van-button>
      </div>


    </van-form>
    <div class="descContainer">
      <p>
        注意事项：<br>
        1.输入被保险人信息后，将与当前登陆手机号绑定，不可更改<br>
        2.您可在"山东普惠门诊保"微信公众号上进行咨询或者拨打客服热线：<a href="tel:95500">95500</a> 进行理赔咨询<br>
        3.关注"山东普惠门诊保"官方微信公众号，点击菜单栏发起理赔<br>
      </p>
    </div>


  </div>
</template>

<script>


import axios from "axios";
import {CountDown, Field, Form, Button, Checkbox, CheckboxGroup, Toast, NavBar} from 'vant';
import Cookies from 'js-cookie'
import {mapState, mapMutations} from 'vuex'

import disableButton from "@/assets/png/button_send_code_disable.png"
import okButton from "@/assets/png/button_send_code_ok.png"

import cardDetail_leftLogo from "@/assets/img/shandong/cardDetail_leftLogo.png"
import cardDetail_rigghtLogo from "@/assets/img/shandong/cardDetail_rightLogo.png"


export default {
  name: "FirstLoginPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
    [CountDown.name]: CountDown,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar
  },
  created() {
     this.checkUserAndInit();
     this.checkCard();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("睿择健康");
  },
  data() {
    return {
      cardId: '',
      sendCodeTitle: '获取短信验证码',
      cardName: '',
      time2: 2,
      checked: false,

      //shandong
      cardDetailLeftLogo:cardDetail_leftLogo,
      cardDetailRightLogo:cardDetail_rigghtLogo,
      //shandong

      showButtonBg: disableButton,
      disableButtonBg: disableButton,
      okButtonBg: okButton,
      // 是否已经发送了验证码
      isSmsSend: false,
      // 计时器对象
      timer: null,
      // 倒数60秒
      counter: 60,
      //是否允许到下一步
      canNext: false,
      openId: "",
      callBackUrl:"",
      qdCode:""
    };
  },
  computed: {
    ...mapState(["currentActiveCard"])
  },
  methods: {
    toLoginOut(){

      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      this.openId=cookieOpenId;
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: this.qdCode,
        openId: this.openId
      }



      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.loginOut4CutBuy, toSendParams
      ).then(res => {
            if (res.data.code === 200) {

              // 退出成功
              // 跳转去登陆页
              alert("退出登陆成功")
              let targetUrl="/#/ShandongLoginPage?qdCode="+this.qdCode;
              window.location.href =targetUrl;
              // window.location.back();
              console.log("not link phone")

            }else {

              //退出失败，提示，打印日志
             // alert( res.data.msg)
            }
          }
      );
    },
    getCookieOpenIdKey() {
      let qdCode = getCurrentUrlQdCode();
      if (qdCode == undefined) {
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return this.$Constants.RzCookieKey.cookieOpenId + "_" + qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:" + code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          let res = await axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          );


          {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          }
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode = getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          let res = await axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          );
          {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl = "/#/ShandongInputCard?qdCode=" + this.qdCode;
              window.location.href = tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:" + tagetOriginUrl)
            }
          }
        }
      } else {
        this.openId = cookieOpenId;
        await this.checkPhoneStatus();
      }
    },


    async checkCard() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());

      let params = {
        openId: cookieOpenId,
        qdCode: this.qdCode,
      }

      let res = await axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.shandongCheckPolicyLink, params
          );
      {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          let targetUrl = "/#/ShandongCardDetail?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
        } else {
          console.log("not link phone")
          return false;
        }
      }

    },
    async checkPhoneStatus() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      let res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      );
      {
        console.log(res);

        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }

        }  else {
          let targetUrl = "/#/ShandongLoginPage?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      }
    },


    toArticlePrivate() {
      console.log("click")

      this.$router.push({
        name: "XinhuSingleArticlePage",
        params: {
          articleId: "2"
        }
      })
    },
    toArticleRegister() {
      // 这是去
      this.$router.push({
        name: "XinhuSingleArticlePage",
        params: {
          articleId: "3"
        }
      })
    },

    toSubmitForm() {
      //todo 直接就跳转了
      //这里是保存用户信息，和手机号的
      // 判断手机号是否已经输入
      if (!this.cardName) {
        Toast('请输入身份证在名字')
        return false
      }

      if (!this.cardId) {
        Toast('请输入身份证号码')
        return false
      }
      // 判断手机号是否符合要求
      console.log("this.cardId.length=" + this.cardId.length)
      if (this.cardId.length < 15) {
        Toast('请输入正确身份证')
        return false
      }


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      let params = {
        cardName: this.cardName,
        cardId: this.cardId,
        openId: cookieOpenId,
        qdCode: this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.shandongCheckPolicy, params
      ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let targetUrl = "/#/ShandongCardDetail?qdCode=" + this.qdCode;
              window.location.href = targetUrl;
            } else {
              Toast(res.data.message);
              return false;
            }
          }
      );

    },
    ...mapMutations(['setCurrentActiveCard'])

  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">

.firstLoginContainer {


  .headFirst {

    border-radius: 20px;
    margin-top: -10px;
    display: flex;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #333C4F;
    font-size: 0.52rem;
    font-weight: bold;
    margin-top: 0.93rem;
    margin-bottom: 0.93rem;
  }

  .van-form {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }

  .myCheckBox {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;

    span {
      font-size: 12px;
      line-height: 20px;
    }
  ;
  }
}

.myFormFontSize {

margin-top: 10px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 15px;
  color: #CBCBCB;
  line-height: 15px;
}

.sendCodeButton {

  border: 0;
  background: none;
  color: #306AFC;
}



.toSubmitButton{


  //width: 325px;
  //height: 44px;
  //margin-right: 25px;
  //margin-left: 25px;
  //margin-top: 25px;
  //font-size: 16px;
  //background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);



  margin-top: 40px;


  width: 327px;
  height: 46px;
  background: #306AFC;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}




.checkBoxDesc {
  color: #FA5151;
}

.testRem1 {
  background: #00ff0d;
  width: 750px;
  height: 12px;
}

.headFirstBg{
  height: 262.5px;
  background-image: url(~@/assets/img/shandong/inputBg.png);
  background-size: cover;
  flex-wrap: wrap;
  display: flex;

}

.buttonContainer{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

}
.descContainer {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 40px;

  font-size: 12px;
  color: #B6B9C4;
}


</style>