<template>
    <div class="container">
        <div class="title_style">
            <img class="title_icon" src="~@/assets/img/gushengtang/texticon@2x.png">
            国医大师名中医坐诊，专属特约通道
        </div>
        <img src="~@/assets/img/gushengtang/background_consultation.png">
        <div class="bottom">
            <p><span style="color:#1A0C04; font-size: 14px; opacity: 0.8;">四次名医问诊机会，平均每个季度一次</span><br>
                <span style="color: #1A0C04;font-size: 18px;">当前剩余</span>
                <span style="color: red; font-size: 26px;"> {{ pageData.consultationTimes }} </span>
                <span style="color: #1A0C04;font-size: 18px;">次</span><br>
                <button v-if="pageData.consultationTimes != 0" class="button_consultation"
                    @click="gotoConsultation">预约视频问诊</button>
                <button v-if="pageData.consultationTimes === 0" class="button_consultation_unable">预约养生视频问诊</button>
                <br>
                <button class="button_record" @click="gotoRecord">查看问诊记录</button>
                <button class="button_record" @click="gotoRecord2">查看处方订单</button>
            </p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.container {
    background: #fcfaed;
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }

    .title_style {
        display: flex;
        font-size: 16px;
        font-weight: 600;
        // margin-top: 10px;
        padding-top: 5px;
        margin-bottom: 10px;

        .title_icon {
            height: 16px;
            width: 3px;
            position: relative;
            // top: 50%;
            // transform: translateY(-50%);
            height: 16px;
            padding-left: 10px;
            padding-right: 4px;
            padding-top: 4px;
        }
    }

    .bottom {
        position: relative;
        bottom: 50px;
        display: flex;
        justify-content: center;
        text-align: center;

        .button_consultation {
            margin-top: 15px;
            width: 350px;
            height: 50px;
            background-color: #E86922;
            color: white;
            font-size: 18px;
            border-radius: 25px;
            border-color: #E86922;
        }

        .button_consultation_unable {
            margin-top: 15px;
            width: 350px;
            height: 50px;
            background-color: #E86922;
            color: white;
            font-size: 18px;
            border-radius: 25px;
            border-color: #E86922;
            opacity: 0.3;
        }

        .button_record {
            margin-top: 10px;
            margin-right: 5px;
            margin-left: 5px;
            width: 170px;
            height: 50px;
            color: #E86922;
            font-size: 18px;
            border-radius: 25px;
            border-color: #E86922;
        }
    }
}
</style>

<script>
import { Toast } from 'vant';
export default {
    name: "GstConsultationByActive",
    components: {
        [Toast.name]: Toast,
    },
    data() {
        return {
            pageData: {
                consultationTimes: 0,
                gstPageParamEncryptedData: '',
                gstPageParamSign: '',
                gstHost: 'http://mp.gstyun.cn/h5ToMini.html?path=', // 生产环境
                // gstHost: 'http://mp.gstyun.cn/h5ToMiniDev.html?path=', // 测试环境
                phone: ''
            }
        }
    },
    created() {

      this.pageData.consultationTimes = this.$route.query.times;
      this.pageData.gstPageParamEncryptedData =this.$route.query.encryptData;
      this.pageData.gstPageParamSign = this.$route.query.sign;

      console.log(" this.pageData.consultationTimes "+ this.pageData.consultationTimes )

      console.log(" this.pageData.gstPageParamEncryptedData "+ this.pageData.gstPageParamEncryptedData )

      console.log(" this.pageData.gstPageParamSign "+ this.pageData.gstPageParamSign )

    },
    methods: {
        gotoConsultation() {
            let url = this.pageData.gstHost + encodeURIComponent('/pages/packageOnlineServices/pages/reproduction/index?params=' + this.pageData.gstPageParamEncryptedData + "&sign=" + this.pageData.gstPageParamSign)
            window.location.href = url
        },
        gotoRecord() {
            let url = this.pageData.gstHost + encodeURIComponent('/pages/medicalRecord/index?params=' + this.pageData.gstPageParamEncryptedData + "&sign=" + this.pageData.gstPageParamSign)
            window.location.href = url
        },
        gotoRecord2(){
            let url = this.pageData.gstHost + encodeURIComponent('/pages/packageUserCenter/pages/myOrder/index?params=' + this.pageData.gstPageParamEncryptedData + "&sign=" + this.pageData.gstPageParamSign)
            window.location.href = url
        }
    }
}

</script>