<template>
  <div class="detail">

    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <img v-bind:src="showConfigVO.detailShowVO.headBackImg" class="headFirst">
    <img v-bind:src="showConfigVO.detailShowVO.powerPoint" class="useFirst">
    <img v-bind:src="showConfigVO.detailShowVO.spe1" class="useFirst" @click="toLink1">
    <img v-bind:src="showConfigVO.detailShowVO.spe2" class="useFirst" @click="toLink2">
    <img v-bind:src="showConfigVO.detailShowVO.speXiXie" class="useFirst"  >
    <img v-bind:src="showConfigVO.detailShowVO.cover" class="useFirst"  >
    <img v-bind:src="showConfigVO.detailShowVO.useProcess" class="useFirst"  >
    <img v-bind:src="showConfigVO.detailShowVO.serviceDesc" class="useFirst" @click="toLink3" >

    <div class="listButton" v-show="willShowButton">
      <img src="http://www.ruizehealth.com/img/desc_left_button.png" @click="showPhone">
      <img src="http://www.ruizehealth.com/img/desc_right_button.png" @click="toSpe">
    </div>

  </div>
</template>

<script>


import descFirst from "@/assets/png/desc_first.png";
import descSecond from "@/assets/png/desc_second.png";
import descThirdLink from "@/assets/png/desc_third_link.png";
import descThirdLink2 from "@/assets/png/desc_third_link2.png";

import descFour from "@/assets/png/desc_four.png";
import descFive from "@/assets/png/desc_five.png";
import descSix from "@/assets/png/desc_six.png";
import descSeven from "@/assets/png/desc_seven.png";

import {Button, Dialog, NavBar, Toast} from 'vant';
import axios from "axios";
import Cookies from "js-cookie";
import {mapMutations, mapState} from "vuex";


export default {
  name: "DetailShow",
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar
  },
  created: function () {

    //判断cookieopenid
    //判断是否有最新的卡
    let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);
    if (cookieOpenId == undefined) {
      this.$router.push("/")
    }

    this.openId = cookieOpenId;

    if(this.from=="third"){
      this.willShowButton=false;
    }else {
      this.willShowButton=true;
      this.checkActiveCard();
    }


   this.from= this.$route.query.from;
   console.error("params from:"+this .from )

    this.toShowCard=this.$route.query.toShowCard;
    console.error("toShowCard:"+ this.toShowCard)

    //init ShowConfigVO
    this.initShowPhoto();
    //init ShowConfigVO
    this.$Constants.setTitle("睿择健康");

    this.initGetClaimJumpUrl();

  },

  computed: {
    ...mapState(["currentActiveCard"])
  },

  data(){
    return{
      descFirst: descFirst,
      descSecond:descSecond,
      descThirdLink:descThirdLink,
      descThirdLink2:descThirdLink2,
      descFour:descFour,
      descFive:descFive,
      descSix:descSix,
      descSeven:descSeven,



      //这是配置的内容
      showConfigVO:{},
      from:"",
      willShowButton:"",
      toShowCard:"",
      pageTitle:"",
      initJumpUrl:false,
      claimJumpUrl:""
    }
  },
  methods:{
    checkActiveCard() {
      let params = {
        openId: this.openId
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCurrentActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.setCurrentActiveCard(res.data.data);
        } else {
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },
    toLink1(){
      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:this.showConfigVO.detailShowVO.spe1ArticleId
        }
      })
    },
    toLink2(){
      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:this.showConfigVO.detailShowVO.spe2ArticleId
        }
      })
    },
    toLink3(){
      this.$router.push({
        name:"singleArticlePage",
        params:{
          articleId:this.showConfigVO.detailShowVO.serviceArticleId
        }
      })
    },
    onClickLeft() {
      //返回
      this.$router.back();
    },
    initShowPhoto(){

      let targetShowCardNumber="";
      if(this.toShowCard!=""){
        console.log("will show toShowCard")
        targetShowCardNumber=this.toShowCard;
      }else {
        targetShowCardNumber=this.currentActiveCard;
        console.log("default toShowCard")

      }
      let
          params={
            openId:this.openId,
            cardNumber:targetShowCardNumber,
          }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCardShow, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.showConfigVO=res.data.data;
          //绑定要映射的内容
          console.log("showConfigVO:"+this.showConfigVO);

          //设置页面的标题为卡的名字
          this.pageTitle='特药详情';
        } else {
          Toast(res.data.message)
        }
      });
    },

    initGetClaimJumpUrl() {
      let params = {
        openId: this.openId,
        cardNumber: this.currentActiveCard
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getClaimUrl, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.claimJumpUrl = res.data.data;
          console.error("------- 获取理赔url 成功：" + this.claimJumpUrl)
          this.initJumpUrl=true;
        } else {

          console.error("------- 获取理赔url 失败")
          this.initJumpUrl=true;
        }
      });

    },
  showPhone() {
    window.location.href="tel:4006998620";
  },
    toSpe(){

      if(this.initJumpUrl==false){
        Dialog.alert({
          title: '特药权益',
          message: '数据在加载中，5秒后重试',
        }).then(() => {
          // todo 跳转到首页
          //this.$router.push("home")
        });
        return false;
      }

      if(this.initJumpUrl==true){
        if (this.claimJumpUrl === "") {
          this.initGetClaimJumpUrl();
        }
      }

      if (this.claimJumpUrl === "") {
        Dialog.alert({
          title: '特药权益',
          message: '在权益卡激活30天后可申请服务',
        }).then(() => {
          // todo 跳转到首页
          //this.$router.push("home")
        });
      } else {
        window.location.href = this.claimJumpUrl;
      }

    },
    ...mapMutations(['setCurrentActiveCard']),
  }
}
</script>


<style scoped lang="scss">
.detail{
  img{
    width: 100%;
  }
}


.listButton {
  display: flex;
  flex-direction: row;
  img {
    width: 50%;
    height: 43.61px;

  }
}
</style>