<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="orderStatusName" ref="statusNameDom">
      {{ orderDetail.statusName }}
    </div>

    <div class="section firstSection">
      <div class="firstHead">
        <div class="leftText">配送方式</div>
        <div class="rightText">快递邮寄</div>
      </div>

      <div class="firstHead">
        <div class="leftText">支付方式</div>
        <div class="rightText">在线支付</div>
      </div>

      <div class="contractLink">
        <div class="leftText">配送地址</div>
        <div class="rightText">{{ orderDetail.name }}</div>
        <div class="rightText">{{ orderDetail.phone }}</div>
      </div>
      <div class="detailAddressContainer">


        <div class="detailAddress">
          {{ orderDetail.address }}

        </div>

      </div>

      <div class="showDeliveryClass" v-show="showDelivery" @click="toDelivery(orderDetail.orderNo)">
        点击查看物流信息 >
      </div>
    </div>


    <div class="section middleContent">
      <div class="firstHead">
        <div class="leftText">健康福利商城</div>
      </div>
      <div class="itemDetailContainer" v-for="item in orderDetail.orderDetailList" :key="item.id">
        <div class="leftIconContainer">
          <img :src="item.picUrl">
        </div>

        <div class="middleContainer">
          <van-tag round type="danger">{{ item.tagName }}</van-tag>
          {{ item.name }}
        </div>

        <div class="rightContainer">
          <div class="topText">
            实付 ¥{{ item.priceStr }}
          </div>
          <!-- 暂时不显示 -->
          <!--          <DIV CLASS="bottomText" >-->
          <!--            ¥30.90-->
          <!--          </DIV>-->
          <DIV CLASS="bottomText">
            X{{ item.quantity }}
          </DIV>
        </div>

      </div>

      <div class="lineDIV">

      </div>

      <DIV CLASS="bottomMoneyContainer">
        <div class="firstHead">
          <div class="leftText">药品金额</div>
          <div class="rightText">{{ orderDetail.medicineFeeStr }}</div>
        </div>
        <div class="firstHead">
          <div class="leftText">快递费</div>
          <div class="rightText">{{ orderDetail.deliveryAmountStr }}</div>
        </div>


        <div class="firstHead">
          <div class="leftText">药品理赔金额</div>
          <div class="rightText">{{ orderDetail.medicineDiscountAmountStr }}</div>
        </div>
        <div class="firstHead">
          <div class="leftText">快递费优惠金额</div>
          <div class="rightText">{{ orderDetail.deliveryDiscountAmountStr }}</div>
        </div>

        <DIV class="priceContainer">
          <div class="leftText"> 实付款</div>
          <div class="rightText">¥{{ orderDetail.payAmountStr }}</div>
        </DIV>
      </DIV>
    </div>

    <div class="section " v-if="orderDetail.prescription.length>0">
      <van-cell title="购药处方" is-link value="查看" @click="toShowPicUrl"/>
    </div>

    <div class="section">

      <DIV CLASS="bottomMoneyContainer">
        <div class="firstHead">
          <div class="leftText">订单编号</div>
          <div class="rightText">{{ orderDetail.orderNo }}</div>
        </div>
        <div class="firstHead">
          <div class="leftText">创建时间</div>
          <div class="rightText">{{ orderDetail.createTimeStr }}</div>
        </div>
        <div class="firstHead" v-if="orderDetail.payTimeStr">
          <div class="leftText">支付时间</div>
          <div class="rightText">{{ orderDetail.payTimeStr }}</div>
        </div>

      </DIV>
    </div>


    <div CLASS="buttonListContainer">


      <div
          class="sonButton" v-if="orderDetail.payUrl" @click="toCancle(orderDetail.orderNo)">
        取消
      </div>


      <div
          class="sonButton" v-if="orderDetail.status==40 || orderDetail.status==50"
          @click="toRefund(orderDetail.orderNo)">
        申请退款
      </div>


      <DIV CLASS="sonButton" @click="toAsk">
        平台客服
      </DIV>

      <div
          class="activeButton" v-if="orderDetail.payUrl" @click="toPay(orderDetail.payUrl)">
        去支付
      </div>


    </div>

  </div>
</template>

<script>


import {Toast, Tab, Tabs, Tag, NavBar, Cell, Dialog} from 'vant';
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "Empty",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [Cell.name]: Cell
  },
  data() {
    return {
      pageTitle: "订单详情",
      activeTabName: 'a',
      orderDetail: {},
      items: [{"orderNo": "1234"}, {"orderNo": "1234"}],
      showDelivery: false,
      openId:"",
      qdCode:""
    }
  },
  created() {
    this.checkUserAndInit();
    this.initOrderDetail();
  },
  methods: {
    getCookieOpenIdKey(){
      let qdCode=getCurrentUrlQdCode();
      if(qdCode==undefined){
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return  this.$Constants.RzCookieKey.cookieOpenId+"_"+qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:"+code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode=getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl="//www.ruizehealth.com/#"+ url.split("#")[1];
              window.location.href =tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:"+tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
      }
    },checkPhoneStatus() {


      if(this.qdCode==""){
        this.qdCode=getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode:this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if(this.qdCode==""){
            this.qdCode=getCurrentUrlQdCode();

            if(this.qdCode==""){
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl="/#/ShandongLoginPage?qdCode="+this.qdCode;
          window.location.href =targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      });
    },
    toAsk() {
      //todo 这里提示去找客服

      Dialog.alert({
        title: '温馨提示',
        message: '暂不支持相关操作，请联系“在线客服”获取帮助。',
      }).then(() => {
        //后退一页
        //window.history.back();
      });
    },
    toCancle() {
      Dialog.alert({
        title: '温馨提示',
        message: '请点击“健康商城”后的“我的订单”中操作订单取消和退货',
      }).then(() => {
        //后退一页
        //window.history.back();
      });
    },
    toRefund() {
      Dialog.alert({
        title: '温馨提示',
        message: '请点击“健康商城”后的“我的订单”中操作订单取消和退货',
      }).then(() => {
        //后退一页
        //window.history.back();
      });
    },
    toPay(payUrl) {
      window.location.href = payUrl;
    },
    toShowPicUrl() {
      let currentUrl = window.location.href;
      let urlParams = "";
      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        urlParams = urlS[1]
      }
      urlParams = urlParams + "&picUrl=" + this.orderDetail.prescription[0].prescriptionUrl;
      window.location.href = "/#/hmbOrderPrescription?" + urlParams;
    },
    initOrderDetail() {
      let currentUrl = window.location.href;
      let qdCode = getUrlParams(currentUrl,"qdCode");
      this.qdCode=qdCode;
      let orderNo = getUrlParams(currentUrl,"orderNo");


      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: qdCode,
        orderNo: orderNo,
        openId:this.openId
      }

      let urlParams = "";
      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        urlParams = urlS[1]
      }


      let that = this;
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.shandongOrderDetail + "?" + urlParams, toSendParams
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量

          if (res.data.data.length > 0) {
            this.orderDetail = res.data.data[0];
            console.info("orderDetail:" + this.orderDetail)

            //设置状态颜色
            if (this.orderDetail.statusName == "待支付") {
              that.$refs.statusNameDom.classList.add("orderStatusName_toPay");
            }

            if(this.orderDetail.status==40 || this.orderDetail.status==50){
                this.showDelivery=true;
            }

          }
        } else {
          console.error(res)
        }
      });
    },
    toDelivery(orderNo) {

      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数

      let tarUrl=  "/#/ShandongDeliveryDetail" + "?orderNo="
          +orderNo+"&qdCode="+this.qdCode;

      window.location.href=tarUrl;

    },
    onClickLeft() {
      //返回
      window.history.back();
    }
  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode(){
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode= getUrlParams(url,"qdCode");

  if(qdCode==undefined){
    let url = window.location.href;

    let originUrl= url.split("#")[1];

    qdCode= getUrlParams(originUrl,"qdCode");

    console.log("qdCode:"+qdCode)

  }
  return qdCode;
}

</script>

<style scoped lang="scss">
.testRem1 {
  background: #F4F5F9 !important;
  height: auto;
}


.ruizeNav{
  color:  #E86922;
}

.orderStatusName {

  width: 375px;
  height: 60px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  font-size: 20px;
  font-family: OPPOSans-Bold, OPPOSans;
  font-weight: bold;
  color: rgba(26, 12, 4, 0.3);
  line-height: 28px;

}


.orderStatusName_toPay {
  color: #E86922;
}

.section {
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px !important;
  margin: 0px 12px 8px 12px;
  opacity: 1;
  display: flex;
}

.firstSection {
  width: 351px;
  display: flex;
  flex-direction: column;
}


.firstHead {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: space-between;


  .leftText {
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 350;
    color: rgba(26, 12, 4, 0.7);
    line-height: 22px;
  }

  .rightText {
    color: #1A0C04;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 350;
    line-height: 22px;
  }

}


.contractLink {
  display: flex;
  flex-direction: row;
  font-size: 14px;

  margin-top: 16px;
  margin-left: 12px;
  margin-right: 12px;

  .rightText {
    margin-left: 8px;
    height: 22px;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: #1A0C04;
    line-height: 22px;
  }

}

.detailAddressContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 12px 16px;

  .detailAddress {
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 500;
    color: rgba(26, 12, 4, 0.6);
    line-height: 22px;
    width: 263px;
  }
}


.itemDetailContainer {
  margin-top: 12PX;
  margin-left: 12PX;
  margin-right: 12PX;
  display: flex;
  flex-direction: row;
  justify-content: space-between;


  .leftIconContainer {

    img {
      width: 72px;
      height: 72px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;

    }


  }


  .middleContainer {
    margin-left: 8PX;
    width: 161px;
    height: 44px;
    font-size: 14px;
    font-weight: 400;
    color: #1A0C04;
    line-height: 22px;

    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }


  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;

    .topText {
      height: 20px;
      font-size: 12px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 20px;
    }

    .bottomText {
      height: 20px;
      font-size: 12px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: rgba(26, 12, 4, 0.5);
      line-height: 20px;
    }
  }


}

.middleContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
}


.lineDIV {
  margin: 12px 12px 0 12px;
  width: 327px;
  height: 0px;
  opacity: 1;
  border: 1px solid rgba(26, 12, 4, 0.04);
}

.priceContainer {
  display: flex;
  justify-content: flex-end;

  margin-top: 12PX;
  margin-right: 12px;

  .leftText {
    height: 22px;
    font-size: 14px;
    font-family: OPPOSans-Medium, OPPOSans;
    font-weight: 400;
    color: #1A0C04;
    line-height: 24px;
    margin-right: 8px;
  }

  .rightText {
    font-size: 20px;
    font-family: OPPOSans-Bold, OPPOSans;
    font-weight: 350;
    color: #FA5151;
    line-height: 24px;
  }


}

.van-cell {
  border-radius: 12px 12px 12px 12px !important;


}

.buttonListContainer {
  width: 375px;
  height: 54px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  background: #FFFFFF;

  .sonButton {
    width: 88px;
    height: 38px;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;
    border: 1px solid rgba(26, 12, 4, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: auto;
    margin-top: auto;
    margin-right: 12px;


    font-size: 14px;
    font-weight: 500;
    color: #1A0C04;
    line-height: 22px;
  }

  .activeButton {
    width: 88px;
    height: 38px;
    background: #E86922;
    border-radius: 100px 100px 100px 100px;
    opacity: 1;

    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: auto;
    margin-top: auto;
    margin-right: 12px;
  }
}

.bottomMoneyContainer {
  width: 100%;
  margin-bottom: 12px;

}


.showDeliveryClass {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 12px 16px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(26,12,4,0.4);
  line-height: 22px;
  width: 100%
}
</style>