<template>

  <div >
    <van-tabbar v-model="menuStatus.currentMenu" class="menuHeight" active-color="#31E8C3" inactive-color="#BEBEBE" >
      <van-tabbar-item name="home" icon-prefix="jobtuitui_icon" icon="shouye"  @click="toHome()"  class="menuSon" >首页</van-tabbar-item>
      <van-tabbar-item name="userCenter" icon-prefix="jobtuitui_icon" icon="gerenzhongxin"  :badge="menuStatus.userCenter.count>0?menuStatus.userCenter.count:''"
                       class="menuSon"
                       @click="toUserCenter()">我的
      </van-tabbar-item>
    </van-tabbar>
  </div>


</template>

<script>

import {Tabbar, TabbarItem,Icon} from 'vant';
import {mapState, mapMutations} from 'vuex'

export default {
  name: "RuizeChecheMenu",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Icon.name]: Icon
  },

  created() {
    let that=this;

    let currentPath=this.$route.path;
    let menuListName=["home","userCenter"];
    menuListName.forEach(function(element) {
      if(currentPath.indexOf(element)>-1){
        console.log(element)
        that.selectMenu(element);
        //Vuex.$store.commit('selectMenu',element);
      }
    })



  },

  data() {
    return {
      time2: 20
    };
  },
  computed: {
    ...mapState(["menuStatus"])
  }, methods: {
    toHome() {
     let url= "/#/ChecheLoginPage?qdCode=cheche001";
       window.location.href=url;
    },
    toUserCenter(e) {
      console.log(e)
      let url= "/#/ChecheUserCenter?qdCode=cheche001";
      window.location.href=url;
    },
    ...mapMutations(['selectMenu'])
  }

}
</script>

<style scoped lang="scss">
.menuHeight{
  height: 51px;
  font-size: 13px;
  .menuSon{
    font-size: 13px;
  }


}
.jobtuitui_icon{
  font-size: 13px;
}


</style>