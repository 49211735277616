import { render, staticRenderFns } from "./GstConsultationByActive.vue?vue&type=template&id=01a93d89&scoped=true&"
import script from "./GstConsultationByActive.vue?vue&type=script&lang=js&"
export * from "./GstConsultationByActive.vue?vue&type=script&lang=js&"
import style0 from "./GstConsultationByActive.vue?vue&type=style&index=0&id=01a93d89&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a93d89",
  null
  
)

export default component.exports