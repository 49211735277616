<template>
  <div>


    <div class="descContainer">


      <!-- heye 问诊 -->
      <div class="sectionHead"
      >
        <!-- heye 商城 -->
        <div class="buyImgContainer"
             :jumpUrl="singleCard.claimUrl"
             @click="toChangeActiveCard($event)">
          <img src="~@/assets/png/mix/mix_1.png">
        </div>

        <!-- gst 问诊（我们自己的首页) -->
        <div class="buyImgContainer"
             @click="toChangeActiveCard($event)"
             :jumpUrl="singleCard.buyUrl"
        >
          <img src="~@/assets/png/mix/mix_2.png">
        </div>

        <!-- gst 静态页面  -->
        <div class="buyImgContainer"
             @click="toChangeActiveCard($event)"
             :jumpUrl="singleCard.gstUrl"
        >
          <img src="~@/assets/png/mix/mix_3.png">
        </div>

        <div class="buyImgContainer"
             @click="toChangeActiveCard($event)"
             :jumpUrl="singleCard.gstStaticUrl"
        >
          <img src="~@/assets/png/mix/mix_4.png">
        </div>

      </div>
    </div>





  </div>
</template>

<script>


import jb from '@/assets/img/yzHome/LinkBackImg.png'
import {Button, Field, Form, Toast} from "vant"; //Dialog
import Cookies from "js-cookie";
import axios from "axios";

export default {
  name: "CutBuyInputCardPage",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button
  },
  created() {
    this.checkUserAndInit();
    // 初始化标记来自于药诊卡,改为枚举
    this.$Constants.setTitle("首页");
    //检查用户状态。
    this.qdCode = getCurrentUrlQdCode();


  },
  data() {
    return {
      jjj: jb,
      kami: "",
      openId: '',
      wxConfig: {},
      wxInit: false,
      originUrl: "",
      qdCode: "",
      singleCard:
        {}
      ,
    }
  },

  methods: {
    toChangeActiveCard(e) {
      let targets = e.currentTarget;
      let jumpUrl = targets.getAttribute("jumpUrl");
      console.log("change jumpUrl:" + jumpUrl);
      window.location.href=jumpUrl;
    }
,
    getCookieOpenIdKey() {
      let qdCode = getCurrentUrlQdCode();
      if (qdCode == undefined) {
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return this.$Constants.RzCookieKey.cookieOpenId + "_" + qdCode;
    },
    checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          })
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid
          let params = {
            code: code,
          }
          axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId, params
          ).then(res => {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl = "https://www.ruizehealth.com/#" + url.split("#")[1];
              window.location.href = tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:" + tagetOriginUrl)
            }
          });
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
        this.allCard();
      }
    },

    checkPhoneStatus() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      ).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }
          // let targetUrl = "//www.ruizehealth.com/#/CutBuyInputCardPage?qdCode=" + this.qdCode;
          // window.location.href = targetUrl;
        } else {

          // window.location.back();
          Toast("请先验证手机号");

          this.qdCode = getCurrentUrlQdCode();
          window.location.href = "//www.ruizehealth.com/#/MixCutBuyLoginPage?qdCode=" + this.qdCode;
          return false;
        }
      });
    },

    allCard() {
      this.qdCode = getCurrentUrlQdCode();
      //38971c9Db42AF3
      let params = {
        "openId": this.openId,
        "qdCode": this.qdCode
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.mixAllCard4CutBuy, params
      ).then(res => {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              //绑定成功, 跳转到
              this.singleCard = res.data.data;

              this.singleCard.gstUrl=res.data.data.gstUrl;
              this.singleCard.buyUrl=res.data.data.buyUrl;
              this.singleCard.claimUrl=res.data.data.claimUrl;
              this.singleCard.gstStaticUrl=res.data.data.gstStaticUrl;

              // 刷新。
              return false;
            } else {
              return false;
            }
          }
      );

    }


  }
}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}


function getCurrentUrlQdCode() {
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode = getUrlParams(url, "qdCode");
  if (qdCode == undefined) {
    let url = window.location.href;
    let originUrl = url.split("#")[1];

    qdCode = getUrlParams(originUrl, "qdCode");

    console.log("qdCode:" + qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">
.buyImgContainer {
  img {
    width: 100%;
    margin-top: 20px;
  }


}

.headBg {
  img {
    width: 100%;
    //width: 750px;
    height: auto;
  }
}

.formContent {

  display: flex;
  justify-content: space-between;

  .left {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .right {
    margin-right: 25px;
    margin-top: 18px;
    margin-bottom: 14px;
    font-size: 25px;

  }

}

.kami {
  background: #F8F8F8;
  margin-left: 25px;
  width: 325px;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 40px;


}


.submitButton {
  width: 325px;
  height: 44px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 16px;

  background-image: linear-gradient(0deg, #31E8C3 0%, #16CC90 100%);

}


.scanContainer {
  width: 100%;
  display: flex;
  flex-display: block;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-top: 28px;
  font-size: 14px;
  margin-bottom: 28px;

  .myScanLable {
    margin-top: -1px;
    margin-left: 5.6px;
  }
}
</style>