<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />

    <div class="firstContainer">
      <div class="title1Container">
        <div class="textLeft">湖北普惠门诊保</div>
        <img :src="require('@/assets/png/hmb/baozhang_logo.png')" class="imgRight"/>
      </div>
      <div class="title2Container">
        <div class="textLeft">被保险人</div>
        <div class="textRight">{{claimInfo.userName}}</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">保障期限</div>
        <div class="textRight">{{claimInfo.startDate}}-{{claimInfo.endDate}}</div>
      </div>
    </div>

    <div class="secondContainer">
      <div class="myTitle">
        我的权益
      </div>
      <div class="title2Container">
        <div class="textLeft">累计剩余理赔额度</div>
        <div class="textRight">{{claimInfo.applyBalance}} 元</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">单月理赔次数</div>
        <div class="textRight">3次</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">单次理赔限额</div>
        <div class="textRight">{{claimInfo.singleLimit}} 元</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">赔付比例</div>
        <div class="textRight">70%</div>
      </div>
    </div>


    <div class="thirdContainer">
      <div class="myTitle">
        保单服务
      </div>
      <div class="iconContainer">

        <div class="buttonAndText" @click="toLipei">
          <img :src="require('@/assets/png/hmb/icon/lipei.png')">
          <span>保险理赔</span>
        </div>


        <div class="buttonAndText" @click="toOrderList">
          <img :src="require('@/assets/png/hmb/icon/dingdan.png')">
          <span>购药订单</span>
        </div>
      </div>
    </div>

  </div>
</template>


<script>


import {Button, NavBar, Toast, Uploader} from 'vant';
import axios from "axios";
//import axios from "axios";

export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader

  }, data() {
    return {
      pageTitle: "保单详情",
      claimUrl: "",
      orderListUrl: "",
      buyShopUrl: "",
      claimInfo:{},
      urlParams:""
    }
  },
  methods: {
    toLipei() {
      if(this.claimUrl==""){
        Toast("初始化中，请5秒后再点击");
      }else {
        window.location.href = this.claimUrl
      }

    },
    toShop() {
      if(this.buyShopUrl==""){
        Toast("初始化中，请5秒后再点击");
      }else {
        window.location.href = this.buyShopUrl
      }

    },
    toOrderList() {
      if(this.orderListUrl==""){
        Toast("初始化中，请5秒后再点击");

      }else {
        window.location.href = this.orderListUrl
      }

    },

    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
      //不支持火腿了
      //window.history.back();
    },
    initUrlAndInfo(){
      let currentUrl = window.location.href;
      let originParams = new URLSearchParams(currentUrl.search);
      let sign = originParams.get("sign");
      let qdCode = originParams.get("qdCode");
      let fromParams = originParams.get("params");


      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        sign: sign,
        qdCode: qdCode,
        params: fromParams
      }

      let urlS = currentUrl.split("?");
      if (urlS.length > 0) {
        this.urlParams = urlS[1]
      }

      //检查用户当前激活的卡是否存在。
      axios.get(
          this.$Constants.devHost + this.$Constants.RzApiMethod.hmbRzDetail + "?" + this.urlParams, toSendParams
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.claimUrl = res.data.data.claimUrl;
          this.buyShopUrl = res.data.data.buyShopUrl;
          this.orderListUrl = res.data.data.orderListUrl;

          this.claimInfo=res.data.data.info;

          // 处理单位为元
          try{
            if(this.claimInfo.singleLimit!=undefined &&  this.claimInfo.singleLimit>0){
              this.claimInfo.singleLimit=this.claimInfo.singleLimit/100;
            }

            if(this.claimInfo.applyTimes!=null && this.claimInfo.applyTimes==-1){
              this.claimInfo.applyTimes="无限制";
            }

            if(this.claimInfo.singleLimit!=undefined && this.claimInfo.singleLimit=="0"){
              this.claimInfo.applyTimes="3";
              this.claimInfo.singleLimit="1000";
              this.claimInfo.applyBalance="20000";
            }


          }catch (e){
            console.error("e:"+e)
          }



        } else {
          console.error(res)
        }
      });
    }
  },
  created() {
   this.initUrlAndInfo();
  },


}
</script>

<style scoped lang="scss">

.testRem1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F4F5F9 !important;
}

.firstContainer {
  margin: 12px;
  width: 351px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;

  .title1Container {
    margin: 12px 12px 36px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textLeft {
      margin: auto auto;
      align-content: center;
      justify-content: center;
      width: 263px;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #1A0C04;
      line-height: 34px;
    }

    .imgRight {
      width: 56px;
      height: 56px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }


}


.title2Container {
  display: flex;
  flex-direction: row;
  margin: 0 12px 12px 12px;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;

  .textLeft {
    font-weight: 500;
    color: rgba(26, 12, 4, 0.4);
    font-family: OPPOSans-Medium, OPPOSans;
  }

  .textRight {
    font-weight: 500;
    color: #1A0C04;
    font-family: OPPOSans-Medium, OPPOSans;
  }


}


.secondContainer {
  width: 351px;
  height: 192px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;

}

.myTitle {
  border-left: solid 2px #E86922;
  margin-left: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #1A0C04;
  padding-left: 6px;
}


.thirdContainer {
  width: 351px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;


}

.iconContainer {


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;

  .buttonAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 98px;
    height: 62px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 14px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 22px;
    }
  }
}


</style>