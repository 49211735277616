<template>
  <div class="testRem1">
    <van-nav-bar
        :title="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        class="ruizeNav"
    />


    <div class="headFirstBg">
      <div class="logoContainer">
        <img :src="cardDetailLeftLogo" class="leftLogo"/>
        <img :src="cardDetailRightLogo" class="rightLogo"/>
      </div>


    </div>

    <div class="bigLogoContainer">
      <img :src="cardDetailBigLogo" class="bigLogo"/>
    </div>


    <div class="firstContainer">
      <div class="title2Container">
        <div class="textLeft">被保险人</div>
        <div class="textRight">{{ claimInfo.userName }}</div>
      </div>

      <div class="title2Container">
        <div class="textLeft">手机号</div>
        <div class="textRight">{{ claimInfo.phone }}</div>
      </div>
      <!--      <div class="title2Container">-->
      <!--        <div class="textLeft">保障期限</div>-->
      <!--        <div class="textRight">{{claimInfo.startDate}}-{{claimInfo.endDate}}</div>-->
      <!--      </div>-->


      <div class="title2Container">
        <div class="textLeft">保单号</div>
        <div class="textRight">{{ claimInfo.policyNo }}</div>
      </div>
    </div>

    <div class="secondContainer">
      <div class="myTitle">
        我的权益
      </div>
      <div class="title2Container">
        <div class="textLeft">累计剩余理赔额度</div>
        <div class="textRight">{{ claimInfo.shandongAllBalanceVO.medicineRight.leftAmountStr }} 元</div>
      </div>

      <div class="title2Container">
        <div class="textLeft">累计剩余购药次数</div>
        <div class="textRight">{{ claimInfo.shandongAllBalanceVO.medicineRight.totalLeft }} 次</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">单月剩余购药次数</div>
        <div class="textRight">{{ claimInfo.shandongAllBalanceVO.medicineRight.monthLeft }} 次</div>
      </div>


      <div class="title2Container">
        <div class="textLeft">单次理赔限额</div>
        <div class="textRight">{{ claimInfo.shandongAllBalanceVO.medicineRight.usableMoneyStr }} 元</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">赔付比例</div>
        <div class="textRight">首次100%，后续70%</div>
      </div>
      <div class="title2Container">
        <div class="textLeft">邮寄政策</div>
        <div class="textRight">首次包邮，后续满68包邮</div>
      </div>
    </div>


    <div class="thirdContainer">
      <div class="myTitle">
        保单服务
      </div>
      <div class="iconContainer">


        <div class="buttonAndText" @click="toLipei">
          <img :src="require('@/assets/png/hmb/icon/lipei.png')">
          <span>保险理赔</span>
        </div>

        <div class="buttonAndText" @click="toShop">
          <img :src="require('@/assets/png/hmb/icon/shop@2x.png')">
          <span>健康商城</span>
        </div>
        <div class="buttonAndText" @click="toOrderList">
          <img :src="require('@/assets/png/hmb/icon/dingdan.png')">
          <span>购药订单</span>
        </div>
      </div>
    </div>


    <div class="buttonContainer">
      <van-button round
                  block
                  type="info"
                  native-type="submit"
                  class="toSubmitButton"
                  @click="toLoginOut"
      >退出登录
      </van-button>
    </div>

  </div>
</template>


<script>


import {Button, NavBar, Toast, Uploader} from 'vant';
import axios from "axios";

import cardDetail_leftLogo from "@/assets/img/shandong/cardDetail_leftLogo.png"
import cardDetail_rigghtLogo from "@/assets/img/shandong/cardDetail_rightLogo.png"
import cardDetailBigLogo from "@/assets/img/shandong/cardDetailBigLogo.png"
import Cookies from "js-cookie";


//import axios from "axios";

export default {
  name: "Empty",
  components: {
    [Toast.name]: Toast,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Uploader.name]: Uploader

  }, data() {
    return {
      pageTitle: "保单详情",
      claimUrl: "",
      //todo 更新这个地址
      orderListUrl: "/#/ShandongOrderList?qdCode=shandong1001",
      buyShopUrl: "",
      claimInfo: {},
      urlParams: "",

      //shandong
      cardDetailLeftLogo: cardDetail_leftLogo,
      cardDetailRightLogo: cardDetail_rigghtLogo,
      cardDetailBigLogo: cardDetailBigLogo,
      //shandong
      openId: "",
      qdCode: "",
    }
  },
  methods: {
    toLoginOut() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      this.openId = cookieOpenId;
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: this.qdCode,
        openId: this.openId
      }


      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.loginOut4CutBuy, toSendParams
      ).then(res => {
            if (res.data.code === 200) {

              // 退出成功
              // 跳转去登陆页
              alert("退出登陆成功")
              let targetUrl = "/#/ShandongLoginPage?qdCode=" + this.qdCode;
              window.location.href = targetUrl;
              // window.location.back();
              console.log("not link phone")


            } else {

              //退出失败，提示，打印日志
              // alert( res.data.msg)
            }
          }
      );
    },
    getCookieOpenIdKey() {
      let qdCode = getCurrentUrlQdCode();
      if (qdCode == undefined) {
        console.error("qdCode is null!!!!!!!!!!!");
      }
      return this.$Constants.RzCookieKey.cookieOpenId + "_" + qdCode;
    },
    async checkUserAndInit() {
      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      if (cookieOpenId == undefined) {
        //如果不存在，则需要判断是否有code
        let url = window.location.href;
        let code = getUrlParams(url, "code")
        debugger
        console.log("code:" + code)
        if (code == null) {
          console.log("--------")
          let params = {
            originUrl: url,
          }
          let res = await axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getBaseUserRedirectUrl, params
          );


          {
            console.log(res);
            console.log("res.data:" + res.data);
            if (res.data.code === 200) {
              let redirectUrl = res.data.data;
              console.log("redirectUrl:" + redirectUrl);
              //重定向
              window.location.href = redirectUrl
            } else {
              //验证码错误
              console.error("res.data.message:" + res.data.message);
            }
          }
          //code 都没有，则需要同步访问了。
        } else {
          console.log("=++++++++++++")
          // 再去调用服务器，获取用户openid

          this.qdCode = getCurrentUrlQdCode();
          let params = {
            code: code,
            qdCode: this.qdCode
          }
          let res = await axios.post(
              this.$Constants.devHost + this.$Constants.RzApiMethod.getUserOpenId4CutBuy, params
          );
          {
            console.log(res);
            if (res.data.code === 200) {
              let openId = res.data.data;
              console.log("openId:" + openId);
              //设置cookie的id
              Cookies.set(this.getCookieOpenIdKey(), openId);
              this.openId = openId;
              //这里要跳转回去原来的url
              let tagetOriginUrl = "/#/ShandongCardDetail?qdCode=" + this.qdCode;
              window.location.href = tagetOriginUrl;
              // this.dealJumpParams();
              console.error("tagetOriginUrl:" + tagetOriginUrl)
            }
          }
        }
      } else {
        this.openId = cookieOpenId;
        this.checkPhoneStatus();
      }
    },

    toLipei() {
      if (this.claimUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.claimUrl
      }

    },
    toShop() {
      if (this.buyShopUrl == "") {
        Toast("初始化中，请5秒后再点击");
      } else {
        window.location.href = this.buyShopUrl
      }

    },
    toOrderList() {
      if (this.orderListUrl == "") {
        Toast("初始化中，请5秒后再点击");

      } else {
        window.location.href = this.orderListUrl
      }

    },

    onClickLeft() {
      //返回
      console.error("-----:onClickLeft")
      //不支持火腿了
      //window.history.back();
    },
    async initUrlAndInfo() {

      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());
      this.openId = cookieOpenId;
      //   检查是否已经有过/
      // 理赔人的
      // 获取当前url参数
      let toSendParams = {
        qdCode: this.qdCode,
        openId: this.openId
      }


      //检查用户当前激活的卡是否存在。
      const res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.shandongRzDetail, toSendParams
      );

      {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.claimUrl = res.data.data.claimUrl;
          this.buyShopUrl = res.data.data.buyShopUrl;
          //this.orderListUrl = res.data.data.orderListUrl;
          this.claimInfo = res.data.data.info;
        } else {
          console.error(res)
          alert(res.data.msg);
        }
      }

    }
    ,
    async checkCard() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }

      let cookieOpenId = Cookies.get(this.getCookieOpenIdKey());

      let params = {
        openId: cookieOpenId,
        qdCode: this.qdCode,
      }

   let res=  await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.shandongCheckPolicyLink, params
      );

      {
        debugger
        console.log(res);
        if (res.data.code === 200) {
          console.log("ok")
        } else {
          console.log("not link phone")
          // 跳转到输入卡密页面。
          let targetUrl = "/#/ShandongInputCard?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          return false;
        }
      }

    },
    async checkPhoneStatus() {


      if (this.qdCode == "") {
        this.qdCode = getCurrentUrlQdCode();
      }
      let params = {
        openId: this.openId,
        qdCode: this.qdCode,
      }
      let res = await axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getUserPhoneStatus4CutBuy, params
      );
      debugger
      {
        console.log(res);
        if (res.data.code === 200) {
          // 跳转到输入卡密页面。
          if (this.qdCode == "") {
            this.qdCode = getCurrentUrlQdCode();

            if (this.qdCode == "") {
              //todo
              Toast("非法访问")
            }
          }

        } else {
          let targetUrl = "/#/ShandongLoginPage?qdCode=" + this.qdCode;
          window.location.href = targetUrl;
          // window.location.back();
          console.log("not link phone")

          return false;
        }
      }
    },
  },
  created() {
    debugger
    this.checkUserAndInit();
    debugger
    this.checkPhoneStatus();
    this.checkCard();
    this.initUrlAndInfo();

  },


}


function getUrlParams(url, key) {
  // 通过 ? 分割获取后面的参数字符串

  if (url.indexOf("?") > 0) {
    let urlStr = url.split('?')[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
    return obj[key]
  }

  return null;


}

function getCurrentUrlQdCode() {
  let url = window.location.href;
  // 取url的qdCode=
  let qdCode = getUrlParams(url, "qdCode");

  if (qdCode == undefined) {
    let url = window.location.href;

    let originUrl = url.split("#")[1];

    qdCode = getUrlParams(originUrl, "qdCode");

    console.log("qdCode:" + qdCode)

  }
  return qdCode;
}
</script>

<style scoped lang="scss">
.ruizeNav{
  color:  #E86922;

}

.toSubmitButton {


  margin-top: 40px;


  width: 327px;
  height: 46px;
  background: #306AFC;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}


.testRem1 {
  height: auto;
  display: flex;
  flex-direction: column;
  background: #006AFA !important;
}

.firstContainer {
  //margin-top: -40px;
  //12 0.32
  padding-top: 13px;
  margin: -140px 12px 12px 12px;
  width: 351px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;

  .title1Container {
    margin: 12px 12px 36px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .textLeft {
      margin: auto auto;
      align-content: center;
      justify-content: center;
      width: 263px;
      height: 34px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #1A0C04;
      line-height: 34px;
    }

    .imgRight {
      width: 56px;
      height: 56px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }


}


.title2Container {
  display: flex;
  flex-direction: row;
  margin: 0 12px 12px 12px;
  font-size: 14px;
  flex-direction: row;
  justify-content: space-between;

  .textLeft {
    font-weight: 500;
    color: rgba(26, 12, 4, 0.4);
    font-family: OPPOSans-Medium, OPPOSans;
  }

  .textRight {
    font-weight: 500;
    color: #1A0C04;
    font-family: OPPOSans-Medium, OPPOSans;
  }


}


.secondContainer {
  width: 351px;

  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;

  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;

}

.myTitle {
  border-left: solid 2px #4149D2;
  margin-left: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #4149D2;
  padding-left: 6px;
}


.firstContainer {
  .textLeft {
    color: #4149D2;
  }
}

.secondContainer {
  .textLeft {
    color: #4149D2;
  }
}


.thirdContainer {
  width: 351px;
  height: 130px;
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 12px 12px 12px;


}

.iconContainer {


  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px;

  .buttonAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 98px;
    height: 62px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    img {
      width: 32px;
      height: 32px;
    }

    span {
      font-size: 14px;
      font-family: OPPOSans-Medium, OPPOSans;
      font-weight: 500;
      color: #1A0C04;
      line-height: 22px;
    }
  }
}


.headFirstBg {
  height: 262.5px;
  background-image: url(~@/assets/img/shandong/cardDetailBg.png);
  background-size: cover;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.logoContainer {
  padding: 10px;
  display: flex;
  justify-content: space-between;

  .rightLogo {
    margin-right: -10px;
  }

}

.leftLogo {
  width: 8rem;
  height: 30px;
}

.rightLogo {
  height: 22px;
}

.bigLogoContainer {
  margin-top: 20px;

  .bigLogo {
    width: 10rem;
    margin-top: -5.3333rem;
  }
}

.buttonContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

}

.addButton {
  margin-top: 10px;
  bottom: 5px;
  width: 325px;
  height: 43px;
  border-radius: 22.5px;
  font-size: 16px;
  background: #F8F8F8;
  margin-left: 25px;
  margin-right: 25px;
  color: #7F8389;

}
</style>