<template>

  <div>

    <div class="sectionHead">
      <div class="sectionHeadRight">
        <div class="nameLst">
          <div class="nameFirstContainer">
              <span class="userName">
 {{ activeCardInfo.name }}
              </span>
            <!--            <span>-->
            <!--                icon-->
            <!--              </span>-->
          </div>
          <div class="nameSecondContainer">
            <span class="cardDate">
                权益人： {{ activeCardInfo.authName }}
              </span>
            <span class="cardDate">
               权益卡有效期至：{{ activeCardInfo.endTimeStr }}
              </span>
            <!--            <span>-->
            <!--                icon-->
            <!--              </span>-->
          </div>


        </div>

      </div>

    </div>

    <div class="detail">
      <img v-bind:src="showConfigVO.homeShowVO.headBackImg" class="headFirst">
      <img v-bind:src="showConfigVO.homeShowVO.spe" class="useFirst">

      <div class="listButton">
        <img v-bind:src="useFirstLeft" @click="toDetailShow">
        <img v-bind:src="useFirstRight" @click="toBuy">
      </div>

      <img v-bind:src="showConfigVO.homeShowVO.simple" class="useFirst" @click="toSingleBuy">
      <img v-bind:src="showConfigVO.homeShowVO.ask" class="headFirst" @click="toAsk">

    </div>


    <ruize-menu>


    </ruize-menu>


  </div>


</template>

<script>


import {Button, Dialog, Form, Toast} from "vant";

import detail_head_first from "@/assets/png/detail_head_first@2x.png";
import use_first_before from "@/assets/png/use_first_simple_desc.png";
import use_first_before2 from "@/assets/png/use_second_teyao_header.png";

import use_first from "@/assets/png/use_first@2x.png";
import use_first_left from "@/assets/png/use_first_left_button@2x.png";
import use_first_right from "@/assets/png/use_first_right_button@2x.png";

import use_second from "@/assets/png/use_second.png";
import use_second_button from "@/assets/png/use_second_button.png";
import use_third from "@/assets/png/use_third.png";
import use_third_button from "@/assets/png/use_third_button.png";


import ruizeMenu from "@/components/ruize/RuizeMenu.vue"
import axios from "axios";
import Cookies from "js-cookie";
import {mapState, mapMutations} from 'vuex'
import wx from "weixin-js-sdk";


export default {
  name: "Home",
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    ruizeMenu
  },
  created() {

    //Toast("the nest ")
    //判断cookieopenid
    //判断是否有最新的卡
    let cookieOpenId = Cookies.get(this.$Constants.RzCookieKey.cookieOpenId);

    if (cookieOpenId == undefined) {
      this.$router.push("/")
    }

    this.openId = cookieOpenId;

    this.checkActiveCard();
    // 获取用户当前绑定的卡号
    this.initActiveCard();

    //
    this.initWechatJsSdk()

    this.$Constants.setTitle("睿择健康");
  },
  computed: {
    ...mapState(["currentActiveCard"])
  },

  data() {
    return {
      headFirst: detail_head_first,
      useFirstBefore: use_first_before,
      useFirstBefore2: use_first_before2,
      useFirst: use_first,
      useFirstLeft: use_first_left,
      useFirstRight: use_first_right,
      useSecond: use_second,
      useSecondButton: use_second_button,
      useThird: use_third,
      useThirdButton: use_third_button,
      openId: "",
      singleJumpUrl: "",
      claimJumpUrl: "",
      showConfigVO: "",
      activeCardInfo: {},
      //这是配置的内容
      wxConfig: {},

    }
  },
  methods: {

    initWechatJsSdk() {


      let url = window.location.href;
      let params = {
        url: url
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getWxConfig, params
      ).then(res => {
        console.log(res);
        console.log("res.data:" + res.data);
        if (res.data.code === 200) {
          this.wxConfig = JSON.parse(res.data.data);
          this.wxConfig.debug = false
          wx.config(
              this.wxConfig
          );


          wx.ready(function () {


              }
          );

          wx.error(function (res) {
            console.error(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
            //  Toast("js error:"+JSON.stringify(res))

          });


        }
      });


    },
    initShowPhoto() {
      let
          params = {
            openId: this.openId,
            cardNumber: this.currentActiveCard,
          }

      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCardShow, params
      ).then(res => {
        console.log(res);

        if (res.data.code === 200) {
          console.log("res.data:" + res.data.data);
          this.showConfigVO = res.data.data;
          //绑定要映射的内容
          console.log("showConfigVO:" + this.showConfigVO);
        } else {
          // Toast(res.data.message)
        }
      });
    },

    initActiveCard() {
      let params = {
        openId: this.openId
      }
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getActiveCardInfo, params
      ).then(res => {
        if (res.data.code === 200) {
          this.activeCardInfo = res.data.data
          //把当前激活的卡号，设置进去全局变量

          if (this.activeCardInfo.cardType == 2) {
            //如果当前激活的是药诊卡，则
             this.$router.push("/yzHome");
          //  console.error(" this.$router.push(\"/yzHome\");")
          }else {

            console.error(" 成功后再挑传;")
            //获取跳转url
            this.initGetJumpUrl();

            //获取理赔跳转url
            this.initGetClaimJumpUrl();

            //init ShowConfigVO
            this.initShowPhoto();



          }
        } else {
          Toast("请先去激活一张卡")
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },

    toDetailShow() {
      this.$router.push("detailShow");
    },
    initGetJumpUrl() {
      let params = {
        openId: this.openId,
        cardNumber: this.currentActiveCard
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getBuyTargetUrl, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.singleJumpUrl = res.data.data;
        } else {

          console.error('---- this.$router.push("/")-----')
          this.$router.push("/")
        }
      });

    },

    initGetClaimJumpUrl() {
      let params = {
        openId: this.openId,
        cardNumber: this.currentActiveCard
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getClaimUrl, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.claimJumpUrl = res.data.data;
          console.error("------- 获取理赔url 成功：" + this.claimJumpUrl)

        } else {

          console.error("------- 获取理赔url 失败")
        }
      });

    },
    toBuy() {
      //this.can


      if (this.claimJumpUrl === "") {
        Dialog.alert({
          title: '特药权益',
          message: '在权益卡激活30天后可申请服务',
        }).then(() => {
          // todo 跳转到首页
          //this.$router.push("home")
        });
      } else {
        window.location.href = this.claimJumpUrl;
      }

    },
    checkActiveCard() {
      let params = {
        openId: this.openId
      }
      //检查用户当前激活的卡是否存在。
      axios.post(
          this.$Constants.devHost + this.$Constants.RzApiMethod.getCurrentActiveCard, params
      ).then(res => {
        if (res.data.code === 200) {
          //把当前激活的卡号，设置进去全局变量
          this.setCurrentActiveCard(res.data.data);
        } else {
          this.$router.push("/linkFirstInputCardPage");
        }
      });
    },
    toSingleBuy() {
      window.location.href = this.singleJumpUrl;
    }, toAsk() {
      window.location.href = "https://wxaurl.cn/BedpT6jglvt";
    },
    ...mapMutations(['setCurrentActiveCard']),
  }
}
</script>


<style scoped lang="scss">


.nameLst {

  margin-left: 22px;
  margin-bottom: 10px;
}

.sectionHead {
  display: flex;
  flex-direction: row;
}

.sectionHeadRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.headImg {
  width: 42px;
  height: 42px;
  border-radius: 60.5px;
  margin: 20px 15px 28.5px 15px;
}


.service-list-container {
  display: flex;
  flex-direction: row;
  margin: 5.5px 5.5px 0 5.5px;
  flex-wrap: wrap;

  span {
    line-height: 14px;

  }

  .singleServiceTag {

    height: 0.58rem;
    border-radius: 0.12rem;
    text-align: center;
    line-height: 0.58rem;
    font-size: 0.26rem;
    width: 1.76rem;
    border: 1px solid black;
    margin: 4px 4px;

  }


}

.nameFirstContainer {
  .userName {
    font-size: 20px;
    color: #333C4F;
  }
}

.nameSecondContainer {

  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .cardDate {
    font-size: 13px;
    color: #333C4F;
    margin-bottom: 5px;
  }
}

.quanyiButton {
  width: 65px;
  height: 25px;
  background-image: linear-gradient(270deg, #31E8C3 0%, #16CC90 100%);
  border-radius: 7px;
  margin-right: 15px;

  font-family: PingFang-SC-Bold;
  font-size: 10px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 700;;
}

.headFirst {
  width: 100%;
}

.useFirst {
  width: 100%;

}

.listButton {
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;

  img {
    width: 50%;
    height: 63.83px;
  }
}

.detail {
  padding-bottom: 110px;
}
</style>